import { DarkStyle } from "./variants/DarkStyle";
import { SimpleStyle } from "./variants/SimpleStyle";
export const CardDetails = ({ data, variant, isItalic, onClick }) => {
  return (
    <>
      {variant !== "dark" ? (
        <SimpleStyle data={data} isItalic={isItalic} onClick={onClick} />
      ) : (
        <DarkStyle data={data} />
      )}
    </>
  );
};
