/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  Typography,
  Divider,
  Box,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import { CardDetails } from "../../../../../components";
import { stylesDetail, styleDivider } from "../../styles";
// import { stylesFont } from "../../../../../styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useNavigate } from "react-router-dom";

import { ModalDetails } from "../../../../../components/modalDetails";
import { DetailsAuthor } from "../../../../components/Authors/[id]/detailsAuthor";
import { routesApp } from "../../../../../utils/constants";

const AuthorSpecieSection = ({ authors }) => {
  const [open, setOpen] = useState(false);
  const [author, setAuthor] = useState({});

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(routesApp.authors);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const selectedAuthor = (author) => {
    setAuthor(author);
  };

  return (
    <>
      {authors?.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pb: 2,
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Divider sx={styleDivider} />
              <Typography sx={stylesDetail.titleSection}>Autores</Typography>
            </Box>
            <Tooltip title={"Conocer otros autores"}>
              <IconButton onClick={() => handleNavigate()}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Grid container spacing={2}>
            {authors?.map((author, index) => (
              <Grid item xs={12} sm={4} md={3} key={index}>
                <CardDetails
                  data={{
                    id: author.id_autor,
                    title: author.vc_nombre,
                    subtitle:
                      author.ano_nacimiento_autor +
                      " - " +
                      author.ano_deceso_autor,
                    caption: author.profesion_autor,
                    image: author.imagen_autor,
                  }}
                  onClick={() => {
                    selectedAuthor(author);
                    setOpen(!open);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <ModalDetails
        open={open}
        onClose={handleOpen}
        title={"Detalles de autor"}
      >
        <DetailsAuthor id_value={author.id_autor} handleClose={handleOpen} />
      </ModalDetails>
    </>
  );
};

export default AuthorSpecieSection;
