import { Box, Container, Typography } from "@mui/material";
import CustomBreadcrumb from "./CustomBreadcrumb";

const BannerSection = ({ image, title, arrayLinks, currentPage }) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          background: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: { xs: "10rem", sm: "12rem" },
        }}
      >
        <Box
          sx={{
            background:
              "linear-gradient(to bottom, rgba( 0.7, 0, 0,0), rgba(0, 0, 0, 0.8))",
            height: "100%",
          }}
          maxWidth="auto"
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "flex-end",
              pb: 2,
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              component="div"
              align="left"
              sx={{ color: "#ffffff" }}
            >
              {title}
            </Typography>
            <CustomBreadcrumb
              arrayLinks={arrayLinks}
              currentPage={currentPage}
            />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default BannerSection;
