/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuthors } from "../../hooks/clients/useAuthors";
// import { transformDataToCard } from "../../infrastructure/logic/functions";

const getParams = (name, defaultValue) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name) === null
    ? defaultValue
    : searchParams.get(name);
};

const AuthorsContex = createContext();

const AuthorsProvider = ({ children }) => {
  const [listDataAuthor, setListDataAuthor] = useState([]);
  //   const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { listAuthors, getAuthors, loading } = useAuthors();

  const bodyData = {
    page: parseInt(getParams("page", 1)),
    sizeItems: parseInt(getParams("sizeItems", 15)),
    search: getParams("search", ""),
  };

  const updateUrlParam = (name, value) => {
    const params = new URLSearchParams(window.location.search);

    if (name === "page") {
      params.set(name, value);
    } else {
      if (value === "") {
        params.delete(name);
      } else {
        params.set(name, value);
      }
      params.set("page", 1);
    }

    navigate(`?${params.toString()}`);
  };

  // Obtener lista de autores según parámetros en la URL
  useEffect(() => {
    getAuthors(bodyData.page, bodyData.sizeItems, bodyData.search);
  }, [searchParams]);

  useEffect(() => {
    const getData = async () => {
      const response = await listAuthors;
      if (response) {
        if (bodyData.search === "") {
          setListDataAuthor(response?.autores);
        } else {
          setListDataAuthor(response?.authors);
        }
      } else {
        setListDataAuthor([]);
      }
    };
    getData();
  }, [listAuthors]);
  return (
    <AuthorsContex.Provider
      value={{
        bodyData,
        listAuthors,
        listDataAuthor,
        updateUrlParam,
        loading,
      }}
    >
      {children}
    </AuthorsContex.Provider>
  );
};

export { AuthorsProvider };
export const useLogicAuthors = () => useContext(AuthorsContex);
