import { Box, Typography } from "@mui/material";
import { stylesFonts } from "../../styles";

export const InfoSection = ({ specie }) => {
  console.log(specie);
  const detailsSpecie = [
    { title: "Nombre común", value: specie?.vc_nombre },
    {
      title: "Nombre en inglés",
      value: specie?.vc_nombre_ingles
        ? specie?.vc_nombre_ingles
        : "No registrado",
    },
    {
      title: "Nombre científico",
      value: specie?.vc_nombre_cientifico,
    },
    {
      title: "Año del registro",
      value: specie?.vc_ano ? specie.vc_ano : "No registrado",
    },
    {
      title: "Tipo",
      value: specie?.taxa ? specie.taxa?.vc_nombre : "No registrado",
    },
  ];

  const detailsTaxonomia = [
    { title: "Reino", value: specie?.taxonomia?.reino?.vc_nombre },
    { title: "Filo", value: specie?.taxonomia?.filo?.vc_nombre },
    { title: "Clase", value: specie?.taxonomia?.clase?.vc_nombre },
    { title: "Orden", value: specie?.taxonomia?.orden?.vc_nombre },
    { title: "Familia", value: specie?.taxonomia?.familia?.vc_nombre },
  ];

  return (
    <Box>
      <Typography sx={stylesFonts.titleSection}>Información general</Typography>
      <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 400,
          }}
        >
          {detailsSpecie.map((detail, index) => (
            <Box key={index}>
              <Box display={"flex"}>
                <Typography
                  sx={stylesFonts.subtitleSection}
                  fontWeight={700}
                  flexGrow={1}
                  width={100}
                  maxWidth={180}
                >
                  {detail.title}
                </Typography>
                <Typography sx={stylesFonts.textSection} flexGrow={2}>
                  : {detail.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {detailsTaxonomia?.map((detail, index) => (
            <Box key={index} display={"flex"}>
              <Typography
                sx={stylesFonts.subtitleSection}
                fontWeight={700}
                flexGrow={1}
                maxWidth={140}
              >
                {detail.title}
              </Typography>
              <Typography sx={stylesFonts.textSection} flexGrow={2}>
                : {detail.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={2}>
        <Typography
          sx={stylesFonts.subtitleSection}
          fontWeight={700}
          flexGrow={1}
          width={100}
        >
          Descripción
        </Typography>
        <Typography sx={{ ...stylesFonts.textSection, textAlign: "justify" }}>
          {specie.te_descripcion}
        </Typography>
      </Box>
    </Box>
  );
};
