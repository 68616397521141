import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import SearchInputSection from "../../../../components/SearchInputSection";
import TableList from "./TableList";
import { CustonDialogAdmin } from "../../../../components/Admin/DialogCuston";

export const TableListSimpleAction = ({
  data,
  dataDefault,
  isPagination,
  isDelete,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [valueDefault, setValueDefault] = React.useState(0);
  const [dataValue, setDataValue] = React.useState({});

  const handlePage = (page) => {
    props.handlePage(page);
  };

  const handleRows = (rows) => {
    props.handleRows(rows);
  };

  const handleActions = () => {
    props.handleActions(dataValue.id, valueDefault);
    setOpen(false);
  };

  return (
    <>
      <Box mb={2}>
        <SearchInputSection
          onResult={props.handleSearch}
          defaultValue={dataDefault.search}
        />
      </Box>
      <TableList
        data={data}
        dataDefault={dataDefault}
        isPagination={isPagination}
        handlePage={(e, value) => handlePage(value + 1)}
        handleRows={(e, value) => handleRows(value)}
        optionValue={(value) => {
          setDataValue(value);
          setOpen(true);
        }}
      />
      <CustonDialogAdmin
        open={open}
        onClose={() => setOpen(false)}
        title={isDelete ? "¿Está seguro de eliminar?" : "Añadir a especie"}
        handleAction={() => handleActions()}
      >
        {props.isAuthor ? (
          <FormAuthorLabel
            value={valueDefault}
            setValue={(e) => setValueDefault(e)}
          />
        ) : null}
      </CustonDialogAdmin>
    </>
  );
};

const FormAuthorLabel = ({ data, value, setValue }) => {
  return (
    <>
      <FormControl>
        <FormLabel>¿Actualizó los datos de la especie? </FormLabel>
        <RadioGroup
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
        >
          <FormControlLabel control={<Radio />} label="Sí" value={1} />
          <FormControlLabel control={<Radio />} label="No" value={0} />
        </RadioGroup>
      </FormControl>
    </>
  );
};
