import React, { useState, useEffect } from "react";
import { Container, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ position: "relative" }}>
      {/* <Bounce> */}
      <IconButton
        size="small"
        onClick={scrollToTop}
        sx={{
          position: "fixed",
          backgroundColor: "white",
          bottom: 30, // Ajusta la posición vertical según tus necesidades
          right: { xs: 20, sm: 20, md: "1rem" }, // Ajusta la posición horizontal según tus necesidades
          zIndex: 999, // Para asegurarte de que esté en la parte superior de otros elementos
          display: isVisible ? "block" : "none",
          borderRadius: "1rem",
          px: 1,
          py: 0.8,
          border: `1px solid ${useTheme().palette.primary.main}`,
          //   backgroundColor: useTheme().palette.info, // Cambiar el fondo a blanco
          color: useTheme().palette.primary.main, // Cambiar el color a azul
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
          "&:hover": {
            backgroundColor: useTheme().palette.primary.main,
            color: "white",
          },
        }}
      >
        <ExpandLessIcon color="primary.main" />
      </IconButton>
    </Container>
  );
};
