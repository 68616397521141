import { Typography, Box } from "@mui/material";
import ButtonCustom from "../ButtonCustom";
import { stylesFonts } from "../../../presentation/components/Administrador/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export const LayoutFrmList = ({ title, to, label, children }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
        <Typography sx={stylesFonts.titlePage}>{title}</Typography>
        <ButtonCustom
          to={to}
          label={"Agregar"}
          color="primary"
          startIcon={<AddRoundedIcon />}
        />
      </Box>
      <Box>{children}</Box>
    </>
  );
};
