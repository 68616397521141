/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  Alert,
  // Alert,
  Box,
  FormControlLabel,
  Grid,
  // Snackbar,
  Switch,
  Typography,
} from "@mui/material";

import { stylesFonts, paragraphSection } from "../../../styles";

import { useEffect } from "react";

import { TableListSimpleAction } from "../../tableSimpleAction";
import { useAuthorSpecie } from "../../../../../../context/admin/specieAuthorProvider";
import { SectionListControls } from "../../ListControls";

const transformDataForList = (data) => {
  if (data) {
    return data.map((item) => {
      return {
        id: item.id_autor,
        status: parseInt(item.estado_autor),
        image: item.imagen_autor,
        primary: item.vc_nombre,
        secondary: item.profesion_autor,
      };
    });
  } else {
    return [];
  }
};

const SectionAuthors = ({ specie }) => {
  const {
    dataDefault,
    setDataDefault,
    getListDataAuthors,
    listAuthors,
    addOrDeleteAuthor,
    message,
  } = useAuthorSpecie();

  const [checked, setChecked] = useState(false); //[{id: 1, name: "nombre"}

  useEffect(() => {
    if (specie?.id_especie) {
      getListDataAuthors(specie?.id_especie);
    }
  }, [specie, dataDefault]);

  const handleChange = () => {
    setChecked((prev) => !checked);
  };

  const handleDataDefault = (name, value) => {
    setDataDefault({
      ...dataDefault,
      [name]: value,
    });
  };

  const handleAction = (value, updated, isDelete) => {
    if (!isDelete) {
      addOrDeleteAuthor(null, {
        id_especie: specie?.id_especie,
        id_autor: value,
        actualizado: updated,
      });
    } else {
      addOrDeleteAuthor(true, {
        id_especie: specie?.id_especie,
        id_autor: value,
      });
    }
  };

  const severity = message.status === 200 ? "success" : "error";

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography sx={stylesFonts.titleSection}>
            Autores asignados
          </Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange} />}
            label="Habilitar edición"
          />
        </Box>
      </Box>

      {message?.message !== "" && message?.message !== null && (
        <Box py={1}>
          <Alert severity={severity}>
            <Typography sx={stylesFonts.messageAlert}>
              {message.message}
            </Typography>
          </Alert>
        </Box>
      )}

      <Grid container spacing={3}>
        {checked ? (
          <Grid item xs={12} md={6}>
            <Typography sx={paragraphSection}>
              Selecciona los autores disponibles para asignar
            </Typography>
            <TableListSimpleAction
              data={listAuthors}
              dataDefault={dataDefault}
              isPagination={true}
              handlePage={(value) => handleDataDefault("currentPage", value)}
              handleRows={(value) => handleDataDefault("sizePage", value)}
              handleSearch={(value) => handleDataDefault("search", value)}
              isAuthor={true}
              handleActions={(value, updated) =>
                handleAction(value, updated, false)
              }
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={checked ? 6 : 12}>
          <SectionListControls
            data={transformDataForList(specie?.autores)}
            isDelete={checked}
            handleDelete={(value) => handleAction(value, null, true)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionAuthors;
