/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
// import ThreeScene from "./ThreeScene";
// import { OrbitControls } from "@react-three/drei";
// import Model from "./Model";

import { LayoutsFrmDetails } from "../../../../../components";
import { useLogicSpeciesAdmin } from "../../../../../context/admin/speciesProvider";
import { InfoSection } from "./DetailsSection";
import { MoreInformation } from "./MoreDetails";
import { MultimediaSection } from "./MultimediaSection";

const FormDetailsSpecie = () => {
  const { getDataSpecie, specie, loading } = useLogicSpeciesAdmin();
  useEffect(() => {
    getDataSpecie();
  }, []);

  return (
    <>
      <LayoutsFrmDetails
        title={specie?.vc_nombre}
        loading={loading}
        image={specie?.vc_imagen}
        subTitle={specie?.fecha_modificacion}
        label={"Especies"}
      >
        <InfoSection specie={specie} />
        <MoreInformation data={specie} />
        <MultimediaSection data={specie} />
      </LayoutsFrmDetails>
      {/* <Box height="80vh">
              <ThreeScene>
                <color attach="background" args={["#dfff80"]} />
                <Suspense fallback={null}>
                  <Model />
                </Suspense>
                <pointLight position={[-100, 100, 10]} />
                <ambientLight />
                <OrbitControls />
              </ThreeScene>
            </Box> */}
    </>
  );
};

export default FormDetailsSpecie;
