// import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const styleSelect = {
  color: "#015E80",
  borderRadius: "8px", // Bordes redondeados
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#015E80", // Borde gris
      borderRadius: "8px", // Bordes redondeados
      border: "2px solid gray", // Grosor del borde
    },
    "&:hover fieldset": {
      borderColor: "#015E80", // Borde blanco al hacer hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#015E80", // Borde blanco cuando está enfocado
      // backgroundColor: "rgba(28, 132,184,72,0.4)", // Fondo blanco cuando está enfocado
    },
  },
  "& .MuiOutlinedInput-input": {
    "&::placeholder": {
      color: "#000000", // Color del placeholder
    },
  },
};

const AutocompleteCustom = ({
  label,
  options,
  value,
  placeholder,
  onChange,
  ...props
}) => {
  //   const [value, setValue] = useState(null);
  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options && options.length > 0 ? options : []}
        getOptionLabel={(option) => `${option.label} `}
        value={options?.find((option) => option.id === value) || null}
        onChange={onChange}
        {...props}
        sx={styleSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            fullWidth
            margin="dense"
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "6px 10px",
              },
            }}
          />
        )}
      />
    </>
  );
};

export default AutocompleteCustom;
