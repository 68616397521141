export const stylesComponents = {
  selectSolid: {
    backgroundColor: "#ffffff",
    borderRadius: "0.5rem",
    // color: "white",
    "& .MuiSelect-icon": {
      // color: "white",
    },
  },
  buttonSolid: {
    marginRight: "10px",
    marginBottom: "10px",
    borderRadius: "0.5rem",
    border: "2px solid #015E80",
    "&:hover": {
      border: "2px solid #015E80",
    },
  },
  buttonOutlined: {
    color: "gray",
    borderRadius: 2,
    border: "2px solid #ccc",
    "&:hover": {
      border: "2px solid ",
      color: "primary.main",
    },
  },
  chip: {
    marginRight: 1,
    marginBottom: 1,
    borderRadius: "0.5rem",
    backgroundColor: "#76A120",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
      "&:hover": {
        color: "#cecece",
      },
    },
    "& .MuiChip-label": {
      fontWeight: "bold",
      textTransform: "capitalize",
      letterSpacing: "0.05rem",
    },
  },
};
