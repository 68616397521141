import { Typography, Grid, Box, Container } from "@mui/material";
import { stylesFont } from "../../../../styles";
import { Fade } from "react-awesome-reveal";

export const MovilSection = () => {
  return (
    <Container maxWidth="lg" sx={{ marginY: 10 }}>
      <Grid
        container
        justifyContent="center"
        columns={{ xs: 4, sm: 4, md: 8, lg: 12 }}
        spacing={4}
      >
        <Grid item xs={4} sm={4} md={4} lg={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid container>
              <Grid item xs={12}>
                <Fade>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: "bold",
                      overflow: "hidden",
                      textAlign: "center",
                      maxWidth: "700px",
                      pb: 2,
                    }}
                  >
                    Tu <span style={{ color: "#ed6c02" }}>guía móvil{""}</span>
                    de especies y pueblos indígenas
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={stylesFont.text}>
                  Amazonia App: Explora, Conecta y Ahorra: Descubre todo el
                  contenido desde cualquier lugar con tu smartphone Android y
                  consume menos datos.
                </Typography>
              </Grid>
              <Grid item container textAlign="center" xs={12}>
                <Grid item xs={12} sm={6}>
                  <Box
                    component="img"
                    src="/images/homeSection/play.webp"
                    alt="Play Store"
                    sx={{
                      maxWidth: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    component="img"
                    src="/images/homeSection/appstore.webp"
                    alt="App Store"
                    sx={{
                      maxWidth: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
          sm={4}
          md={4}
          lg={6}
        >
          <Fade>
            <Grid item>
              <Box
                component="img"
                id="parallax-image"
                src="/images/homeSection/telefono.avif"
                alt="Teléfono"
                sx={{
                  filter: "drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.9))",
                  objectFit: "cover",
                  height: "auto",
                  width: "100%",
                  minWidth: "300px",
                }}
              />
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
};
