import { Box } from "@mui/material";
import LayoutCommunity from "../layoutCommunity";
import InfoSection from "./sections/infoSection";
import ListSection from "./sections/listSection";
import { stylesSections } from "../../../../styles";

const IndexCommunity = () => {
  return (
    <LayoutCommunity title="Comunidades Indígenas" currentPage={"Comunidades"}>
      <Box sx={stylesSections.sectionGeneral}>
        <InfoSection />
        <ListSection />
      </Box>
    </LayoutCommunity>
  );
};

export default IndexCommunity;
