import React from "react";
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import { stylesSections, stylesFont } from "../../../../styles";
import { Link } from "react-router-dom";

import { Fade } from "react-awesome-reveal";
import CheckIcon from "@mui/icons-material/Check";

export const AboutSection = () => {
  const [loadedImage, setLoadedImage] = React.useState(false);

  return (
    <Box sx={{ backgroundColor: "#ffffff" }}>
      <Container maxWidth="lg">
        <Box sx={stylesSections.sectionGeneral}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Box component={Fade}>
                <Typography sx={stylesFont.titleSectionAbout}>
                  AMAZONÍA
                </Typography>
                <Typography sx={stylesFont.subTitleSectionAbout}>
                  Guía ilustrada de flora y fauna
                </Typography>
                <Typography sx={stylesFont.text} pb={2}>
                  Bienvenidos a la plataforma web de la guía ilustrada de flora
                  y fauna. Explora la biodiversidad de la región a través de una
                  experiencia interactiva basada en datos actualizados de 2022.
                </Typography>
              </Box>

              <Box component={Fade}>
                <Box pb={2}>
                  <Box
                    display={"flex"}
                    pb={1}
                    alignItems={"center"}
                    color={"primary.main"}
                  >
                    <CheckIcon />
                    <Typography
                      sx={{
                        ...stylesFont.subTitleSectionAbout,
                        fontSize: "1rem",
                        marginLeft: 1,
                        color: "primary.main",
                      }}
                    >
                      Sobre el contenido
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...stylesFont.text,
                      color: "GrayText",
                      fontSize: { xs: "0.8rem", sm: "0.9rem" },
                    }}
                  >
                    Destaca la impresionante diversidad de la región, educando y
                    sensibilizando a todas las edades tanto dentro como fuera de
                    la Amazonía debido a su belleza e importancia.
                  </Typography>
                </Box>
                <Box pb={2}>
                  <Box
                    display={"flex"}
                    pb={1}
                    alignItems={"center"}
                    color={"primary.main"}
                  >
                    <CheckIcon />
                    <Typography
                      sx={{
                        ...stylesFont.subTitleSectionAbout,
                        fontSize: "1rem",
                        marginLeft: 1,
                        color: "primary.main",
                      }}
                    >
                      Nuestro objetivo
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...stylesFont.text,
                      color: "GrayText",
                      fontSize: { xs: "0.8rem", sm: "0.9rem" },
                    }}
                  >
                    Buscamos concienciar sobre la importancia de la
                    biodiversidad y su protección, especialmente en las regiones
                    más pobres y entre las poblaciones vulnerables afectadas por
                    la degradación y el cambio climático.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Fade
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alingItems: "center",
                  alignContent: "center",
                }}
              >
                <Tooltip title="Ver repositorio de la guía">
                  <Link
                    to="https://repositorio.iiap.gob.pe/handle/20.500.12921/680"
                    target="_blank"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!loadedImage && (
                      <Skeleton
                        variant="rectangular"
                        height={400}
                        sx={{ width: "100%" }}
                      />
                    )}
                    <Box
                      component={"img"}
                      onLoad={() => setLoadedImage(true)}
                      src="images/homeSection/libro.avif"
                      alt="libro"
                      sx={{
                        display: loadedImage ? "flex" : "none",
                        maxHeight: {
                          xs: "300px",
                          sm: "400px",
                        },
                        alingItems: "center",
                        alignContent: "center",
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                    />
                  </Link>
                </Tooltip>
              </Fade>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
