import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

 const BlinkingTypography = styled(Typography)`
  @keyframes blink {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }

  animation: blink 1s infinite;
`;

export const LoadingInfo = () => {
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        height="100vh"
      >
        <Box height="360px" textAlign="center">
          <CircularProgress />
          <BlinkingTypography
            variant="h5"
            fontWeight={"Bold"}
            color={"GrayText"}
            mt={2}
          >
            Loading ...
          </BlinkingTypography>
        </Box>
      </Grid>
    </>
  );
};
export default LoadingInfo;
