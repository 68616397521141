/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Container, Box } from "@mui/material";
import LayoutCommunity from "../layoutCommunity";
import { useParams } from "react-router-dom";
import { LoadingInfo } from "../../../../components";
import { stylesSections } from "../../../../styles";
import InfoSection from "./sections/infoSection";
import { useCommunity } from "../../../../hooks/clients/useCommunity";

const IndexDetailCommunity = () => {
  const { id } = useParams();
  const { community, loading, getCommunityById } = useCommunity();

  useEffect(() => {
    getCommunityById(id);
  }, [id]);

  return (
    <>
      <LayoutCommunity
        title="Comunidades Indígenas"
        currentPage="Detalle de comunidad"
      >
        <Box sx={stylesSections.sectionGeneral}>
          <Container maxWidth={"lg"}>
            {loading ? (
              <LoadingInfo />
            ) : (
              <>
                <InfoSection community={community} />
              </>
            )}
          </Container>
        </Box>
      </LayoutCommunity>
    </>
  );
};

export default IndexDetailCommunity;
