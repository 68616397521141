/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, Grid, Alert, TextField } from "@mui/material";
import { titleSection } from "../../styles";
import { useLogicAuthorsAdmin } from "../../../../../context/admin/authorsProvider";

const SectionDetails = ({ author, warningMsg, setValues }) => {
  const { id_autor } = useLogicAuthorsAdmin();
  const [formData, setFormData] = useState({
    vc_nombre: "",
    vc_apellido: "",
    vc_ano_nacimiento: "",
    vc_ano_deceso: "",
    vc_profesion: "",
    te_biografia: "",
  });

  useEffect(() => {
    if (id_autor) {
      setFormData({
        vc_nombre: author?.vc_nombre || "",
        vc_apellido: author?.vc_apellido || "",
        vc_ano_nacimiento: author?.vc_ano_nacimiento || "",
        vc_ano_deceso: author?.vc_ano_deceso || "",
        vc_profesion: author?.vc_profesion || "",
        te_biografia: author?.te_biografia || "",
      });
    }
  }, [author]);

  useEffect(() => {
    setValues(formData);
  }, [formData]);

  return (
    <>
      <Typography sx={titleSection}>Detalles del autor</Typography>

      {warningMsg ? (
        <Alert severity="warning" sx={{ marginBottom: 1 }}>
          {warningMsg}
        </Alert>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={"Nombre"}
            placeholder={"Escribe el nombre..."}
            value={formData.vc_nombre}
            onChange={(e) =>
              setFormData({ ...formData, vc_nombre: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={"Apellido"}
            placeholder={"Escribe el apellido..."}
            value={formData.vc_apellido}
            onChange={(e) =>
              setFormData({ ...formData, vc_apellido: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label={"Año de nacimiento"}
            placeholder={"Año de nacimiento..."}
            value={formData.vc_ano_nacimiento}
            onChange={(e) =>
              setFormData({
                ...formData,
                vc_ano_nacimiento: e.target.value,
              })
            }
            type={"number"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label={"Año de deceso"}
            placeholder={"Año de deceso..."}
            value={formData.vc_ano_deceso}
            onChange={(e) =>
              setFormData({ ...formData, vc_ano_deceso: e.target.value })
            }
            type={"number"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label={"Profesión"}
            placeholder={"Escribe la profesión..."}
            value={formData.vc_profesion}
            onChange={(e) =>
              setFormData({ ...formData, vc_profesion: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={"Biografía"}
            placeholder={"Escribe una biografía..."}
            multiline
            rows={8}
            value={formData.te_biografia}
            onChange={(e) =>
              setFormData({ ...formData, te_biografia: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionDetails;
