import React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { getListCategories } from "../../../infrastructure/api/client/categories";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const getSpeciesColor = (speciesName) => {
  const colorMap = {
    Aves: "#F36A2A",
    Mamiferos: "#886D5B",
    Anfibios: "#3B8476",
    Reptiles: "#77A69D",
    Peces: "#0E69A8",
    Insectos: "#E3B409",
    Arboles: "#4CB848",
    Palmeras: "#8C62AA",
  };

  return colorMap[speciesName] || "#000000";
};

function hexToTransparentRgba(hex, alpha) {
  hex = hex.replace("#", ""); // Elimina el símbolo "#"
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const transformData = (data) => {
  return data?.map((item) => ({
    id: item.id_taxonomia,
    name: item.vc_nombre,
    quantity: item.in_total_especies,
    porcentage: item.de_porcentaje,
    color: getSpeciesColor(item.vc_nombre),
  }));
};

export default function ChartSection() {
  const [apiData, setApiData] = useState([]);
  const getDataCategory = () => {
    getListCategories().then((res) => {
      setApiData(transformData(res.data));
    });
  };

  useEffect(() => {
    getDataCategory();
  }, []);

  const data = {
    labels: apiData?.map((category) => `${category?.name}`),
    datasets: [
      {
        label: "Distribución de especies",
        data: apiData?.map((category) => category?.quantity),
        backgroundColor: apiData?.map((category) =>
          hexToTransparentRgba(category.color, 0.5)
        ),
        borderColor: apiData?.map((category) => category.color),
      },
    ],
  };

  const legendOptions = {
    position: window.innerWidth < 600 ? "bottom" : "left",
    labels: {
      boxWidth: 15,
    },
  };

  const titleOptions = {
    display: true,
    text: "Distribución de especies",
    position: "top",
  };

  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "8px",
        maxHeight: { sx: "280px", md: "365px" },
        minHeight: { sx: "280px", md: "365px" },
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Pie
        data={data}
        style={{ width: "100%", height: "100%" }}
        options={{
          plugins: {
            legend: legendOptions,
            title: titleOptions,
            datalabels: {
              display: true,
            },
          },
        }}
      />
    </Box>
  );
}
