import ListAuthors from "./ListAuthors/ListAuthors";
import { LayoutFrmList } from "../../../../components";

const IndexAuthors = () => {
  return (
    <>
      <LayoutFrmList title={"Autores"} to={"/admin/autores/add"}>
        <ListAuthors />
      </LayoutFrmList>
    </>
  );
};

export default IndexAuthors;
