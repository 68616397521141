import { Outlet } from "react-router-dom";

const TemplateAdmin = () => {
  return (
    <>
      <Outlet/>
    </>
  );
};

export default TemplateAdmin;
