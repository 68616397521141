import { Box, Chip, Typography, CardActionArea, Avatar } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useNavigate } from "react-router-dom";
import { storeSearchData } from "./localStorageUtil";

const styleHover = {
  "&:hover": {
    backgroundColor: "rgba(1, 94, 128, 0.1)",
    border: "1px solid #015E80",
    cursor: "pointer",
  },
};

const ResultSearch = ({ label, arrayItems, handleClose }) => {
  const navigate = useNavigate();

  const handleSearchClick = (data) => {
    storeSearchData(data); // Almacena los datos cuando se hace clic
  };

  return (
    <>
      <Box
        padding={2}
        display="flex"
        color={"#015E80"}
        borderRadius={4}
        sx={styleHover}
      >
        <Box>
          <InsertDriveFileOutlinedIcon />
        </Box>
        <Typography
          fontSize={18}
          component="h2"
          marginLeft={2}
          fontWeight={600}
        >
          {label}
        </Typography>
      </Box>
      {arrayItems?.length > 0 &&
        arrayItems?.slice(0, 6).map((item, index) => (
          <CardActionArea
            key={index}
            sx={{
              borderRadius: 4,
            }}
            onClick={() => {
              navigate(item.url);
              handleSearchClick(item);
              handleClose();
            }}
          >
            <Box
              paddingX={2}
              paddingY={1}
              display="flex"
              alignItems={"center"}
              sx={styleHover}
              borderRadius={4}
              color={"#015E80"}
              justifyContent={"space-between"}
            >
              <Box paddingX={2} display={"flex"} alignItems={"center"}>
                {/* <Grid3x3OutlinedIcon /> */}
                <Avatar
                  src={
                    item.image
                      ? item.image
                      : "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                  }
                  sx={{
                    // width: 32,
                    // height: 32,
                    backgroundColor: "#ffffff",
                    borderColor: "#015E80",
                    border: "1px solid",
                  }}
                />
                <Box>
                  <Typography variant="subtitle1" component="h2" marginLeft={2}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="h2"
                    marginLeft={2}
                    fontStyle={"italic"}
                  >
                    {item.subTitle}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Chip label={item.tag} variant="outlined" size="small" />
              </Box>
            </Box>
          </CardActionArea>
        ))}
    </>
  );
};

export default ResultSearch;
