import { Container } from "@mui/material";

import LayoutAuthors from "../layoutAuthors";
import { DetailsAuthor } from "./detailsAuthor";

const PageAuthor = () => {
  return (
    <>
      <LayoutAuthors title="Autores" currentPage="Detalles de autor">
        <Container maxWidth="lg">
          <DetailsAuthor />
        </Container>
      </LayoutAuthors>
    </>
  );
};
export default PageAuthor;
