/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { stylesFont } from "../../../../styles";

import { useAuthors } from "../../../../hooks/clients/useAuthors";
import { useParams } from "react-router-dom";
import { CardDetails } from "../../../../components/Cards/CardDetails";

const style = {
  body: {
    paddingY: { xs: 2, sm: 4 },
  },
  titleLarge: {
    fontSize: { xs: "1.4rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
    fontWeight: "bold",
    textTransform: "capitalize",
    textShadow: "0px 2px 2px rgba(0,0,0,0.3)",
  },
  personalInfo: {
    display: { xs: "block", sm: "flex" },
    gap: 2,
    columnGap: 2,
    mb: { xs: "0.7rem", sm: "1rem" },
    alignItems: "end",
  },
  listSpecies: {
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(2, 1fr)",
      sm: "repeat(4, 1fr)",
      lg: "repeat(5, 1fr)",
    },
    gap: 2,
  },
};

export const DetailsAuthor = ({ id_value, handleClose }) => {
  const navigate = useNavigate();
  const { getAuthor, loading, author } = useAuthors();
  const [loadingImg, setLoadingImg] = React.useState(true);
  const { id } = useParams();
  const id_author = id_value ? id_value : id;

  useEffect(() => {
    getAuthor(id_author);
  }, []);

  const handleOnClick = (id) => {
    navigate(`/species/${id}`);
  };

  return (
    <Box sx={style.body}>
      <Box sx={style.personalInfo}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: { xs: "300px", md: "400px" },
              width: { xs: "300px", md: "400px" },
            }}
          />
        ) : (
          <>
            {loadingImg && (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: { xs: "300px", md: "400px" },
                  height: { xs: "300px", md: "400px" },
                }}
              />
            )}
            <Box
              component={"img"}
              src={author?.vc_imagen}
              alt="imagen de autor"
              onLoad={() => setLoadingImg(false)}
              sx={{
                display: loadingImg ? "none" : "block",
                borderRadius: "8px",
                width: "100%",
                height: "100%",
                maxHeight: { xs: "300px", md: "400px" },
                maxWidth: { xs: "300px", md: "400px" },
                mb: { xs: "1rem", sm: 0 },
              }}
            />
          </>
        )}
        <Box
          sx={{
            width: { xs: "100%", sm: "calc(100% - 300px)" },
          }}
        >
          <Typography sx={style.titleLarge}>
            {loading
              ? "Cargando..."
              : author?.vc_nombre + " " + author?.vc_apellido}
          </Typography>
          <Typography sx={{ ...stylesFont.titleSection }}>
            {author?.vc_ano_nacimiento} - {author?.vc_ano_deceso}
          </Typography>
          <Typography
            sx={{
              ...stylesFont.subTitleText,
              textTransform: "capitalize",
              color: "gray",
            }}
          >
            {author?.vc_profesion}
          </Typography>
          <Typography sx={{ ...stylesFont.titleSection, mb: 1, fontSize: 20 }}>
            Total de especies
          </Typography>
          <Typography sx={{ ...stylesFont.subTitleText, mb: 2, color: "gray" }}>
            {loading ? "Cargando..." : author?.species?.length} especies{" "}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ ...stylesFont.titleSection, mb: 1 }}>
          Biografía
        </Typography>
        <Typography sx={{ ...stylesFont.text, mb: 2 }}>
          {loading ? "Cargando..." : author?.te_biografia}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography sx={{ ...stylesFont.titleSection, mb: 1 }}>
          Especies relacionadas
        </Typography>
        <Box>
          {loading ? (
            "cargando..."
          ) : (
            <>
              {author?.species.length > 0 ? (
                <>
                  <Box sx={style.listSpecies}>
                    {author?.species?.map((specie, index) => (
                      <CardDetails
                        key={index}
                        data={{
                          id: specie?.id_especie,
                          title: specie?.vc_nombre,
                          subtitle: specie?.vc_nombre_cientifico,
                          image: specie?.vc_imagen,
                          tag: "categoria",
                        }}
                        height={240}
                        isItalic={true}
                        onClick={() => handleOnClick(specie?.id_especie)}
                      />
                    ))}
                  </Box>
                </>
              ) : (
                <Box p={2}>
                  <Typography
                    sx={{
                      ...stylesFont.text,
                      textTransform: "capitalize",
                      color: "gray",
                      fontWeight: "bold",
                    }}
                  >
                    No hay especies relacionadas
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
