import { Grid } from "@mui/material";
import CardIndicatorsDashboard from "./CardIndicatorsDashboard";
import AnimationOutlinedIcon from "@mui/icons-material/AnimationOutlined";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";

const SectionIndicator = ({ data, loading, icon, color, onClick }) => {
  const cardsField = [
    {
      title: "Total de Especies",
      subtitle: "Especies",
      value: data?.species?.totalSpecies,
      image: <AnimationOutlinedIcon sx={{ fontSize: 45, color: "gray" }} />,
      link: "/admin/especies",
    },
    {
      title: "Total de Autores",
      subtitle: "Autores",
      value: data?.authors?.totalAuthors,
      image: <Groups2OutlinedIcon sx={{ fontSize: 45, color: "gray" }} />,
      link: "/admin/autores",
    },
    {
      title: "Total de pueblos",
      subtitle: "Pueblos",
      value: data?.communities?.totalCommunities,
      image: <Diversity2OutlinedIcon sx={{ fontSize: 45, color: "gray" }} />,
      link: "/admin/towns",
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {cardsField.map((item, index) => {
          return (
            <Grid item xs={12} md={4} key={index}>
              <CardIndicatorsDashboard
                loading={loading}
                title={item.title}
                subTitle={item.subtitle}
                value={item.value}
                icon={item.image}
                color={color}
                onClick={onClick}
                url={item.link}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SectionIndicator;
