/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Typography, Divider, Box, Skeleton } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Fade } from "react-awesome-reveal";
import { ButtonCuston } from "../../../../components";
import { stylesFont, styleDivider, stylesSections } from "../../../../styles";
import { useCommunity } from "../../../../hooks/clients/useCommunity";
import { CardDetails } from "../../../../components";
import { useNavigate } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const responsive = {
  desktop: {
    breakpoint: { max: 9000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 699 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  // add breakpoint for mobile large
  mobileLarge: {
    breakpoint: { max: 700, min: 441 },
    items: 2,
    // partialVisibilityGutter: 80,
  },
  mobile: {
    breakpoint: { max: 440, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

export const CommunitySection = () => {
  const navigate = useNavigate();
  const { getCommunities, listCommunities, loading } = useCommunity();
  const body = {
    page: 1,
    size: 8,
  };

  useEffect(() => {
    getCommunities(body.page, body.size, "");
  }, []);

  const handleDetail = (id) => {
    navigate(`/community/${id}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgb(243, 244, 249)",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={stylesSections.sectionGeneral}>
          <Box sx={stylesSections.sectionTitleSubTitle}>
            <Typography sx={stylesFont.subTitleSection}>
              PUEBLOS INDÍGENAS AMAZÓNICOS
            </Typography>
            <Typography sx={stylesFont.titleSection}>
              Descubriendo las raíces indígenas
            </Typography>
            <Divider sx={styleDivider.dividerHome} />
          </Box>

          {loading ? (
            <Box display={"flex"} gap={1}>
              {[...Array(4)].map((item, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={220}
                  height={200}
                  sx={{ margin: "0 16px 0 0" }}
                />
              ))}
            </Box>
          ) : (
            <>
              {listCommunities?.pueblosIndigenas.length > 0 ? (
                <Carousel
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  partialVisible={true}
                >
                  {listCommunities?.pueblosIndigenas?.map((item, index) => (
                    <Box
                      component={Fade}
                      key={index}
                      sx={{
                        padding: "24px 16px 24px 0",
                      }}
                    >
                      <CardDetails
                        data={{
                          id: item.id_pueblo_indigena,
                          image:
                            item.vc_image !== null
                              ? item.vc_image
                              : "/images/not_indigena.webp",
                          title: item.vc_nombre,
                        }}
                        height={220}
                        onClick={() => handleDetail(item.id_pueblo_indigena)}
                      />
                    </Box>
                  ))}
                </Carousel>
              ) : (
                <Typography sx={stylesFont.subTitleSectionHome}>
                  No hay Datos
                </Typography>
              )}
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
            <ButtonCuston
              text="Ver todos"
              link="/community"
              onClick={scrollToTop}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
