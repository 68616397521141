import React, { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import { Box, Toolbar, Typography } from "@mui/material";

import "./bg.css";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useAuth from "../../utils/hooks/useAuth";

import AppBarAdmin from "../../components/Admin/AppBarAdmin";
import DrawerAdmin from "../../components/Admin/DrawerAdmin";
import { Container } from "@mui/system";
import { CommunitiesAdminProvider } from "../../context/admin/CommunitiesProvider";
import { AuthorsAdminProvider } from "../../context/admin/authorsProvider";
import { ConservationAdminProvider } from "../../context/admin/conservationProvider";
import { SpeciesAdminProvider } from "../../context/admin/speciesProvider";

const defaultTheme = createTheme();

export default function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(!isMobile);

  const { loading } = useAuth();
  const token = localStorage.getItem("token_raices"); // Obtiene el token del almacenamiento local

  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight={700}
          height="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          Bienvenido
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* Newa Provider*/}
      <SpeciesAdminProvider>
        <AuthorsAdminProvider>
          <CommunitiesAdminProvider>
            <ConservationAdminProvider>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "rgb(243, 244, 249)",
                }}
              >
                <CssBaseline />

                <AppBarAdmin open={open} toggleDrawer={toggleDrawer} />
                <DrawerAdmin open={open} toggleDrawer={toggleDrawer} />

                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                  }}
                >
                  <Toolbar />
                  <Container maxWidth="xl">
                    {token ? <Outlet /> : <Navigate to="/login" />}
                  </Container>
                </Box>
              </Box>
            </ConservationAdminProvider>
          </CommunitiesAdminProvider>
        </AuthorsAdminProvider>
      </SpeciesAdminProvider>
    </ThemeProvider>
  );
}
