import React, { useEffect, useState } from "react";
import { Typography, CardActionArea, Box, Avatar, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getSearchData } from "./localStorageUtil";

const styleHover = {
  "&:hover": {
    backgroundColor: "rgba(1, 94, 128, 0.1)",
    border: "1px solid #015E80",
    cursor: "pointer",
  },
};

const RecentSearch = ({ handleClose }) => {
  const [arrayItems, setArrayItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getSearchArray = async () => {
      const data = await getSearchData();
      return data;
    };

    getSearchArray().then((data) => {
      setArrayItems(data);
    });

    // setArrayItems(data);
  }, []);

  return (
    <>
      {arrayItems && arrayItems.length > 0 && (
        <Typography
          id="modal-modal-title"
          variant="subtitle1"
          component="h2"
          color="gray"
          sx={{
            paddingX: { xs: 2, md: 0 },
          }}
        >
          Recientes
        </Typography>
      )}
      {arrayItems?.length > 0 &&
        arrayItems?.slice(0, 5).map((item, index) => (
          <CardActionArea
            key={index}
            sx={{
              borderRadius: 4,
            }}
            onClick={() => {
              navigate(item.url);
              handleClose();
            }}
          >
            <Box
              paddingY={1}
              display="flex"
              alignItems={"center"}
              sx={styleHover}
              borderRadius={4}
              color={"#015E80"}
              justifyContent={"space-between"}
            >
              <Box paddingX={2} display={"flex"} alignItems={"center"}>
                {/* <Grid3x3OutlinedIcon /> */}
                <Avatar
                  src={
                    item.image
                      ? item.image
                      : "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                  }
                  sx={{
                    // width: 32,
                    // height: 32,
                    backgroundColor: "#ffffff",
                    borderColor: "#015E80",
                    border: "1px solid",
                  }}
                />
                <Box>
                  <Typography variant="subtitle1" component="h2" marginLeft={2}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="h2"
                    marginLeft={2}
                    fontStyle={"italic"}
                  >
                    {item.subTitle}
                  </Typography>
                </Box>
              </Box>
              <Box marginRight={2}>
                <Chip label={item.tag} variant="outlined" size="small" />
              </Box>
            </Box>
          </CardActionArea>
        ))}
    </>
  );
};

export default RecentSearch;
