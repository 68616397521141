import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListFamily = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/families`);
    const data = await response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchFamilyList = async (
  token,
  currentPage,
  sizePage,
  search = ""
) => {
  try {
    const url = search
      ? `${API_BASE_URL}/families/admin/search/${search}/${currentPage}/${sizePage}`
      : `${API_BASE_URL}/families/admin/${currentPage}/${sizePage}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchFamilyById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/families/${id}`, {});
    const data = await response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveOrEditDataFamily = async (token, data, id = "") => {
  try {
    const url = id
      ? `${API_BASE_URL}/families/edit/${id}`
      : `${API_BASE_URL}/families/create`;

    const method = id ? "patch" : "post";

    const response = await axios.request({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const changeStatusDataFamily = async (token, id, body) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/families/delete/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      }
    );

    return response;
  } catch (error) {
    throw error.response;
  }
};
