/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { LayoutsFrmDetails } from "../../../../../components";
import SectionDetailsTown from "./SectionDetailsTown";

import { useCommunities } from "../../../../../hooks/admin/useCommunities";
import { useLogicCommunitiesAdmin } from "../../../../../context/admin/CommunitiesProvider";

const DetailsTowsIndex = () => {
  const { id_community } = useLogicCommunitiesAdmin();
  const { getDataCommunityById, community, loading } = useCommunities();

  useEffect(() => {
    if (id_community) {
      getDataCommunityById("", id_community);
    }
  }, [id_community]);

  return (
    <>
      <LayoutsFrmDetails
        title={community?.vc_nombre}
        subTitle={community?.updated_at}
        image={community?.vc_image}
        label={"Comunidades"}
        loading={loading}
      >
        <SectionDetailsTown town={community} />
      </LayoutsFrmDetails>
    </>
  );
};

export default DetailsTowsIndex;
