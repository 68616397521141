import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { stylesCard } from "../../../styles";

export default function FolderList({ number, categorie, color, iconCategory }) {
  FolderList.propTypes = {
    number: PropTypes.number.isRequired,
    categorie: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    iconCategory: PropTypes.string.isRequired,
  };

  return (
    <List sx={{ width: "100%" }}>
      <ListItem
        sx={{
          // border: "solid 1px  #DDE1E6",
          borderRadius: "8px",
          backgroundColor: "#ffffff",
          p: 1,
        }}
      >
        <ListItemAvatar>
          <Avatar style={{ background: `${color}` }}>
            <img
              src={iconCategory}
              alt="iconCategoria"
              style={{ height: "24px" }}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography sx={stylesCard.titleCard}>{number}</Typography>}
          secondary={
            <Typography
              sx={{
                fontSize: {
                  xs: "0.7rem ",
                  sm: "0.8rem ",
                  md: "0.9rem ",
                  lg: "1",
                },
              }}
            >
              {categorie}
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}
