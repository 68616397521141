/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ButtonCustom from "../../../../../components/Admin/ButtonCustom";
import TextFieldCustom from "../../../../../components/Admin/TextFieldCustom";
import { styleSections, subtitleSection } from "../../styles";
import { validateInput } from "../../../../../infrastructure/ui/validatorInputs";
import {
  Box,
  FormControl,
  Typography,
  Alert,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import AutocompleteCustom from "../../../../../components/Admin/AutocompleteCustom";
import { useTaxasAdmin } from "../../../../../hooks";
import { usePagination } from "../../../../../hooks/admin/usePagination";

const taxonomies = [
  {
    id: 0,
    name: "Clases",
    api: "class",
    fk_id: "id_filo",
  },
  {
    id: 1,
    name: "Orden",
    api: "order",
    fk_id: "id_clase",
  },
  {
    id: 2,
    name: "Familia",
    api: "family",
    fk_id: "id_orden",
  },
];

export const CrudTaxonomies = ({ apikey, value, taxaSelected }) => {
  // const [loadingData, setLoadingData] = useState(false);
  const { getParams, updateUrlParam } = usePagination();
  const {
    listForOptions,
    getListForOptions,
    getTaxaBy,
    dataTaxa,
    createOeEditTaxa,
    loading,
    message: messsageTaxa,
  } = useTaxasAdmin();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    vc_nombre: "",
    id_taxa: "",
  });

  const id = getParams("id", "");
  const view = getParams("view", "");

  useEffect(() => {
    if (messsageTaxa?.message !== "") {
      setOpenSnackbar(true);
    }
  }, [id]);

  useEffect(() => {
    getListForOptions(taxonomies[value].api);
  }, [value]);

  const [messsage, setMessage] = useState("");

  useEffect(() => {
    if (messsage !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [messsage]);

  const saveData = () => {
    if (Object.values(formData).some((value) => value === "")) {
      setMessage("Todos los campos son obligatorios");
      return;
    }
    if (validateInput(formData.vc_nombre, "onlyLetters").valid === false) {
      setMessage("El nombre solo puede contener letras sin espacios");
      return;
    }
    createOeEditTaxa(
      taxonomies[value].api,
      taxonomies[value]?.fk_id,
      id,
      formData
    );
    clearValues();
  };

  const clearValues = () => {
    setFormData({
      ...formData,
      vc_nombre: "",
      id_taxa: "",
    });
  };

  useEffect(() => {
    if (id !== "") {
      setFormData({
        vc_nombre: dataTaxa.vc_nombre,
        id_taxa: dataTaxa[taxonomies[value].fk_id],
      });
    }
  }, [id, dataTaxa]);

  useEffect(() => {
    if (view !== "") {
      setFormData({
        vc_nombre: dataTaxa.vc_nombre,
        id_taxa: dataTaxa[taxonomies[value].fk_id],
      });
    }
  }, [view, dataTaxa]);

  useEffect(() => {
    clearValues();
  }, [taxaSelected]);

  useEffect(() => {
    if (id !== "") {
      getTaxaBy(taxonomies[value].api, id);
    }
  }, [id]);

  useEffect(() => {
    if (view !== "") {
      getTaxaBy(taxonomies[value].api, view);
    }
  }, [view]);

  const severity = messsageTaxa?.status === "success" ? "success" : "error";

  return (
    <Box sx={styleSections}>
      <Typography variant="h6">
        {id === ""
          ? `Crear una nueva ${taxaSelected?.title}`
          : `Editar ${taxaSelected?.title}`}
      </Typography>
      {loading ? (
        <Box pt={2}>
          <LinearProgress />
        </Box>
      ) : null}
      {messsage !== "" ? (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {messsage}
        </Alert>
      ) : null}
      <FormControl fullWidth>
        <Typography sx={subtitleSection} textTransform={"capitalize"}>
          {taxaSelected?.subTitle}
        </Typography>
        <AutocompleteCustom
          placeholder={"Seleccionar " + taxaSelected?.subTitle}
          options={listForOptions}
          size={"small"}
          value={formData?.id_taxa}
          onChange={(e, value) => {
            setFormData({ ...formData, id_taxa: value ? value.id : "" });
          }}
          disabled={view !== ""}
        />
        <Typography sx={subtitleSection}>
          Nombre de {taxaSelected?.title}
        </Typography>
        <TextFieldCustom
          placeholder={"Escribe el nombre de " + taxaSelected?.title}
          size={"small"}
          value={formData.vc_nombre}
          onChange={(e) => {
            setFormData({ ...formData, vc_nombre: e.target.value });
          }}
          error={!validateInput(formData.vc_nombre, "onlyLetters").valid}
          disabled={view !== ""}
        />
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <ButtonCustom
            label={id === "" ? "Guardar" : "Guardar cambios"}
            color="primary"
            onClick={() => saveData()}
            disabled={view !== ""}
          />
          <ButtonCustom
            label={view !== "" ? "Cerrar detalle" : "Cancelar"}
            color="error"
            onClick={() => {
              updateUrlParam("id", "");
              updateUrlParam("view", "");
              clearValues();
            }}
          />
        </Box>
      </FormControl>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity={severity}>{messsageTaxa?.message}</Alert>
      </Snackbar>
    </Box>
  );
};
