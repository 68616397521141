import React from "react";
import {
  Button,
  Fade,
  Box,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import { stylesFont } from "../../../../styles";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CloseIcon from "@mui/icons-material/Close";

import { PDFViewer, usePDF } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
// import html2canvas from "html2canvas";

import MyDocument from "./myDocument";
import { modal, styleButton, styleHeader, styleFooter } from "./styles";

export default function CustomizedMenus({ specie, disableButton }) {
  // console.log(specie);
  const [open, setOpen] = React.useState(false);

  const [instance] = usePDF({
    document: <MyDocument data={specie ? specie : {}} />,
  });
  if (instance.loading) return <div>Loading...</div>;
  if (instance.error) return <div>Something went wrong</div>;

  const handleModal = () => {
    setOpen(!open);
  };

  const downloadPDF = () => {
    if (!instance.loading && !instance.error) {
      saveAs(instance.blob, `ficha_${specie.vc_nombre}.pdf`);
      handleModal();
    }
  };

  const printPDF = () => {
    if (!instance.loading && !instance.error) {
      window.open(instance.url, "_blank");
      handleModal();
    }
  };

  // const saveAsImage = async () => {
  //   if (!instance.loading && !instance.error) {
  //     const pdfViewer = document.getElementById("pdf-viewer");
  //     console.log(pdfViewer);

  //     if (pdfViewer) {
  //       const canvas = await html2canvas(pdfViewer);
  //       const imageData = canvas.toDataURL("image/png");

  //       saveAs(imageData, `ficha_${specie.vc_nombre}.png`);
  //       handleModal();
  //     }
  //   }
  // };

  return (
    <>
      <Button
        onClick={() => handleModal()}
        color="warning"
        variant="contained"
        size="small"
        disabled={disableButton}
        sx={{
          borderRadius: "8px",
        }}
        endIcon={<DownloadRoundedIcon />}
      >
        <Typography
          sx={{
            textTransform: "capitalize",
          }}
        >
          Descargar Ficha
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={() => handleModal()}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            id="pdf-viewer"
            sx={modal}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Box sx={styleHeader}>
              <Typography sx={{ ...stylesFont.titleCard }}>
                Descargar ficha
              </Typography>
              <IconButton
                sx={{
                  color: "white",
                }}
                onClick={() => handleModal()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Visualizador de pdf */}
            {instance.loading ? (
              <div>Loading...</div>
            ) : instance.error ? (
              <div>Something went wrong</div>
            ) : (
              <PDFViewer
                style={{ width: "100%", height: "100%" }}
                src={instance.url}
              />
            )}
            <Box sx={styleFooter}>
              <Button
                variant="contained"
                sx={styleButton}
                onClick={() => downloadPDF()}
              >
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  Como Pdf
                </Typography>
              </Button>
              {/* <Button
                variant="contained"
                sx={{ ...styleButton, backgroundColor: "#feb602" }}
                onClick={() => saveAsImage()}
              >
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  Como imagen
                </Typography>
              </Button> */}
              <IconButton
                onClick={() => printPDF()}
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "#e3e9ed",
                }}
              >
                <LocalPrintshopIcon />
              </IconButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
