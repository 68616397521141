import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListDataClass = async () => {
  const response = await axios.get(`${API_BASE_URL}/classes`);
  return response.data;
};

// export const getListDataClass = async (token, currentPage, sizePage, search = "") => {
//   try {
//     const url = search
//       ? `${API_BASE_URL}/classes/admin/search/${search}/${currentPage}/${sizePage}`
//       : `${API_BASE_URL}/classes/admin/${currentPage}/${sizePage}`;

//     const response = await axios.get(url, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const getDataClassById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/classes/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveOrEditDataClass = async (token, data, id = "") => {
  try {
    const url = id
      ? `${API_BASE_URL}/classes/edit/${id}`
      : `${API_BASE_URL}/classes/create`;

    const method = id ? "patch" : "post";

    const response = await axios.request({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

//change status
export const ChangeStatusDataClass = async (token, id, body) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/classes/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
