import React, { useState } from "react";
import {
  Box,
  CardActionArea,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  CircularProgress,
  Card,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CustonDialogAdmin } from "../DialogCuston";

export const UpLoadAudio = ({ data, loading, handleDelete, handleUpLoad }) => {
  const [audioFile, setAudioFile] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDialog = () => {
    setOpen(!open);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".mp3")) {
      setAudioFile(selectedFile);
    } else {
      alert("Por favor, seleccione un archivo .mp3.");
    }
  };

  const handleRemoveAudio = () => {
    setAudioFile(null);
  };

  const uploadImage = () => {
    if (audioFile) {
      handleUpLoad(audioFile);
    }
  };

  //   const handleDeleteAudio = () => {
  //     handleDelete();
  //   };

  return (
    <>
      {data?.audio ? (
        <List dense={true}>
          <ListItem
            secondaryAction={
              <IconButton
                color="warning"
                sx={{
                  borderRadius: "0.5rem",
                  border: "2px solid",
                }}
                onClick={handleDialog}
              >
                <Delete />
              </IconButton>
            }
          >
            <ListItemText>
              <Box
                component={"audio"}
                controls
                src={data.audio}
                type="audio/mpeg"
                sx={{
                  width: "calc(100% - 2rem)",
                }}
              />
            </ListItemText>
          </ListItem>
        </List>
      ) : (
        <>
          <Card
            sx={{
              padding: 2,
              borderRadius: "0.5rem",
              boxShadow: "none",
              border: "2px dashed #c4c4c4",
            }}
          >
            {!audioFile ? (
              <CardActionArea sx={{ borderRadius: 4, padding: 1 }}>
                <label htmlFor="audio-upload" style={{ cursor: "pointer" }}>
                  <Box display="flex" alignItems="center">
                    <Box
                      borderRadius={100}
                      bgcolor="#F5F5F5"
                      padding={1}
                      marginRight={1}
                    >
                      <CloudUploadOutlinedIcon sx={{ fontSize: 40 }} />
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={700}
                        component="div"
                      >
                        Click para cargar el archivo del audio
                      </Typography>
                      <Typography variant="subtitle2" color="gray">
                        (Solo archivos .mp3)
                      </Typography>
                    </Box>
                  </Box>
                </label>
                <input
                  type="file"
                  id="audio-upload"
                  accept=".mp3"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </CardActionArea>
            ) : (
              <>
                <CardActionArea
                  sx={{ borderRadius: 4, padding: 1 }}
                  onClick={handleRemoveAudio}
                  style={{ cursor: "pointer" }}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      borderRadius={100}
                      bgcolor="#F5F5F5"
                      padding={1}
                      marginRight={1}
                    >
                      {loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <IconButton
                          onClick={handleRemoveAudio}
                          size="medium"
                          color="primary"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={700}
                        component="div"
                      >
                        Archivo de audio cargado: {audioFile.name}
                      </Typography>
                      <Typography variant="subtitle2" color="gray">
                        Haga clic para eliminar
                      </Typography>
                    </Box>
                  </Box>
                </CardActionArea>
                <Box display="flex" justifyContent="center" marginY={2}>
                  <Button
                    onClick={uploadImage}
                    variant="outlined"
                    size="small"
                    color="success"
                    disabled={loading ? true : false}
                    endIcon={
                      loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <FileUploadOutlinedIcon />
                      )
                    }
                    fullWidth
                  >
                    Subir Audio
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </>
      )}
      <CustonDialogAdmin
        open={open}
        onClose={handleDialog}
        title={"Eliminar audio"}
        message={"¿Está seguro que desea eliminar el audio?"}
        handleAction={() => {
          handleDelete();
          handleDialog();
        }}
      />
    </>
  );
};
