import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getListAuthors = async (token, search, currentPage, sizePage) => {
  const apiUrl = search
    ? `${API_BASE_URL}/authors/admin/search/${search}/${currentPage}/${sizePage}`
    : `${API_BASE_URL}/authors/admin/${currentPage}/${sizePage}`;
  try {
    const response = await axios.get(apiUrl, axiosConfig(token));

    return response;
  } catch (error) {
    return error;
  }
};

export const getAuthorById = async (token, id) => {
  const response = await axios.get(
    `${API_BASE_URL}/authors/admin/${id}`,
    axiosConfig(token)
  );
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAuthor = async (token, id, body) => {
  const response = await axios.delete(`${API_BASE_URL}/authors/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: body,
  });
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const saveOrEditAuthor = async (token, body, id) => {
  const apiUrl = id
    ? `${API_BASE_URL}/authors/edit/${id}`
    : `${API_BASE_URL}/authors/create`;
  const method = id ? "patch" : "post";
  const response = await axios[method](apiUrl, body, axiosConfig(token));
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const addUpdateImgAuthor = async (token, id, body) => {
  const response = await axios.put(
    `${API_BASE_URL}/authors/addUpdateImg/${id}`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteImgAuthor = async (token, id) => {
  const response = await axios.delete(
    `${API_BASE_URL}/authors/deleteImg/${id}`,
    axiosConfig(token)
  );
  try {
    return response;
  } catch (error) {
    return error;
  }
};
