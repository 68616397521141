import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const FetchDataSearchGeneral = async (search) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/general-search/${search}`
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response;
  }
};
