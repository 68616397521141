import { Select, MenuItem, Typography } from "@mui/material";
import { stylesComponents } from "../../presentation/styles/stylesComponents";

export const CustonSelect = ({ options, ...props }) => {
  return (
    <Select
      {...props}
      sx={{
        ...stylesComponents.selectSolid,
        height: props.height ? props.height : "2.2rem",
      }}
    >
      {options &&
        options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <Typography>{item.name}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
};
