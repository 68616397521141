/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import BiotechIcon from "@mui/icons-material/Biotech";
import { useGeneralCounter } from "../../../../hooks/admin/useGeneralCounter";
import { Fade } from "react-awesome-reveal";

const styleCards = {
  title: {
    fontSize: { xs: "1.1rem", lg: "1.5rem" },
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: { xs: "0.8rem", lg: "1rem" },
    fontWeight: "bold",
  },
};

export const IndicatorSection = () => {
  const { generalCounter, getGeneralCounterData } = useGeneralCounter();
  const [dataCount, setDataCount] = useState([
    { id: 1, name: "Especies", count: 0 },
    { id: 2, name: "Pueblos indígenas", count: 0 },
    { id: 3, name: "Autores", count: 0 },
  ]);

  useEffect(() => {
    getGeneralCounterData();
  }, []);

  useEffect(() => {
    if (generalCounter) {
      const dataCountNew = [
        {
          id: 1,
          name: "Especies",
          count: generalCounter?.species?.publishedSpecies,
          image: "/images/svg/mono.png",
          color: "#015E80",
        },
        {
          id: 2,
          name: "Comunidades",
          count: generalCounter?.communities?.totalCommunities,
          image: "/images/svg/choza.png",
          color: "#886D5B",
        },
        {
          id: 3,
          name: "Autores",
          count: generalCounter?.authors?.activeAuthors,
          image: <BiotechIcon />,
          color: "#3B8476",
        },
      ];
      setDataCount(dataCountNew);
    } else {
      setDataCount([]);
    }
  }, [generalCounter]);

  return (
    <Box component={"section"}>
      <Grid container spacing={2}>
        {dataCount?.map((item, index) => (
          <Grid item xs={12} key={item.id}>
            <Fade
              style={{
                width: "100%",
              }}
            >
              <Card
                sx={{
                  borderRadius: "8px",
                  width: "100%",
                  boxShadow: "none",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Avatar
                    sx={{
                      width: { xs: 28, lg: 24 },
                      height: { xs: 28, lg: 24 },
                      backgroundColor: item.color,
                      color: "#ffffff",
                      p: 1,
                    }}
                    src={
                      typeof item.image === "string" ? item.image : undefined
                    }
                  >
                    {typeof item.image !== "string" ? item.image : null}
                  </Avatar>
                  <Box marginLeft={2}>
                    <Typography sx={styleCards.title}>{item.count}</Typography>
                    <Typography
                      sx={{ ...styleCards.subTitle, color: "GrayText" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
