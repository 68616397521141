import { createContext, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useLogicSpecies } from "./speciesProvider";

const ConservationContex = createContext();

const ConservationProvider = ({ children }) => {
  const { updateUrlParam } = useLogicSpecies();
  const [searchParams] = useSearchParams();
  const selectedConservation =
    searchParams.get("conservacion") === null
      ? ""
      : parseInt(searchParams.get("conservacion"));

  const setSelectedConservation = (value) => {
    updateUrlParam("conservacion", value);
  };

  return (
    <ConservationContex.Provider
      value={{
        selectedConservation,
        setSelectedConservation,
      }}
    >
      {children}
    </ConservationContex.Provider>
  );
};

export { ConservationProvider };
export const useLogicConservation = () => useContext(ConservationContex);
