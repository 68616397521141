/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import { Typography, Grid, Box, Divider } from "@mui/material";
import { stylesDetail, styleDivider } from "../../styles";
import { stylesFont } from "../../../../../styles";
import { speciesArray } from "../../../../styles/colors";
import DetailSection from "./detailSection";
import ImageSection from "./imageSection";
import AuthorSpecieSection from "./autorSpecieSection";
// import { IconsTaxonomia } from "../../../../../infrastructure/data/ArrayTaxas";

import { Helmet } from "react-helmet";
import ThreeScene from "../../../Administrador/species/FormDetailsSpecies/ThreeScene";
import Model from "../../../Administrador/species/FormDetailsSpecies/Model";

import { OrbitControls } from "@react-three/drei";

const InfoSpecieSection = ({ specie }) => {
  const getTypeSpecie = () => {
    const type = speciesArray.find(
      (species) => species.name === specie?.taxa?.vc_nombre
    );
    return type;
  };

  console.log(specie);
  return (
    <>
      <Helmet>
        <title>
          {specie?.vc_nombre
            ? `${specie?.vc_nombre} - Detalle de la especie`
            : `Detalle de la especie`}
        </title>
        <meta
          property="og:url"
          content={`https://amazonia.iiap.gob.pe/species/${specie?.id_especie}`}
        />
        <meta property="og:type" content="website" />
        <meta name="description" content={specie?.te_descripcion} />
        <meta
          property="og:title"
          content={`${specie?.vc_nombre} - Detalle de la especie`}
        />
        <meta property="og:description" content={specie?.te_descripcion} />
        <meta property="og:image" content={specie?.vc_imagen} />
      </Helmet>

      <Box textAlign={"center"} marginBottom={2}>
        <Typography
          sx={stylesDetail.titleLarge}
          color={getTypeSpecie()?.primaryColor}
        >
          {specie?.vc_nombre}
        </Typography>
        <Typography
          sx={stylesDetail.titleMedium}
          color={getTypeSpecie()?.secondaryColor}
        >
          {specie.vc_nombre_ingles}
        </Typography>
        <Box
          display={{ sm: "block", md: "flex" }}
          justifyItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <Typography sx={stylesDetail.titleSmall}>
            {specie.vc_nombre_cientifico}
          </Typography>
          <Typography
            key={specie.id_especie}
            sx={{
              ...stylesDetail.titleSmall,
              fontStyle: "normal",
            }}
          >
            {specie?.autores?.length > 0 && (
              <>
                {specie?.autores?.map((author, index) => {
                  console.log(author);
                  const firstWord = author?.vc_nombre.split(" ")[0]; // Divide la cadena en palabras y toma la primera
                  return (
                    <React.Fragment key={index}>
                      {index > 0 && ", "}{" "}
                      {/* Agrega coma solo si no es el primer autor */}
                      {author?.actualizado === 1
                        ? `(${firstWord})`
                        : firstWord}{" "}
                    </React.Fragment>
                  );
                })}
                {specie.vc_ano}
              </>
            )}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <ImageSection species={specie} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DetailSection species={specie} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pb: 2,
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Divider sx={styleDivider} />
              <Typography sx={stylesDetail.titleSection}>
                Descripción
              </Typography>
            </Box>
          </Box>

          <Typography sx={stylesFont.text}>{specie?.te_descripcion}</Typography>
        </Grid>
        <Grid item xs={12}>
          <AuthorSpecieSection authors={specie?.autores} />
        </Grid>
        {specie.id_especie === 201 && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pb: 2,
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Divider sx={styleDivider} />
                <Typography sx={stylesDetail.titleSection}>
                  Modelo 3D
                </Typography>
              </Box>
            </Box>
            <Box height="60vh">
              <ThreeScene>
                <color attach="background" args={["#e8fcad"]} />
                <Suspense fallback={null}>
                  <Model />
                </Suspense>
                <pointLight position={[-100, 100, 10]} />
                <ambientLight intensity={6} />
                <OrbitControls
                  enableZoom={true}
                  enablePan={true}
                  enableRotate={true}
                />
                {/* <Stars /> */}
              </ThreeScene>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default InfoSpecieSection;
