/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useCategories } from "../../../hooks/clients/useCategories";
import { useLogicCategories } from "../../../context/client/categoriesProvider";

import {
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Skeleton,
  Box,
} from "@mui/material";

const styles = {
  // color: "black",
  maxHeight: "60px",
  width: "100%",
  py: { xs: 6, md: 3, lg: 0 },
  "&.Mui-selected": {
    // backgroundColor: bgItemSelectMenu,
    backgroundColor: "selected.main",
    color: "white",
  },
  "&:hover": {
    // backgroundColor: bgItemSelectMenu,
    backgroundColor: "selected.main",
    color: "white",
    "& img": {
      transform: "scale(2.1)", // Aumenta el tamaño en hover
    },
    // color of typhography in hover
    "& .MuiTypography-root": {
      // color: bgItemSelectMenu,
      color: "selected.main",
    },
  },
};

const styleBottomNavigation = {
  display: { xs: "none", md: "flex" },
  direction: "row",
  backgroundColor: "transparent",
  width: "100%",
  height: "auto",
  maxHeight: "60px",
  //max wid
  justifyContent: "space-around",
  alignItems: "center",
  maxWidth: "lg",
  marginLeft: "auto",
  marginRight: "auto",
};

export const MenuBar = () => {
  const isHome = useLocation().pathname === "/";
  const isSpecies = useLocation().pathname === "/species";
  const [imageLoader, setImageLoader] = useState(false);
  const location = useLocation();

  const { getCategories, taxonomies } = useCategories();
  const { selectedCategoryId, handleCategorySelection, clearCategorySelected } =
    useLogicCategories();

  const handleActionClick = (id) => {
    handleCategorySelection(id);
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (isHome) {
      clearCategorySelected();
    }
  }, [location.pathname]);

  return (
    <>
      <BottomNavigation sx={styleBottomNavigation} value={selectedCategoryId}>
        {taxonomies?.map((taxa) => (
          <BottomNavigationAction
            key={taxa.id_taxonomia}
            sx={styles}
            component={!isSpecies ? Link : "div"}
            to={`/species?categoria=${taxa.id_taxonomia}`}
            label={
              imageLoader && (
                <Typography variant="subtitle1" component="h2" color="white" mb={1}>
                  {taxa.vc_nombre}
                </Typography>
              )
            }
            showLabel={true}
            value={taxa.id_taxonomia}
            onClick={() => handleActionClick(taxa.id_taxonomia)}
            icon={
              <>
                {!imageLoader && (
                  <Box py={1}>
                    <Skeleton variant="circular" width={40} height={40} />
                  </Box>
                )}
                <img
                  src={taxa.vc_imagen}
                  alt="Imagen"
                  style={{
                    height: "24px",
                    paddingTop: 14,
                    display: imageLoader ? "block" : "none",
                    transition: "transform 0.2s",
                  }}
                  onLoad={() => setImageLoader(true)}
                />
              </>
            }
          />
        ))}
      </BottomNavigation>
    </>
  );
};
