export const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "60%" },
  height: { xs: "100%", sm: "90%" },
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
};

export const styleButton = {
  boxShadow: "none",
  backgroundColor: "#44b678",
};

export const styleHeader = {
  p: 2,
  backgroundColor: "primary.main",
  color: "white",
  position: "sticky",
  top: 0,
  zIndex: 100,
  borderRadius: "8px 8px 0 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const styleFooter = {
  p: { xs: 1 },
  display: "flex",
  gap: 1,
  backgroundColor: "white",
  // position: "sticky",
  // bottom: 0,
  // zIndex: 100,
  justifyContent: "center",
  borderRadius: "0 0 8px 8px",
};
