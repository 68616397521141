import React from "react";
import IndexAuthors from "../components/Authors/listAuthors/indexAuthors";

export default function AuthorPage() {
  return (
    <>
      <IndexAuthors />
    </>
  );
}
