import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getAuthorsNotInSpecie = async (token, id, body) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/species/admin/authors/not-in-specie/${id}    `,
      body,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const addAuthorToSpecie = async (token, body) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/species/esp_autor`,
      body,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const deleteAuthorFromSpecie = async (token, body) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/species/delete_autor/`,
      {
        ...axiosConfig(token),
        data: body,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getConservationsNotInSpecie = async (token, id, body) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/species/admin/conserv-status/not-in-specie/${id}`,
      body,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const addConservationToSpecie = async (token, body) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/species/est_conservacion`,
      body,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteConservationFromSpecie = async (token, body) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/species/associate/est_conservacion`,
      {
        ...axiosConfig(token),
        data: body,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
