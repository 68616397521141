import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getCategories = async (token, search) => {
  const apiUrl = search
    ? `${API_BASE_URL}/taxonomy/admin/search/${search}`
    : `${API_BASE_URL}/taxonomy/admin`;
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("List Categories not found");
      return null;
    }

    throw error;
  }
};

export const getCategory = async (token, id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/taxonomy/admin/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("Category not found");
      return null;
    }

    throw error;
  }
};

export const saveOrEditCategory = async (token, body, id) => {
  const apiUrl = id
    ? `${API_BASE_URL}/taxonomy/edit/${id}`
    : `${API_BASE_URL}/taxonomy/create`;
  const method = id ? "PATCH" : "POST";
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("List Authors not found");
      return null;
    }

    throw error;
  }
};

export const deleteCategory = async (token, id, body) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/taxonomy/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      }
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("Category not found");
      return null;
    }

    throw error;
  }
};
