import { Box, Container, Pagination, Typography } from "@mui/material";
import { LoadingInfo } from "../loadingInfo";
import { SearchInputSection } from "../SearchInputSection";
// import { CardDetails } from "../Cards/CardDetails";
import { stylesFont } from "../../styles";
import { CustonSelect } from "./CustonSelect";
import { optionsItemsPerPage } from "../../utils/constants";

export const SectionSearch = ({
  // data,
  defaultValues,
  loading,
  onChangePage,
  onChangeItems,
  onChangeSearch,
  children,
  ...props
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          position: "sticky",
          top: { xs: 56, sm: 64, md: 124 }, // { xs: 56, sm: 64
          zIndex: 100,
          pt: 2,
          pb: 2,
        }}
      >
        <Container maxWidth={"lg"}>
          <Typography sx={{ ...stylesFont.titleSection, mb: 2 }}>
            {props.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, sm: 2 },
            }}
          >
            <SearchInputSection
              loading={loading}
              defaultValue={defaultValues?.search}
              onResult={onChangeSearch}
            />
            <CustonSelect
              size="small"
              variant="outlined"
              options={optionsItemsPerPage}
              value={defaultValues?.pageSize ? defaultValues?.pageSize : 15}
              displayEmpty
              onChange={(e) => onChangeItems(parseInt(e.target.value))}
              height={"100%"}
            />
          </Box>
        </Container>
      </Box>
      <Container maxWidth={"lg"}>
        <Box>{loading ? <LoadingInfo /> : <Box>{children}</Box>}</Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={props?.totalPages}
            variant="outlined"
            color="primary"
            page={defaultValues?.currentPage ? defaultValues?.currentPage : 1}
            onChange={onChangePage}
          />
        </Box>
      </Container>
    </>
  );
};
