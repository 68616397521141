/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Diversity1RoundedIcon from "@mui/icons-material/Diversity1Rounded";
import BiotechIcon from "@mui/icons-material/Biotech";
import FlagIcon from "@mui/icons-material/Flag";
import StyleIcon from "@mui/icons-material/Style";
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import { Tooltip } from "@mui/material";

const buttonData = [
  {
    id: "dashboard",
    to: "/admin",
    primary: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: "especies",
    to: "/admin/especies",
    primary: "Especies",
    icon: <FormatListBulletedIcon />,
  },
  {
    id: "towns",
    to: "/admin/towns",
    primary: "Pueblos",
    icon: <Diversity1RoundedIcon />,
  },
  {
    id: "autores",
    to: "/admin/autores",
    primary: "Autores",
    icon: <BiotechIcon />,
  },
  {
    id: "status-conservation",
    to: "/admin/status-conservation",
    primary: "Est. conservación",
    icon: <FlagIcon />,
  },
  {
    id: "category",
    to: "/admin/category",
    primary: "Categorias",
    icon: <CategoryRoundedIcon />,
  },
  {
    id: "taxonomia",
    to: "/admin/taxonomia",
    primary: "Taxonomía",
    icon: <StyleIcon />,
  },
];

const StyledListItemButton = ({
  id,
  to,
  primary,
  icon,
  onClick,
  isSelected,
}) => {
  const buttonStyles = {
    color: "white",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: isSelected ? "rgba(84, 158, 156, 0.3)" : "initial",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: isSelected
        ? "rgba(84, 158, 156, 0.7)"
        : "rgba(84, 158, 156, 0.5)",
    },
  };

  return (
    <Tooltip title={primary} placement="right">
      <ListItemButton
        component={Link}
        to={to}
        onClick={onClick}
        style={buttonStyles}
      >
        <ListItemIcon>
          {React.cloneElement(icon, {
            style: { color: isSelected ? "white" : "#c4c4c4" },
          })}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          style={{ color: isSelected ? "white" : "#c4c4c4" }}
        />
      </ListItemButton>
    </Tooltip>
  );
};

export const MainListItems = () => {
  const location = useLocation();
  const [selectedButton, setSelectedButton] = useState(null);
  const pathname = location.pathname.split("/")[2];

  useEffect(() => {
    setSelectedButton(pathname || "dashboard");
  }, [location.pathname]);

  const handleButtonClick = (id) => {
    setSelectedButton(id);
  };

  return (
    <React.Fragment>
      {buttonData.map((button, index) => (
        <StyledListItemButton
          key={index}
          id={button.id}
          to={button.to}
          primary={button.primary}
          icon={button.icon}
          isSelected={selectedButton === button.id}
          onClick={() => handleButtonClick(button.id)}
        />
      ))}
    </React.Fragment>
  );
};
