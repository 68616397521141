/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSpecies } from "../../hooks/clients/useSpecies";

const SpeciesContext = createContext();

const SpeciesProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getSpeciesByFilter, listDataSpecies, loading } = useSpecies();

  const getFilters = (name, defaultValue) => {
    const filter = searchParams.get(name);
    return filter === null ? defaultValue : filter;
  };

  const dataSpecies = {
    page: parseInt(getFilters("page", 1)),
    pageSize: getFilters("pageSize", 15),
    classId: getFilters("clase", ""),
    orderId: getFilters("orden", ""),
    familyId: getFilters("familia", ""),
    taxonomyId: getFilters("categoria", ""),
    hasSound: getFilters("multimedia", 2),
    conservationStatus: getFilters("conservacion", ""),
    search: getFilters("search", ""),
    orderBy: getFilters("orderBy", "vc_nombre"),
    orderType: getFilters("orderType", ""),
  };

  const filtersApplied = {
    clase: getFilters("clase", ""),
    orden: getFilters("orden", ""),
    familia: getFilters("familia", ""),
    categoria: getFilters("categoria", ""),
    multimedia: getFilters("multimedia", ""),
    conservacion: getFilters("conservacion", ""),
  };

  const updateUrlParam = (name, value) => {
    const params = new URLSearchParams(window.location.search);

    if (name === "page") {
      params.set(name, value);
    } else {
      if (value === "" || value === null) {
        params.delete(name);
      } else {
        params.set(name, value);
      }
      params.set("page", 1);
    }

    navigate(`?${params.toString()}`);
  };

  useEffect(() => {
    getSpeciesByFilter(dataSpecies);
  }, [searchParams]);

  const clearAllFilter = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("clase");
    params.delete("orden");
    params.delete("familia");
    params.delete("categoria");
    params.delete("multimedia");
    params.delete("conservacion");
    params.delete("search");
    params.delete("page");
    params.delete("pageSize");
    params.delete("orderBy");
    params.delete("orderType");
    navigate(`?${params.toString()}`);
  };

  return (
    <SpeciesContext.Provider
      value={{
        dataSpecies,
        listDataSpecies,
        loading,
        clearAllFilter,
        filtersApplied,
        //New functions
        updateUrlParam,
        getFilters,
      }}
    >
      {children}
    </SpeciesContext.Provider>
  );
};

export { SpeciesProvider };
export const useLogicSpecies = () => useContext(SpeciesContext);
