import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
// import { useNavigation } from "react-router-dom";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 460,
    backgroundColor: "white",
    "& .MuiTooltip-arrow": {
      color: "white",
    },
  },
});

export const CustomTooltip = () => {
  return (
    <CustomWidthTooltip
      arrow
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white", // Color de fondo blanco
            padding: "8px",
            borderRadius: "8px",
            gap: 3,
          }}
        >
          <Box component={"img"} src="/images/qr_link.webp" width={190} />
          <Box>
            <Typography variant="h5" fontWeight={900} color={"primary.main"}>
              Descargar la App de Amazonia
            </Typography>
            <Typography variant="body1" color={"GrayText"} mb={0}>
              Escanea el código QR para descargarla
            </Typography>
            <Box
              sx={{ display: "flex", gap: "8px", justifyContent: "center" }}
              href="https://play.google.com/store/apps/details?id=pe.gob.iiap.guiailustrada&pcampaingid=web_share"
            >
              <Box
                component={"img"}
                src="/images/homeSection/play.webp"
                alt="Imagen 1"
                width={150}
              />
              {/* <Box
                component={"img"}
                src="/images/homeSection/appstore.webp"
                alt="Imagen 2"
                width={150}
              /> */}
            </Box>
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          maxWidth: "140px",
        }}
      >
        <QrCodeScannerIcon fontSize="large" />
        <Typography
          variant="caption"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          Descargar la app de Amazonía
        </Typography>
      </Box>
    </CustomWidthTooltip>
  );
};
