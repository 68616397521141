// Función para almacenar en localStorage
export const storeSearchData = (data) => {
  // Recupera los datos actuales del localStorage
  const storedData = JSON.parse(localStorage.getItem("searchData")) || [];

  // Verifica si el elemento con el mismo id ya existe en el array
  const existingItemIndex = storedData.findIndex((item) => item.id === data.id);

  // Si no existe, agrega el nuevo elemento a la parte superior de la lista
  if (existingItemIndex === -1) {
    storedData.unshift(data);

    // Limita la lista a 5 elementos
    const limitedData = storedData.slice(0, 5);

    // Almacena los datos limitados en el localStorage
    localStorage.setItem("searchData", JSON.stringify(limitedData));
  }
};

// Función para recuperar datos del localStorage
export const getSearchData = () => {
  return JSON.parse(localStorage.getItem("searchData")) || [];
};

// Función para eliminar el elemento más antiguo si se superan 5 elementos
export const removeOldestDataIfNeeded = () => {
  const storedData = JSON.parse(localStorage.getItem("searchData")) || [];

  if (storedData.length > 5) {
    // Elimina el elemento más antiguo (último elemento)
    storedData.pop();
    localStorage.setItem("searchData", JSON.stringify(storedData));
  }
};

// Cuando se hace clic en un elemento de búsqueda, llamas a storeSearchData para almacenar los datos
// Asegúrate de que los datos que deseas almacenar se pasen como argumento a esta función

// Luego, puedes llamar a getSearchData para recuperar los datos almacenados en cualquier momento.
