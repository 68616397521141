import * as React from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
// import { Link as RouterLink } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
}

export default function IconBreadcrumbs({ arrayLinks, currentPage }) {
  const navigate = useNavigate();

  const navigateTo = (value) => {
    navigate(value.url);
  };
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#C0C0C0" }}>
        <Button
          sx={{
            color: "#C0C0C0",
            textDecoration: "none",
            textTransform: "capitalize",
            borderRadius: "0.5rem",
          }}
          size="small"
          component={Link}
          to="/"
          variant="text"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Button>
        {arrayLinks &&
          Array.isArray(arrayLinks) &&
          arrayLinks.map((link, index) => (
            <Button
              key={index}
              sx={{
                color: "#C0C0C0",
                textDecoration: "none",
                textTransform: "capitalize",
                borderRadius: "0.5rem",
              }}
              size="small"
              onClick={() => navigateTo(link)}
            >
              {link.label}
            </Button>
          ))}
        <Typography sx={{ display: "flex", alignItems: "center" }} color="#fff">
          {currentPage}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
