export const styleTitlePage = {
  component: "div",
  fontSize: "1.5rem",
  fontWeight: "bold",
  flexGrow: 1,
  // marginBottom: "16px",
  // marginTop: "16px",
};
export const styleSections = {
  width: "100%",
  padding: { xs: "1rem", lg: "1.5rem" },
  borderRadius: "0.5rem",
  backgroundColor: "white",
  marginBottom: "1rem",
};

export const stylesFonts = {
  titlePage: {
    fontSize: 24,
    fontWeight: 900,
  },
  titleLargeSection: {
    component: "div",
    flexGrow: 1,
    fontSize: "2rem",
    fontWeight: "bold",
  },
  subTitleLargeSection: {
    fontSize: "1rem",
    fontWeight: 700,
    flexGrow: 1,
    color: "gray",
  },
  titleSection: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    component: "div",
    flexGrow: 1,
    marginBottom: 1,
  },
  subtitleSection: {
    fontSize: "1rem",
    fontWeight: 700,
    color: "gray",
    component: "div",
    flexGrow: 1,
  },
  textSection: {
    fontSize: "1rem",
    component: "div",
    flexGrow: 1,
    color: "gray",
  },
};

//Titulo general de la seccion
export const titleLargeSection = {
  fontSize: "30px",
  fontWeight: "bold",
};

//Titulo de las secciones
export const titleSection = {
  fontSize: "1.1rem",
  fontWeight: "bold",
  component: "div",
  flexGrow: 1,
  marginBottom: 2,
};

//Subtitulo de las secciones
export const subtitleSection = {
  variant: "subtitle1",
  component: "div",
  marginTop: "8px",
  flexGrow: 1,
};

//Parrafos de las secciones
export const paragraphSection = {
  fontSize: "14px",
  component: "div",
  flexGrow: 1,
  marginBottom: "8px",
  marginTop: "8px",
  color: "gray",
};

//For details specie
export const styleTitleDetail = {
  variant: "subtitle2",
  component: "div",
  flexGrow: 1,
  fontWeight: 700,
  color: "black",
};

export const styleParagraphDetail = {
  fontSize: "14px",
  component: "div",
  sx: { flexGrow: 1 },
  color: "gray",
};

export const styleSubtitleDetail = {
  variant: "subtitle2",
  component: "div",
  with: 100,
  color: "gray",
};

export const styleButtonLight = {
  variant: "subtitle2",
  color: "gray",
  display: "flex",
  alignItems: "center",
  ":hover": {
    cursor: "pointer",
    color: "#1976d2",
  },
};
