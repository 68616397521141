import { Avatar, Box, Link, Typography } from "@mui/material";
import { ModalDetails } from "../../../components/modalDetails";

const staff = {
  team_coordination: {
    label: "Equipo de Coordinación",
    teams: [
      {
        label: "Coordinación editorial",
        members: ["Manuel Martin Brañas", "Juan José Bellido Collahuacho"],
      },
      {
        label: "Comité Revisor",
        teams: [
          {
            label: "Revision de Textos",
            members: [
              "Manuel Martin Brañas, IIAP",
              "Juan José Bellido Collahuacho, IIAP",
            ],
          },
          {
            label: "Revisión Anfibios y Reptiles",
            members: ["Giussepe Gagliardi Urrutia, IIAP"],
          },
          {
            label: "Revisión Aves",
            members: ["José Álvarez Alonso, MINAM", "Juan Díaz Alvan, UCP"],
          },
          {
            label: "Revisión Mamíferos",
            members: ["Pedro Pérez Peña, IIAP"],
          },
          {
            label: "Revisión de Peces",
            members: [
              "Carmen Rosa García Davila, IIAP",
              "Kevin Morgan Ruíz Tafur, IIAP",
            ],
          },
          {
            label: "Revisión de Insectos",
            members: [
              "José Bardales Vásquez, IIAP",
              "Walter Leonardo Vásquez Mora, UNAP",
            ],
          },
          {
            label: "Revisión de Palmeras",
            members: ["Kember Mejía Caehuanca, IIAP"],
          },
          {
            label: "Revisión de Plantas",
            members: [
              "Náillarett Dávila Cardozo, IIAP",
              "Ricardo Zárate Gómez, IIAP",
              "Elsa Rengifo Salgado, IIAP",
            ],
          },
          {
            label: "Elaboración de Mapas",
            members: ["Juan José Palacios Vega", "Lizardo Fachin Malaverri"],
          },
        ],
      },
      {
        label: "Ilustraciones",
        members: ["Jaime Choclote Martinez", "Javier Vela. (Palmeras)"],
      },
      {
        label: "Sonidos",
        teams: [
          {
            label: "Aves",
            members: [
              "Roger Ahlman",
              "Frank Lambert",
              "Juan Diaz",
              "Thomas S. Schulenberg",
              "Curtis A. Marantz",
              "Peter H. English",
              "Ohn V. Moore",
              "Niels Krabbe",
              "Olaf Jahn",
              "Peter Boesman",
              "Alejandro Luy",
              "Thibaud Aronson",
              "Pedro Allasi",
              "Andrew Spencer",
              "Jaime Suarez",
              "Guilherme Melo",
              "John V Moore",
              "Ted Parker",
              "Jonas Nilsson",
              "Carlos Octavio Gussoni",
              "Edson Endrigo",
              "Scarlet Medina",
              "David F Belmonte",
              "Glen Seeholzer",
              "Daniel Lane",
              "Fernando Angulo",
              "Davis Finch",
              "Raul Pommer",
              "Jerome Fischer",
              "Paul Marvin",
              "Fabio Toledo",
              "Manuel Roncal",
            ],
          },
          {
            label: "Monos",
            members: ["Roberta Aralla"],
          },
          {
            label: "Anfibios",
            members: ["José Manuel Pandial"],
          },
        ],
      },
    ],
  },
  team_development: {
    label: "Equipo de Desarrollo",
    team: [
      {
        name: "Jhon Charlie Martinez Carranza",
        role: "Líder técnico",
        repository: "https://github.com/jmartinez888",
        image: "https://avatars.githubusercontent.com/u/34007650?v=4",
      },
      {
        name: "Edson Isaias Sánchez Chota",
        role: "Desarrollador móvil",
        repository: "https://github.com/edisaiassan",
        image: "https://avatars.githubusercontent.com/u/123921945?v=4",
      },
      {
        name: "Jorge Antonio Del Águila Malafaya",
        role: "Desarrollador móvil",
        repository: "https://github.com/JorgeAntonio",
        image: "https://avatars.githubusercontent.com/u/56671752?v=4",
      },
      {
        name: "Santos Panaifo José Jefferson",
        role: "Desarrollador frontend",
        repository: "https://github.com/daylerjeff199906",
        image: "https://avatars.githubusercontent.com/u/104048237?v=4",
      },
      {
        name: "Josue Franco Soria Ponce",
        role: "Desarrollador frontend",
        repository: "https://github.com/franquito3",
        image: "https://avatars.githubusercontent.com/u/128390649?v=4",
      },
      {
        name: "Danny Dávila Daza",
        role: "Desarrollador frontend",
        repository: "https://github.com/Danny-da",
        image: "https://avatars.githubusercontent.com/u/127968091?v=4",
      },
      {
        name: "Anthony Scott Ramirez Sías",
        role: "Desarrollador backend",
        repository: "https://github.com/Scott-Ramirez",
        image: "https://avatars.githubusercontent.com/u/127967806?v=4",
      },
      {
        name: "Piero Eleví Frías Mori",
        role: "Desarrollador backend",
        repository: "https://github.com/PieroFrias",
        image: "https://avatars.githubusercontent.com/u/67763096?v=4",
      },
      {
        name: "Jordan A. Flores Chavez",
        role: "Desarrollador backend",
        repository: "https://github.com/jordanflower",
        image: "https://avatars.githubusercontent.com/u/128650267?v=4",
      },
    ],
  },
};

export const ModalStaff = ({ open, onClose }) => {
  return (
    <ModalDetails title="Staff" open={open} onClose={onClose}>
      <Box py={2}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 1,
            fontWeight: 700,
            textAlign: "center",
            color: "primary.main",
          }}
        >
          {staff.team_coordination.label}
        </Typography>
        <Box>
          {staff.team_coordination.teams.map((team, i) =>
            team.teams ? (
              <Box
                key={i}
                sx={{
                  mb: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: 700, color: "#F07C54" }}
                >
                  {team.label}
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                      lg: "repeat(3, 1fr)",
                    },
                    gap: 2,
                  }}
                >
                  {team.teams.map((team, i) => (
                    <Box key={i} sx={{ textAlign: "center" }}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 700, color: "GrayText" }}
                      >
                        {team.label}
                      </Typography>
                      <Box>
                        {team.members.map((member, i) => (
                          <Box key={i}>
                            <Typography variant="subtitle1" component="div">
                              {member}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <Box key={i} sx={{ mb: 2, textAlign: "center" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: 700, color: "#F07C54" }}
                >
                  {team.label}
                </Typography>
                <Box>
                  {team.members.map((member, i) => (
                    <Box key={i}>
                      <Typography variant="subtitle1" component="div">
                        {member}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          )}
        </Box>
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 2,
            fontWeight: 700,
            textAlign: "center",
            color: "primary.main",
          }}
        >
          {staff.team_development.label}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            },
            gap: 2,
          }}
        >
          {staff.team_development.team.map((team, i) => (
            <Box key={i} sx={{ mb: 2, textAlign: "center" }}>
              <Avatar src={team.image} sx={{ mx: "auto", mb: 1 }} />
              <Typography variant="subtitle1" component="div">
                {team.name}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                fontWeight={700}
                color="GrayText"
              >
                {team.role}
              </Typography>
              <Link href={team.repository}>Ver repositorio</Link>
            </Box>
          ))}
        </Box>
      </Box>
    </ModalDetails>
  );
};
