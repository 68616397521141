import {
  Box,
  Typography,
  Avatar,
  Backdrop,
  CircularProgress,
  Button,
  Breadcrumbs,
  IconButton,
} from "@mui/material";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import {
  stylesFonts,
  styleSections,
} from "../../../presentation/components/Administrador/styles";
import { useNavigate, Link } from "react-router-dom";

export const LayoutsFrmDetails = ({
  title,
  label,
  image,
  subTitle,
  loading,
  children,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box py={2}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton
            sx={{ borderRadius: "0.5rem" }}
            component={Link}
            to="/admin"
            size="small"
          >
            <DashboardRoundedIcon sx={{ fontSize: 18 }} />
          </IconButton>
          <Button
            onClick={() => handleBack()}
            size="small"
            sx={{
              borderRadius: "0.5rem",
              textTransform: "capitalize",
              color: "GrayText",
              fontWeight: 700,
            }}
          >
            {label ? label : "Regresar"}
          </Button>
        </Breadcrumbs>
      </Box>
      <Box sx={styleSections}>
        <Box display={"flex"} alignItems={"center"} mb={2}>
          <Avatar
            alt="Remy Sharp"
            src={image ? image : "https://source.unsplash.com/random"}
            sx={{ width: 80, height: 80, backgroundColor: "white" }}
          />
          <Box>
            <Typography
              sx={{ ...stylesFonts.titleLargeSection, marginLeft: 2 }}
            >
              {title}
            </Typography>
            <Typography
              sx={{ ...stylesFonts.subTitleLargeSection, marginLeft: 2 }}
            >
              {subTitle
                ? `Última modificación ${new Date(
                    subTitle
                  ).toLocaleDateString()} a las ${new Date(
                    subTitle
                  ).toLocaleTimeString()}
                `
                : "No actualizado"}
            </Typography>
          </Box>
        </Box>
        <Box> {children}</Box>
      </Box>

      <Backdrop
        open={loading}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
