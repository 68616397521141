/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { transformDataAuthors } from "./transformsData";
import { validateYearRange } from "../../infrastructure/ui/validatorInputs";

import { usePagination } from "../../hooks/admin/usePagination";
import { useAuthorsAdmin } from "../../hooks";

const AuthorsContext = createContext();

const AuthorsAdminProvider = ({ children }) => {
  const navigate = useNavigate();
  const { getParams, searchParams } = usePagination();
  const { id } = useParams();
  const location = useLocation();
  const hasAuthors = location.pathname.includes("autores");
  const id_autor = hasAuthors ? id : null;

  const {
    author,
    authors,
    getAuthorsData,
    getDataAuthorById,
    deleteAuthorData,
    editAuthorData,
    loading,
    message,
  } = useAuthorsAdmin();

  const [warningMsg, setWarningMsg] = useState("");
  const [listAuthors, setListAuthors] = useState({
    data: [],
    totalItems: 0,
    totalPages: 0,
  });

  const dataDefault = {
    currentPage: getParams("page", 1),
    sizePage: getParams("size", 15),
    search: searchParams.get("search", ""),
  };

  useEffect(() => {
    if (hasAuthors) {
      getAuthorsData(
        dataDefault?.search,
        dataDefault?.currentPage,
        dataDefault?.sizePage
      );
    }
  }, [searchParams]);

  useEffect(() => {
    setListAuthors({
      ...listAuthors,
      data: transformDataAuthors(
        dataDefault?.search ? authors?.authors : authors?.autores
      ),
      totalPages: authors?.totalPages,
      totalItems: authors?.totalItems,
    });
  }, [authors]);

  useEffect(() => {
    if (id_autor) {
      getDataAuthorById(id_autor);
    }
  }, [id_autor]);

  const loadingAuthors = () => {
    getAuthorsData(
      dataDefault?.search,
      dataDefault?.currentPage,
      dataDefault?.sizePage
    );
  };

  // const getDetailAuthor = () => {
  //   getDataAuthorById(id_autor);
  // };

  const deleteAuthor = async (id, body) => {
    await deleteAuthorData(id, body);
    loadingAuthors();
  };

  const editOrSaveAuthor = async (body, id) => {
    const { vc_ano_nacimiento, vc_ano_deceso } = body;
    const isValid = validateYearRange(vc_ano_nacimiento, vc_ano_deceso).valid;
    if (!isValid) {
      setWarningMsg("El año de nacimiento no puede ser mayor al año de deceso");
      return;
    }
    if (id) {
      await editAuthorData(body, id);
      await loadingAuthors();
      navigate("/admin/autores");
    } else {
      await editAuthorData(body);
      await loadingAuthors();
      navigate("/admin/autores");
    }
  };

  // const updateOrDeleteImg = async (id, file) => {
  //   if (file) {
  //     await addUpdateAuthorImg(id, {
  //       vc_imagen: file,
  //     });
  //   } else {
  //     await deleteAuthorImg(id);
  //   }
  //   getDetailAuthor();
  // };

  return (
    <AuthorsContext.Provider
      value={{
        id_autor,
        dataDefault,
        loadingAuthors,
        listAuthors,
        author,
        //CRUD
        deleteAuthor,
        editOrSaveAuthor,
        // updateOrDeleteImg,
        //REPONSE
        loading,
        warningMsg,
        message,
      }}
    >
      {children}
    </AuthorsContext.Provider>
  );
};

export { AuthorsAdminProvider };
export const useLogicAuthorsAdmin = () => useContext(AuthorsContext);
