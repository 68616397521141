import {
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";

import { Link } from "react-router-dom";
import LoginIcon from "./LoginIcon";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BiotechIcon from "@mui/icons-material/Biotech";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { IconsTaxonomia } from "../../../infrastructure/data/ArrayTaxas";

import { useLogicCategories } from "../../../context/client/categoriesProvider";
import { routesApp } from "../../../utils/constants";

const menuItems = [
  { to: "/", text: "Inicio", icon: <HomeRoundedIcon htmlColor="#ffffff" /> },
  {
    to: routesApp.species,
    text: "Consulta especies",
    icon: <BugReportRoundedIcon htmlColor="#ffffff" />,
  },
  {
    to: routesApp.communities,
    text: "Consulta comunidades",
    icon: <Diversity2Icon fontSize="small" htmlColor="#ffffff" />,
  },
  {
    to: routesApp.authors,
    text: "Autores",
    icon: <TravelExploreIcon fontSize="small" htmlColor="#ffffff" />,
  },
];

const MenuItemLink = ({ to, text, icon, onClose }) => (
  <Link to={to} style={{ textDecoration: "none" }}>
    <MenuItem onClick={onClose}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText sx={{ color: "white" }}>{text}</ListItemText>
    </MenuItem>
  </Link>
);

const AppBarHeaderPhone = ({ onClose }) => {
  // const isHome = useLocation().pathname === "/";
  const { handleCategorySelection } = useLogicCategories();

  const handleActionClick = (id) => {
    handleCategorySelection(id);
  };

  const OnClose = () => {
    onClose();
  };

  return (
    <>
      <Paper
        sx={{
          width: 300,
          maxWidth: "100%",
          height: "100%",
          // backgroundColor: "#076633",
          backgroundColor: "primary.main",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MenuList>
          <Box display={"flex"} padding={2}>
            <img src="/favicon/favicon.ico" alt="logo" height={50} />
            <Typography
              marginLeft={1}
              fontWeight={700}
              color={"white"}
              fontSize={"18px"}
            >
              AMAZONIA: Guía ilustrada de flora y fauna
            </Typography>
            <Button
              startIcon={<LoginIcon />}
              style={{
                color: "white",
                justifyContent: "flex-end",
              }}
            ></Button>
          </Box>
        </MenuList>
        <Divider />
        <MenuList>
          {menuItems.map((item, index) => (
            <MenuItemLink key={index} {...item} onClose={OnClose} />
          ))}
          <Accordion
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="#ffffff" />}
            >
              <BiotechIcon fontSize="small" htmlColor="#ffffff" />
              <ListItemText sx={{ color: "white", marginLeft: 2 }}>
                Categorias especies
              </ListItemText>
            </AccordionSummary>
            <AccordionDetails>
              {IconsTaxonomia.map((icon, index) => (
                <MenuItemLink
                  key={icon.taxaValue}
                  // to={`/species?categoria=${taxa.id_taxonomia}`}
                  to={`/species?categoria=${index + 1}`}
                  text={icon.taxaNombre}
                  icon={
                    <img
                      src={icon.taxaIcono}
                      alt={icon.taxaNombre}
                      width="24"
                      height="24"
                    />
                  }
                  onClose={() => {
                    handleActionClick(index);
                    OnClose();
                  }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </MenuList>
        <Divider />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop="auto"
          bottom={0}
          bgcolor="transparent"
        >
          <img
            src="/images/svg_app_bar.avif"
            alt="logo"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Paper>
    </>
  );
};
export default AppBarHeaderPhone;
