import React from "react";
import { Box, Typography } from "@mui/material";
import { stylesFonts } from "../../styles";
import { LayoutsFrmDetails } from "../../../../../components/Admin/layouts/layoutsFrmDetails";
import { useLogicConservationAdmin } from "../../../../../context/admin/conservationProvider";

const FomrDetailsConservation = () => {
  const { conservation, loading } = useLogicConservationAdmin();

  const details = [
    {
      title: "Iniciales",
      value: conservation.vc_iniciales
        ? conservation.vc_iniciales
        : "No registrado",
    },
    {
      title: "Institución",
      value: conservation.vc_institucion
        ? conservation.vc_institucion
        : "No registrado",
    },
  ];
  return (
    <>
      <LayoutsFrmDetails
        title={conservation?.vc_nombre}
        image={conservation?.vc_imagen_estado}
        loading={loading}
        label={"Est. de conservación"}
      >
        <Typography sx={stylesFonts.titleSection}>
          Información general
        </Typography>
        <Box>
          {details?.map((detail, index) => (
            <Box
              key={index}
              sx={{
                display: { xs: "block", sm: "flex" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: { xs: "100%", sm: "20%" },
                }}
              >
                <Typography sx={stylesFonts.subtitleSection}>
                  {detail.title} :
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography sx={stylesFonts.textSection}>
                  {detail.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box mt={2}>
          <Typography sx={stylesFonts.subtitleSection}>Descripción</Typography>
          <Typography sx={{ ...stylesFonts.textSection, textAlign: "justify" }}>
            {conservation?.te_descripcion}
          </Typography>
        </Box>
      </LayoutsFrmDetails>
    </>
  );
};

export default FomrDetailsConservation;
