/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";

import { useSpecieAuthor } from "../../hooks/admin/useSpecieAuthor";
import { useLogicSpeciesAdmin } from "./speciesProvider";

const AuthorSpecieContext = createContext();
const token = localStorage.getItem("token_raices");

const transformData = (data) => {
  if (data) {
    return data.map((item) => {
      return {
        id: item.id_autor,
        name: item.vc_nombre,
        key: item.id_autor,
        status: parseInt(item.estado_autor),
      };
    });
  } else {
    return [];
  }
};

const SpecieAuthorProviderAdmin = ({ children }) => {
  const { getDataSpecie } = useLogicSpeciesAdmin();
  const {
    getAuthorsNotInSpecieData,
    dataListAuhtors,
    addOrDeleteAuthorToSpecie,
    //response
    loading,
    message,
  } = useSpecieAuthor();

  const [listAuthors, setListAuthors] = useState({
    data: [],
    totalItems: 0,
    totalPages: 0,
  });

  const [dataDefault, setDataDefault] = useState({
    currentPage: 1,
    sizePage: 15,
    search: "",
  });

  const getListDataAuthors = (id) => {
    getAuthorsNotInSpecieData(token, id, {
      page: dataDefault.currentPage,
      pageSize: dataDefault.sizePage,
      search: dataDefault.search,
    });
  };

  useEffect(() => {
    if (dataListAuhtors) {
      setListAuthors({
        data: transformData(dataListAuhtors.authorsData),
        totalItems: dataListAuhtors.totalItems,
        totalPages: dataListAuhtors.totalPages,
      });
    }
  }, [dataListAuhtors]);

  const addOrDeleteAuthor = async (isDelete, body) => {
    const { id_especie } = body;
    await addOrDeleteAuthorToSpecie(token, isDelete, body);
    await getAuthorsNotInSpecieData(token, id_especie, {
      page: 1,
      pageSize: 15,
      search: "",
    });
    getDataSpecie(id_especie);
  };

  return (
    <AuthorSpecieContext.Provider
      value={{
        //Data
        dataDefault,
        listAuthors,
        getListDataAuthors,
        //For crud
        setDataDefault,
        addOrDeleteAuthor,
        //Response
        message,
        loading,
      }}
    >
      {children}
    </AuthorSpecieContext.Provider>
  );
};

export { SpecieAuthorProviderAdmin };
export const useAuthorSpecie = () => useContext(AuthorSpecieContext);
