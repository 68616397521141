/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Stack,
  Button,
  Chip,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { SubTitleSection } from "../../styles";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

// import ModalInfoStatus from "../modalStatusInfo";
import { useLogicSpecies } from "../../../../../context/client/speciesProvider";
import { stylesComponents } from "../../../../styles/stylesComponents";

import ModalConservation from "../../modalConservation";
import { FiltersMultimedia } from "./filtersMultimedia";
import { FiltersConservation } from "./filtersConservation";
import { FiltersTaxonomy } from "./filtersTaxonomy";
import { FiltersCategory } from "./filtersCategory";

const ConsultSection = () => {
  const { filtersApplied, clearAllFilter, updateUrlParam } = useLogicSpecies();

  const [openModal, setOpenModal] = useState(false);

  const handleChipDelete = (key) => {
    updateUrlParam(key, null);
  };

  const openModalConservation = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Box paddingY={2}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Stack
              direction={"row"}
              spacing={"auto"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={SubTitleSection}>Filtrar Aplicados</Typography>
              <Button
                variant="outlined"
                size="small"
                color="error"
                endIcon={<ClearOutlinedIcon />}
                onClick={() => {
                  clearAllFilter();
                }}
                sx={{
                  borderRadius: "0.5rem",
                  border: "2px solid",
                  "&:hover": {
                    border: "2px solid",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  Quitar
                </Typography>
              </Button>
            </Stack>
            <Box>
              {Object.entries(filtersApplied).map(([key, value]) =>
                value !== "" ? (
                  <Chip
                    key={key}
                    label={key}
                    sx={stylesComponents.chip}
                    size="small"
                    onDelete={() => {
                      handleChipDelete(key);
                    }}
                  />
                ) : null
              )}
            </Box>

            <Typography sx={SubTitleSection}>Categoría</Typography>
            <FiltersCategory />
            <Typography sx={SubTitleSection}>Taxonomía</Typography>
            <FiltersTaxonomy />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={SubTitleSection}>
                Estados de conservación
              </Typography>
              <IconButton onClick={() => openModalConservation()}>
                <HelpOutlineIcon />
              </IconButton>
            </Box>
            <FiltersConservation />
            <Typography sx={SubTitleSection}>Multimedia</Typography>
            <Box>
              <FiltersMultimedia />
            </Box>
          </Stack>
        </Stack>
      </Box>
      <ModalConservation open={openModal} onClose={openModalConservation} />
    </>
  );
};

export default ConsultSection;
