import { useState } from "react";
import {
  getListClass,
  getListOrder,
  getListFamily,
  getListFamilyByIdOrder,
  getListOrderByIdClass,
} from "../../infrastructure/api/client/taxonomies";

const transformData = (data, nameKey, nameFkey) => {
  return data?.map((item) => {
    return {
      id: item[`id_${nameKey}`],
      fk_id: item[`id_${nameFkey}`],
      label: item.vc_nombre,
      value: item[`id_${nameKey}`],
    };
  });
};

export const useTaxonomies = () => {
  const [taxonomies, setTaxonomies] = useState({
    listClass: [],
    listOrder: [],
    listFamily: [],
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getListDataClass = async () => {
    try {
      const data = await getListClass().then((res) => res);
      setTaxonomies((prevState) => ({
        ...prevState,
        listClass: transformData(data, "clase", "clase"),
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const getListDataOrder = async () => {
    try {
      const data = await getListOrder().then((res) => res);
      setTaxonomies((prevState) => ({
        ...prevState,
        listOrder: transformData(data, "orden", "clase"),
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const getListDataFamily = async () => {
    try {
      const data = await getListFamily().then((res) => res);
      setTaxonomies((prevState) => ({
        ...prevState,
        listFamily: transformData(data, "familia", "orden"),
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const getListDataOrderByIdClass = async (idClass) => {
    try {
      const data = await getListOrderByIdClass(idClass).then((res) => res);
      setTaxonomies((prevState) => ({
        ...prevState,
        listOrder: transformData(data, "orden", "clase"),
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const getListDataFamilyByIdOrder = async (idOrder) => {
    try {
      const data = await getListFamilyByIdOrder(idOrder).then((res) => res);
      setTaxonomies((prevState) => ({
        ...prevState,
        listFamily: transformData(data, "familia", "orden"),
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  return {
    getListDataClass,
    getListDataOrder,
    getListDataFamily,
    getListDataOrderByIdClass,
    getListDataFamilyByIdOrder,
    taxonomies,
    error,
    loading,
  };
};
