import { useState, useEffect } from "react";

import {
  getConservationsNotInSpecie,
  addConservationToSpecie,
  deleteConservationFromSpecie,
} from "../../api";

export const useSpecieConservation = () => {
  const [dataListConservations, setDataListConservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const handleResponse = (response, successMessage, errorMessage) => {
    setLoading(false);
    if (response && response.status === 200) {
      setMessage({
        ...message,
        status: response.status,
        message: successMessage,
      });
    } else {
      setMessage({
        ...message,
        status: response.status,
        message: errorMessage,
      });
    }
  };

  const getConservationsNotInSpecieData = async (token, id_especie, body) => {
    setLoading(true);
    const response = await getConservationsNotInSpecie(token, id_especie, body);
    if (response && response.status === 200) {
      setDataListConservations(response.data);
    } else {
      setDataListConservations([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const addOrDeleteConservationToSpecie = async (token, isDelete, body) => {
    setLoading(true);
    const response = isDelete
      ? await deleteConservationFromSpecie(token, body)
      : await addConservationToSpecie(token, body);

    const res = isDelete ? "eliminar" : "añadir";

    handleResponse(
      response,
      `Se ha ${res} la conservación correctamente`,
      `No se ha podido ${res} la conservación`
    );
  };

  return {
    dataListConservations,
    loading,
    getConservationsNotInSpecieData,
    addOrDeleteConservationToSpecie,
    message,
  };
};
