import ListTowns from "./ListTowns/ListTowns";
import { LayoutFrmList } from "../../../../components";

const IndexTows = () => {
  return (
    <LayoutFrmList title={"Pueblos indígenas"} to={"/admin/towns/add"}>
      <ListTowns />
    </LayoutFrmList>
  );
};

export default IndexTows;
