export const optionsItemsPerPage = [
  { name: "15 / pág.", value: 15 },
  { name: "20 / pág.", value: 20 },
  { name: "30 / pág.", value: 30 },
  { name: "100 / pág.", value: 100 },
];

// export const typesOrdering = [
//   { name: "Recientes", value: "" },
//   { name: "A-Z", value: "ASC" },
//   { name: "Z-A", value: "DESC" },
// ];

export const orderBy = [
  { id: 0, name: "Recientes", value: "" },
  {
    id: 1,
    name: "n. común",
    value: "vc_nombre",
  },
  {
    id: 2,
    name: "n. científico",
    value: "vc_nombre_cientifico",
  },
];

export const orderType = [
  { id: 0, name: "A-Z", value: "ASC" },
  { id: 1, name: "Z-A", value: "DESC" },
];

export const routesApp = {
  home: "/",
  dashboard: "/dashboard",
  species: "/species",
  authors: "/authors",
  communities: "/community",
};
