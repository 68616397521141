import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import {
  WhatsappShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

export const IconShare = () => {
  const [open, setOpen] = React.useState(true);
  const location = window.location.href;
  const sizeIcon = 40;
  const borderRadius = 16;
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "5rem",
        right: { xs: 20, sm: 20, md: "1rem" },
        zIndex: 900,
      }}
    >
      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            right: "1rem",
          }}
        >
          <WhatsappShareButton
            url={location}
            title="Comparte esta pagina con tus amigos"
          >
            <WhatsappIcon size={sizeIcon} borderRadius={borderRadius} />
          </WhatsappShareButton>
          <FacebookShareButton
            url={location}
            quote="Comparte esta pagina con tus amigos"
          >
            <FacebookIcon size={sizeIcon} borderRadius={borderRadius} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={location}
            title="Comparte esta pagina con tus amigos"
            summary="Comparte esta pagina con tus amigos"
            source="Comparte esta pagina con tus amigos"
          >
            <LinkedinIcon size={sizeIcon} borderRadius={borderRadius} />
          </LinkedinShareButton>
          <TwitterShareButton
            url={location}
            title="Comparte esta pagina con tus amigos"
          >
            <TwitterIcon size={sizeIcon} borderRadius={borderRadius} />
          </TwitterShareButton>
          <EmailShareButton
            url={location}
            subject="Comparte esta pagina con tus amigos"
            body="Comparte esta pagina con tus amigos"
          >
            <EmailIcon size={sizeIcon} borderRadius={borderRadius} />
          </EmailShareButton>
        </Box>
      )}
      <IconButton
        onClick={() => setOpen(!open)}
        sx={{
          borderRadius: "1rem",
          backgroundColor: "#fff",
          border: `1px solid #E0E0E0`,
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        }}
      >
        {!open ? <AddRoundedIcon /> : <CloseRoundedIcon />}
      </IconButton>
    </Box>
  );
};
