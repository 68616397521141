/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import {
  // Alert,
  Box,
  Grid,
  Typography,
  TextField,
  // Snackbar,
} from "@mui/material";

import { TaxaSpecieProvider } from "../../../../../context/TaxaSpecieProvider";
import { stylesFonts } from "../../styles";

import SectionDetails from "./sections/SectionDetails";
import SectionTaxonomia from "./sections/SectionTaxonomia";
import SectionAuthors from "./sections/SectionAuthors";
import SectionStatusConservation from "./sections/SectionConservation";
import SectionMultimedia from "./sections/SectionMultimedia";

import { styleSections } from "../../styles";
import ButtonCustom from "../../../../../components/Admin/ButtonCustom";

//news provider
import { useLogicSpeciesAdmin } from "../../../../../context/admin/speciesProvider";
import { LayoutFrmAdd } from "../../../../../components/Admin/layouts/layoutFrmAdd";
import { SpecieAuthorProviderAdmin } from "../../../../../context/admin/specieAuthorProvider";
import { SpecieConservationProviderAdmin } from "../../../../../context/admin/specieConservationProvider";

const FormAddSpecies = () => {
  const { loading, id_especie, specie, saveOrEditDataSpecie } =
    useLogicSpeciesAdmin();

  const [formData, setFormData] = React.useState({
    vc_nombre: "",
    vc_nombre_ingles: "",
    vc_nombre_cientifico: "",
    te_descripcion: "",
    vc_ano: "",
    id_taxonomia: "",
    id_familia: "",
  });

  const [otherTaxas, setOtherTaxas] = React.useState({
    id_orden: "",
    id_clase: "",
  });

  useEffect(() => {
    if (id_especie) {
      setFormData({
        vc_nombre: specie?.vc_nombre || "",
        vc_nombre_ingles: specie?.vc_nombre_ingles || "",
        vc_nombre_cientifico: specie?.vc_nombre_cientifico || "",
        te_descripcion: specie?.te_descripcion || "",
        vc_ano: specie?.vc_ano || "",
        id_taxonomia: specie?.taxa?.id_taxa || "",
        id_familia: specie?.taxonomia?.familia?.id_familia || "",
      });
      setOtherTaxas({
        id_orden: specie?.taxonomia?.orden?.id_orden || "",
        id_clase: specie?.taxonomia?.clase?.id_clase || "",
      });
    }
  }, [specie, id_especie]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelect = (name, value, isOther) => {
    if (isOther) {
      setOtherTaxas({
        ...otherTaxas,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSaveData = () => {
    if (id_especie) {
      saveOrEditDataSpecie(formData, id_especie);
    } else {
      saveOrEditDataSpecie(formData, null);
    }
  };

  return (
    <>
      <SpecieConservationProviderAdmin>
        <SpecieAuthorProviderAdmin>
          <LayoutFrmAdd
            title={id_especie ? "Editar especie" : "Agregar especie"}
            loading={loading}
            label={"Especies"}
          >
            <TaxaSpecieProvider>
              <Box sx={styleSections}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SectionDetails
                      data={formData}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SectionTaxonomia
                      data={formData}
                      otherData={otherTaxas}
                      handleChange={handleSelect}
                    />
                  </Grid>
                  <Grid item xs={12} pb={2}>
                    <Typography sx={stylesFonts.subtitleSection} pb={2}>
                      Descripción
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder={"Escribe la descripción..."}
                      value={formData.te_descripcion}
                      multiline
                      rows={6}
                      name="te_descripcion"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ gap: 2 }}
                >
                  <ButtonCustom
                    label={id_especie ? "Editar" : "Guardar"}
                    color={"primary"}
                    onClick={handleSaveData}
                    disabled={loading}
                  />

                  <ButtonCustom
                    label={"Cancelar"}
                    color={"error"}
                    to={"/admin/especies"}
                  />
                </Box>
              </Box>
              {id_especie ? (
                <Box sx={styleSections}>
                  <SectionAuthors specie={specie} />
                </Box>
              ) : null}
              {id_especie ? (
                <>
                  <Box sx={styleSections}>
                    <SectionStatusConservation specie={specie} />
                  </Box>
                  <Box sx={styleSections}>
                    <SectionMultimedia specie={specie} />
                  </Box>
                </>
              ) : null}
            </TaxaSpecieProvider>
          </LayoutFrmAdd>
        </SpecieAuthorProviderAdmin>
      </SpecieConservationProviderAdmin>
    </>
  );
};

export default FormAddSpecies;
