import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider, Container, Box } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import Chart from "../Chart";
import CardCategories from "../CardCategories";
import { getListCategories } from "../../../../infrastructure/api/client/categories";

import { stylesSections, styleDivider, stylesFont } from "../../../../styles";
import { IndicatorSection } from "./IndicatorSection";

const getSpeciesColor = (speciesName) => {
  const colorMap = {
    Aves: "#F36A2A",
    Mamiferos: "#886D5B",
    Anfibios: "#3B8476",
    Reptiles: "#77A69D",
    Peces: "#0E69A8",
    Insectos: "#E3B409",
    Arboles: "#4CB848",
    Palmeras: "#8C62AA",
  };

  return colorMap[speciesName] || "#000000";
};

export const ChartsSection = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getListCategories().then((res) => {
      setApiData(res.data);
      setLoading(false);
    });
  }, []);

  const categories = loading
    ? []
    : apiData?.map((data) => ({
        id: data?.id_taxonomia,
        name: data?.vc_nombre,
        quantity: data?.in_total_especies,
        color: getSpeciesColor(data.vc_nombre),
        taxaIcono: data?.vc_imagen,
      }));
  return (
    <Box
      sx={{
        ...stylesSections.sectionGeneral,
        backgroundColor: "rgb(243, 244, 249)",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={stylesSections.sectionTitleSubTitle}>
          <Typography sx={stylesFont.subTitleSection}>
            NUESTROS NÚMEROS
          </Typography>
          <Typography sx={stylesFont.titleSection}>
            Distribución de especies por grupos categorías
          </Typography>
          <Divider sx={styleDivider.dividerHome} />
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <IndicatorSection />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Fade style={{ width: "100%", height: "100%" }}>
              <Chart />
            </Fade>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container columnSpacing={2}>
              {categories?.map((category) => (
                <Grid key={category.id} item xs={6}>
                  <Fade cascade damping={0.1}>
                    <CardCategories
                      number={category.quantity}
                      categorie={category.name}
                      color={category.color}
                      iconCategory={category.taxaIcono}
                    />
                  </Fade>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
