import { useState, useEffect } from "react";
import { Typography, Box, Alert, CircularProgress } from "@mui/material";
import { titleSection } from "../../styles";

import { UpLoadImage } from "../../../../../components";
import { useLogicAuthorsAdmin } from "../../../../../context/admin/authorsProvider";
import { useAuthorsAdmin } from "../../../../../hooks";

const SectionMultimedia = ({ data }) => {
  const [authorData, setAuthorData] = useState({});
  const { id_autor } = useLogicAuthorsAdmin();
  const {
    author,
    loading,
    message,
    addUpdateAuthorImg,
    deleteAuthorImg,
    getDataAuthorById,
  } = useAuthorsAdmin();

  useEffect(() => {
    if (data) {
      setAuthorData(data);
    }
  }, [data]);

  useEffect(() => {
    if (author) {
      setAuthorData(author);
    }
  }, [author]);

  const updateImg = async (file) => {
    if (file) {
      await addUpdateAuthorImg(id_autor, file);
      getDataAuthorById(id_autor);
    } else {
      await deleteAuthorImg(id_autor);
      getDataAuthorById(id_autor);
    }
  };

  const severity = message?.status === 200 ? "success" : "error";

  return (
    <>
      <Typography sx={titleSection}>Multimedia</Typography>
      {message?.message !== "" && message?.message !== null && (
        <Box py={1}>
          <Alert severity={severity}>
            {/* <Typography sx={stylesFonts.messageAlert}> */}
            {message.message}
            {/* </Typography> */}
          </Alert>
        </Box>
      )}
      <Box py={2}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <UpLoadImage
            data={{
              id: authorData?.id_autor,
              image: authorData?.vc_imagen,
            }}
            handleDelete={() => updateImg()}
            handleUpLoad={(file) => updateImg(file)}
          />
        )}
      </Box>
    </>
  );
};

export default SectionMultimedia;
