export const IconsTaxonomia = [
  {
    taxaNombre: "Aves",
    taxaValue: 1,
    taxaIcono: "/images/taxas/aves.png",
  },
  {
    taxaNombre: "Mamiferos",
    taxaValue: 2,
    taxaIcono: "/images/taxas/mamiferos.png",
  },
  {
    taxaNombre: "Reptiles",
    taxaValue: 3,
    taxaIcono: "/images/taxas/reptiles.png",
  },
  {
    taxaNombre: "Anfibios",
    taxaValue: 4,
    taxaIcono: "/images/taxas/anfibios.png",
  },
  {
    taxaNombre: "Peces",
    taxaValue: 5,
    taxaIcono: "/images/taxas/peces.png",
  },
  {
    taxaNombre: "Insectos",
    taxaValue: 6,
    taxaIcono: "/images/taxas/insectos.png",
  },
  {
    taxaNombre: "Arboles",
    taxaValue: 7,
    taxaIcono: "/images/taxas/arboles.png",
  },
  {
    taxaNombre: "Palmeras",
    taxaValue: 8,
    taxaIcono: "/images/taxas/palmeras.png",
  },
];
