import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ButtonCuston = ({ text, link, color, ...props }) => {
  return (
    <Link to={link}>
      <Button
        {...props}
        disableElevation
        variant="contained"
        sx={{
          backgroundColor: color,
          borderRadius: "8px",
          my: 2,
          px: 2,
          py: 1,
          textTransform: "capitalize",
        }}
      >
        {text}
      </Button>
    </Link>
  );
};
