import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const listDataFilo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/phylum`, {});
    const data = await response;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const listDataClase = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/classes`, {});
    const data = await response;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const listDataOrden = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders`, {});
    const data = await response;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const listDataFamilia = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/families`, {});
    const data = await response;
    return data;
  } catch (error) {
    console.log(error);
  }
};

//No eh usado ninguno en el provider Taxaspecie
export const dataReinoById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/kingdoms/${id}`, {});
    const data = await response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const dataFiloById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/phylum/${id}`, {});
    const data = await response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const dataClaseById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/classes/${id}`, {});
    const data = await response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const dataOrdenById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/${id}`, {});
    const data = await response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};
