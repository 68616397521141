/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { CardDetails } from "../../../../../components";
// import { useNavigate } from "react-router-dom";

import { SectionSearch } from "../../../../../components";

import { useLogicAuthors } from "../../../../../context/client/authorsProvider";
import { ModalDetails } from "../../../../../components/modalDetails";
import { DetailsAuthor } from "../../[id]/detailsAuthor";

const AuthorsPageIndex = () => {
  // const navigate = useNavigate();
  const { bodyData, listAuthors, listDataAuthor, updateUrlParam, loading } =
    useLogicAuthors();
  const [open, setOpen] = useState(false);
  const [id_author, setIdAuthor] = useState(null);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <SectionSearch
        loading={loading}
        defaultValues={{
          search: bodyData?.search,
          currentPage: bodyData?.page,
          pageSize: bodyData?.sizeItems,
        }}
        totalPages={listAuthors?.totalPages}
        onChangePage={(e, newPage) => {
          updateUrlParam("page", newPage);
        }}
        onChangeItems={(value) => {
          updateUrlParam("sizeItems", value);
        }}
        onChangeSearch={(value) => updateUrlParam("search", value)}
      >
        <Grid container spacing={2}>
          {listDataAuthor?.length > 0 ? (
            listDataAuthor?.map((author, index) => (
              <Grid item xs={12} sm={4} md={3} key={index}>
                <CardDetails
                  data={{
                    id: author?.id_autor,
                    title: author?.vc_nombre + " " + author?.vc_apellido,
                    subtitle:
                      author?.vc_ano_nacimiento + " - " + author?.vc_ano_deceso,
                    caption: author?.vc_profesion,
                    image: author?.vc_imagen,
                    tag: "especies",
                  }}
                  isDark={true}
                  onClick={(value) => {
                    setIdAuthor(value);
                    handleOpen();
                  }}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="subtitle1">No hay resultados</Typography>
            </Grid>
          )}
        </Grid>
      </SectionSearch>
      <ModalDetails open={open} onClose={handleOpen} title={"Detalle de autor"}>
        <DetailsAuthor id_value={id_author} />
      </ModalDetails>
    </>
  );
};

export default AuthorsPageIndex;
