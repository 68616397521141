import { createTheme } from "@mui/material";

export const primaryColor = "#015E80";
export const secondaryColor = "#83AB3A";
// export const secondaryColor = "#8DB63E73";
export const backgroundColor = "#FFFFFF";
export const bgItemSelectMenu = "#3D5656";
// ... otras variables de colores
export const speciesArray = [
  { id: 1, name: "Aves", primaryColor: "#F36A2A", secondaryColor: "#54C5CF" },
  {
    id: 2,
    name: "Mamiferos",
    primaryColor: "#A6CE39",
    secondaryColor: "#886D5B",
  },
  {
    id: 3,
    name: "Reptiles",
    primaryColor: "#C656A0",
    // primaryColor: "#C656A0",
    secondaryColor: "#C656A0",
  },
  {
    id: 4,
    name: "Anfibios",
    primaryColor: "#3B8476",
    secondaryColor: "#C656A0",
  },
  { id: 5, name: "Peces", primaryColor: "#0E69A8", secondaryColor: "#FAAF18" },
  {
    id: 6,
    name: "Insectos",
    primaryColor: "#B08205",
    secondaryColor: "#FAD97E",
  },
  {
    id: 7,
    name: "Arboles",
    primaryColor: "#4CB848",
    secondaryColor: "#A2238E",
  },
  {
    id: 8,
    name: "Palmeras",
    primaryColor: "#5E3F74",
    secondaryColor: "#F89D33",
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#015E80", // Color primario
    },
    secondary: {
      main: "#8DB63E73", // Color secundario
    },
    dark: {
      main: "#000000",
    },
    selected: {
      main: "#3D5656",
    },
    // divider: {
    //   main: "#8B0000",
    // },
    // Otros colores
  },
  breakpoints: {
    values: {
      xs: 0,
      xls: 420,
      sm: 600,
      xsm: 720,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2560,
    },
  },
  // Otros ajustes del tema
});

export default theme;
