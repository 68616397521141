import { Box } from "@mui/material";
import { SearchInputSection } from "../../SearchInputSection";
import { TableList } from "../ListTable/TableList";

export const TableListSection = ({
  isSimple,
  loading,
  data,
  dataDefault,
  ...props
}) => {
  return (
    <>
      <Box
        sx={{
          paddingBottom: 2,
        }}
      >
        <SearchInputSection
          defaultValue={dataDefault && dataDefault.search}
          onResult={props.onChangeSearch}
        />
      </Box>
      <Box>
        <TableList
          loading={loading}
          data={data ? data : []}
          isSimple={isSimple ? isSimple : false}
          isPagination={props.isPagination}
          dataDefault={dataDefault && dataDefault}
          handlePage={props.handlePage}
          handleRows={props.handleSize}
          confirmDelete={props.confirmDelete}
          onChangeView={props.onChangeView}
          onChangeEdit={props.onChangeEdit}
        />
      </Box>
    </>
  );
};
