/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  Alert,
} from "@mui/material";
import { paragraphSection, stylesFonts } from "../../../styles";
import { TableListSimpleAction } from "../../tableSimpleAction";
import { SectionListControls } from "../../ListControls";
import { useLogicConservationSpecie } from "../../../../../../context/admin/specieConservationProvider";
// import TableList from "../../TableList";

const transformDataForList = (data) => {
  if (data) {
    return data.map((item) => {
      return {
        id: item.id_estado,
        status: parseInt(item.estado),
        image: item.imagen_estado,
        primary: item.vc_nombre + " (" + item.iniciales_estado + ")",
        secondary: item.institucion,
      };
    });
  } else {
    return [];
  }
};

const SectionStatusConservation = ({ specie }) => {
  const [checked, setChecked] = useState(false);

  const {
    dataDefault,
    listConservation,
    setDataDefault,
    getListDataConservation,
    addOrDeleteConservation,
    message,
  } = useLogicConservationSpecie();

  useEffect(() => {
    if (specie?.id_especie) {
      getListDataConservation(specie?.id_especie);
    }
  }, [specie, dataDefault]);

  const handleChange = () => {
    setChecked((prev) => !checked);
  };

  const handleDataDefault = (name, value) => {
    setDataDefault({
      ...dataDefault,
      [name]: value,
    });
  };

  const handleAction = (value, isDelete) => {
    addOrDeleteConservation(isDelete, {
      id_especie: specie?.id_especie,
      id_est_conservacion: value,
    });
  };

  const severity = message?.status === 200 ? "success" : "error";

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography sx={stylesFonts.titleSection}>
            Estados de conservación
          </Typography>
          <Typography sx={stylesFonts.textSection}>
            ¿En qué estado de conservación se encuentra la especie?
          </Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange} />}
            label="Habilitar edición "
          />
        </Box>
      </Box>

      {message?.message !== "" && message?.message !== null && (
        <Box py={1}>
          <Alert severity={severity}>
            <Typography sx={stylesFonts.messageAlert}>
              {message.message}
            </Typography>
          </Alert>
        </Box>
      )}
      <Grid container spacing={3}>
        {checked ? (
          <Grid item xs={12} md={6}>
            <Typography sx={paragraphSection}>
              Selecciona los Estados disponibles para asignar
            </Typography>
            <TableListSimpleAction
              data={listConservation}
              dataDefault={dataDefault}
              isPagination={true}
              handlePage={(value) => handleDataDefault("currentPage", value)}
              handleRows={(value) => handleDataDefault("sizePage", value)}
              handleSearch={(value) => handleDataDefault("search", value)}
              handleActions={(value) => handleAction(value, false)}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={checked ? 6 : 12}>
          <SectionListControls
            data={transformDataForList(specie?.estados_conservacion)}
            isDelete={checked}
            handleDelete={(value) => handleAction(value, true)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionStatusConservation;
