import { createContext, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { useLogicSpecies } from "./speciesProvider";
const MultimediaContext = createContext();

const MultimediaProvider = ({ children }) => {
  const { updateUrlParam } = useLogicSpecies();
  const [searchParams] = useSearchParams();
  const selectedMultimedia =
    searchParams.get("multimedia") === null
      ? 2
      : parseInt(searchParams.get("multimedia"));

  const setSelectedMultimedia = (e, value) => {
    const { name } = e.currentTarget;
    if (value !== 2) {
      updateUrlParam(name, value);
    } else {
      updateUrlParam(name, "");
    }
  };

  return (
    <MultimediaContext.Provider
      value={{
        selectedMultimedia,
        setSelectedMultimedia,
      }}
    >
      {children}
    </MultimediaContext.Provider>
  );
};

export { MultimediaProvider };
export const useLogicMultimedia = () => useContext(MultimediaContext);
