import { Avatar, Box, Typography } from "@mui/material";
import { stylesFonts } from "../../styles";

export const MoreInformation = ({ data }) => {
  return (
    <Box mt={2}>
      <Typography sx={stylesFonts.titleSection}>Sobre la especie</Typography>
      <Box
        sx={{
          display: { xs: "block", sm: "flex", gap: 2, columnGap: 2 },
        }}
      >
        <Box width={"100%"} maxWidth={400}>
          <Typography sx={stylesFonts.subtitleSection}>
            Estados de conservación
          </Typography>
          {data?.estados_conservacion?.length > 0 ? (
            data?.estados_conservacion?.map((detail, index) => (
              <Box
                key={index}
                display={"flex"}
                width={"100%"}
                mt={2}
                alignItems={"center"}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={detail.imagen_estado}
                  sx={{
                    width: 42,
                    height: 42,
                    marginRight: 1,
                    borderRadius: "0.5rem",
                  }}
                />
                <Typography
                  sx={stylesFonts.textSection}
                  flexGrow={2}
                  width={100}
                >
                  : {detail.vc_nombre}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography
              sx={stylesFonts.textSection}
              flexGrow={2}
              width={"100%"}
            >
              No hay estados de conservación registrados
            </Typography>
          )}
        </Box>

        <Box width={"100%"}>
          <Typography sx={stylesFonts.subtitleSection}>Autores</Typography>
          {data?.autores?.length > 0 ? (
            data?.autores?.map((detail, index) => (
              <Box
                key={index}
                display={"flex"}
                width={"100%"}
                mt={2}
                alignItems={"center"}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={detail?.imagen_autor ? detail?.imagen_autor : null}
                  sx={{
                    width: 42,
                    height: 42,
                    marginRight: 1,
                    borderRadius: "0.5rem",
                  }}
                />
                <Typography
                  sx={stylesFonts.textSection}
                  flexGrow={2}
                  width={"100%"}
                >
                  : {detail.vc_nombre}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography
              sx={stylesFonts.textSection}
              flexGrow={2}
              width={"100%"}
            >
              No hay autores registrados
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
