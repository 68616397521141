export const transformDataForOptions = (data, idField) => {
  const dataTransformed = data?.map((item) => ({
    id: item[idField],
    label: item.vc_nombre,
  }));
  return dataTransformed;
};

export const transformDataForTable = (data, idField, pkIdField) => {
  const dataTransformed = data?.map((item) => ({
    id: item[idField],
    pk_id: item[pkIdField],
    label: item.vc_nombre,
    status: item.ch_estado,
  }));
  return dataTransformed;
};
