// export const backgroundImage = require("/images/fondo_login.avif");
export const TitleSection = {
  variant: "h4",
  fontSize: "2rem",
  fontWeight: "bold",
  textAlign: "center",
  textTransform: "uppercase",
  fontFamily: "Poppins",
};

export const TitleInput = {
  variant: "h6",
  fontSize: "1rem",
  fontWeight: "bold",
  fontFamily: "Poppins",
  marginBottom: "0.5rem",
};
