import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListOrder = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders`);
    const data = await response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchOrderList = async (
  token,
  currentPage,
  sizePage,
  search = ""
) => {
  try {
    const url = search
      ? `${API_BASE_URL}/orders/admin/search/${search}/${currentPage}/${sizePage}`
      : `${API_BASE_URL}/orders/admin/${currentPage}/${sizePage}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDataOrderById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/${id}`, {});
    const data = await response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveOrEditDataOrder = async (token, data, id = "") => {
  try {
    const url = id
      ? `${API_BASE_URL}/orders/edit/${id}`
      : `${API_BASE_URL}/orders/create`;

    const method = id ? "patch" : "post";

    const response = await axios.request({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const changeStatusDataOrder = async (token, id, body) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/orders/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
