import { useState } from "react";
import { getListCategories } from "../../infrastructure/api/client/categories";
import { speciesArray } from "../../presentation/styles/colors";

const getColorPrimary = (data, item) => {
  const category = data.find((category) => category.name === item);
  return category?.primaryColor;
};

const transformData = (data) => {
  const todosItem = { id: "", value: "", name: "Todos" };
  return [
    todosItem,
    ...data?.map((item) => {
      return {
        id: item.id_taxonomia,
        name: item.vc_nombre,
        icon: item.vc_imagen,
        bgColor: getColorPrimary(speciesArray, item.vc_nombre),
      };
    }),
  ];
};
const transformDataForAutocomplete = (data) => {
  const newData = data?.map((item) => {
    return {
      id: item.id_taxonomia,
      label: item.vc_nombre,
      value: item.id_taxonomia,
    };
  });
  return newData;
};

export const useCategories = () => {
  const [listCategories, setListCategories] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [listForAutocomplete, setListForAutocomplete] = useState([]);

  const getCategories = async () => {
    const response = await getListCategories().then((res) => res);
    if (response.status === 200) {
      setTaxonomies(response.data);
      setListForAutocomplete(transformDataForAutocomplete(response.data));
      setListCategories(transformData(response.data));
      setLoading(false);
    } else {
      setTaxonomies([]);
      setListCategories([]);
      setError(true);
      setLoading(false);
    }
  };

  return {
    listForAutocomplete,
    getCategories,
    listCategories,
    taxonomies,
    error,
    loading,
  };
};
