import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getListSpecies = async (token, search, currentPage, sizePage) => {
  try {
    const apiUrl = search
      ? `${API_BASE_URL}/species/admin/search/${search}/${currentPage}/${sizePage}`
      : `${API_BASE_URL}/species/admin/${currentPage}/${sizePage}`;

    const response = await axios.get(apiUrl, axiosConfig(token));

    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("List Species not found");
      return null;
    }

    throw error;
  }
};

export const getListSpeciesByFilter = async (token, filter) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/species/admin/filter`,
      filter,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getDataSpecieById = async (token, id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/species/admin/${id}`,
      axiosConfig(token)
    );

    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("Species not found");
      return null;
    }

    throw error;
  }
};

export const saveOrEditSpecie = async (token, body, id) => {
  // console.log(body);
  try {
    const apiUrl = id
      ? `${API_BASE_URL}/species/edit/${id}`
      : `${API_BASE_URL}/species/create`;
    const method = id ? "patch" : "post";

    const response = await axios.request({
      method,
      url: apiUrl,
      data: body,
      headers: axiosConfig(token).headers,
    });
    return response;
  } catch (error) {
    return error;
    // throw error;
  }
};

export const changeStatusSpecies = async (token, id, body) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/species/changeStatus/${id}`,
      body,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteSpecies = async (token, id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/species/delete/${id}`,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const recoverySpecie = async (token, id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/species/admin/recoverySpecie/${id}`,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addUpdateSound = async (token, id, body) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/species/addUpdateSound/${id}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteSound = async (token, id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/species/deleteSound/${id}`,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateOrDeleteImg = async (token, id, body) => {
  const apiUrl = body
    ? `${API_BASE_URL}/species/addUpdateImg/${id}`
    : `${API_BASE_URL}/species/deleteImg/${id}`;
  const method = body ? "put" : "delete";

  try {
    const response = await axios.request({
      method,
      url: apiUrl,
      data: body ? body : null,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
