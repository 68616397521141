import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

import { AppBarCuston, IconShare, ScrollToTopButton } from "../../components";
import { CategoriesProvider } from "../../context/client/categoriesProvider";

const TemplateUser = () => {
  return (
    <>
      <CategoriesProvider>
        <AppBarCuston />
        <Outlet />
        <IconShare />
        <ScrollToTopButton />
        <Footer />
      </CategoriesProvider>
    </>
  );
};

export default TemplateUser;
