import React from "react";
import {
  Grid,
  Typography,
  Box,
  Pagination,
  CircularProgress,
  ButtonGroup,
  Button,
  Backdrop,
} from "@mui/material";
import { optionsItemsPerPage } from "../../../../../utils/constants";

import CardSpecies from "../cardSpecies";
import { stylesFont } from "../../../../../styles";

import { speciesArray } from "../../../../styles/colors";

import { useLogicSpecies } from "../../../../../context/client/speciesProvider";
import { CustonSelect } from "../../../../../components";

const urlDetailSpecies = "/species";

const styleButtons = (value) => ({
  borderRadius: "0.5rem",
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: { xs: "0.75rem", md: "0.9rem" },
});

export default function ListSpeciesSection() {
  const { loading, listDataSpecies, dataSpecies, updateUrlParam } =
    useLogicSpecies();

  const [order, setOrder] = React.useState({
    field: "",
    isAsc: false,
  });

  const handleSelectOrderType = (fieldName) => {
    const isSameField = order.field === fieldName;

    setOrder({
      field: fieldName,
      isAsc: isSameField ? !order.isAsc : true,
    });

    if (fieldName !== "") {
      const orderTypeValue = isSameField
        ? order.isAsc
          ? "DESC"
          : "ASC"
        : "ASC";

      updateUrlParam("orderBy", fieldName);
      updateUrlParam("orderType", orderTypeValue);
    } else {
      updateUrlParam("orderBy", null);
      updateUrlParam("orderType", null);
    }
  };

  const renderButton = (id, name, label, isAsc) => (
    <Button
      disableElevation
      key={id}
      sx={styleButtons}
      id={id}
      name={name}
      variant={order.field === name ? "contained" : "outlined"}
      onClick={() => handleSelectOrderType(name)}
      endIcon={
        name && isAsc && order.field === name
          ? "A-Z"
          : name && !isAsc && order.field === name
          ? "Z-A"
          : null
      }
    >
      {label}
    </Button>
  );

  return (
    <>
      <Box paddingTop={2}>
        {dataSpecies.search && (
          <Typography
            sx={{ ...stylesFont.titleSection, fontWeight: 500 }}
            align="center"
          >
            Mostrando resultados de <b>{dataSpecies.search}</b>
          </Typography>
        )}
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            marginBottom: { xs: "1rem", md: "2rem" },
          }}
          // paddingY={1}
        >
          <Box mb={{ xs: 1, sm: 0, display: "flex" }}>
            <Typography sx={stylesFont.text}>
              {dataSpecies.search
                ? "Especies encontradas"
                : "Total de especies de:"}
            </Typography>
            <Typography
              sx={{
                ...stylesFont.text,
                fontWeight: 500,
                marginLeft: "0.5rem",
              }}
            >
              {listDataSpecies?.totalItems}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                ...stylesFont.text,
                marginBottom: { xs: 1, sm: 0 },
              }}
            >
              Ordenar por:
            </Typography>

            <ButtonGroup size="small" defaultValue="ncomun">
              {renderButton("recientes", "", "Recientes", false)}
              {renderButton(
                "ncomun",
                "vc_nombre",
                "N. Común",
                order.field === "vc_nombre" && order.isAsc
              )}
              {renderButton(
                "ncientifico",
                "vc_nombre_cientifico",
                "N. Científico",
                order.field === "vc_nombre_cientifico" && order.isAsc
              )}
            </ButtonGroup>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {!loading && (
            <>
              {listDataSpecies?.listSpecies?.length > 0 ? (
                // Renderizar los datos de la API
                listDataSpecies?.listSpecies.map((specie, index) => (
                  <Grid item xs={12} xls={6} sm={6} xsm={4} lg={3} key={index}>
                    <CardSpecies
                      key={index}
                      urlImage={specie.vc_imagen}
                      commonName={specie.vc_nombre}
                      scientificName={specie.vc_nombre_cientifico}
                      link={`${urlDetailSpecies}/${specie.id_especie}?page=${listDataSpecies?.currentPage}&pageSize=${dataSpecies?.pageSize}`}
                      colorDivider={
                        speciesArray.find(
                          (species) => species.id === specie?.taxa?.id_taxa
                        )?.primaryColor || "blue"
                      }
                      arrayState={specie?.estados_conservacion}
                      audioValue={specie.vc_sonido}
                    />
                  </Grid>
                ))
              ) : (
                // Renderizar el mensaje de error
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <Typography
                    variant="h4"
                    align="center"
                    fontWeight="bold"
                    justifyItems="center"
                  >
                    No se encontraron resultados
                  </Typography>
                  <img
                    src="/images/errorsImages/searchSpecies.svg"
                    alt="not fund"
                    style={{
                      width: "200px",
                      height: "200px",
                      alignItems: "center",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Box
          sx={{
            paddingY: { xs: "1rem", md: "2rem" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            justifyItems: "center",
          }}
        >
          <Pagination
            shape="rounded"
            defaultPage={listDataSpecies?.currentPage}
            boundaryCount={1}
            count={listDataSpecies?.totalPages}
            page={listDataSpecies?.currentPage}
            onChange={(e, value) => updateUrlParam("page", value)}
            color="primary"
            variant="outlined"
          />
          <CustonSelect
            value={dataSpecies?.pageSize}
            onChange={(e) => updateUrlParam("pageSize", e.target.value)}
            options={optionsItemsPerPage}
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      </Box>
      <Backdrop
        open={loading}
        sx={{
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
