import React from "react";
import { Typography, Container } from "@mui/material";
import Box from "@mui/material/Box";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ButtonCuston } from "../../../../components";
import { SpeciesSection } from "./SpeciesSection";
import { stylesFont } from "../../../../styles";

const carouselItems = [
  {
    src: "/images/img2.avif",
    alt: "Descripción de la imagen 2",
  },
  {
    src: "/images/img1.avif",
    alt: "Descripción de la imagen 1",
  },
  {
    src: "/images/img3.avif",
    alt: "Descripción de la imagen 3",
  },
];

export const CarouselSection = () => {
  return (
    <Box
      position={"relative"}
      sx={{
        height: "100%",
        // maxHeight: "840px",
        maxHeight: {
          xs: "100vh",
          sm: "100vh",
          md: "100vh",
          lg: "100vh",
          xxl: "50vh",
        },
        overflow: "hidden",
      }}
    >
      <Carousel
        autoPlay
        infiniteLoop
        animationHandler={"fade"}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        interval={5500}
      >
        {carouselItems?.map((item, index) => (
          <Box
            component="img"
            key={index}
            src={item.src}
            alt={item.alt}
            sx={{
              height: "100vh",
              objectFit: "cover",
            }}
          />
        ))}
      </Carousel>
      <Box
        position={"absolute"}
        top={0}
        height={"100%"}
        width={"100%"}
        color="white"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 10))",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingTop: { xs: 6, sm: 6, md: 16, lg: 16 },
            }}
          >
            <Typography sx={stylesFont.titleExtraLarge}>
              Explora las especies de la guía ilustrada
            </Typography>
            <Typography sx={stylesFont.subtitleExtraLarge}>
              Conoce, navega y comparte
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                paddingBottom: { xs: 0, lg: 2 },
              }}
            >
              <ButtonCuston text={"Quiero ver Todo"} link={"/species"} />
            </Box>
            <SpeciesSection />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
