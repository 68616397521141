import { useState } from "react";
import {
  Box,
  Container,
  Toolbar,
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Drawer,
} from "@mui/material";
import SearchInput from "../../SearchInput";
import { Link } from "react-router-dom";
import { sIconsAppBar } from "../styles";
import LoginIcon from "./LoginIcon";

import BiotechIcon from "@mui/icons-material/Biotech";
import MenuIcon from "@mui/icons-material/Menu";
import AppBarHeaderPhone from "./AppBarHeaderPhone";
import { CustomTooltip } from "./custonTooltip";

import { routesApp } from "../../../utils/constants";

function IconLink({ to, imgSrc, imgAlt, tooltip }) {
  return (
    <Link to={to}>
      <Tooltip title={tooltip}>
        <IconButton sx={sIconsAppBar}>
          {imgSrc ? (
            <img src={imgSrc} alt={imgAlt} style={{ width: 20, height: 20 }} />
          ) : (
            <BiotechIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </Link>
  );
}

const iconOptions = [
  {
    to: routesApp.species,
    imgSrc: "/images/svg/mono.png",
    imgAlt: "iconIndigena",
    tooltip: "Especies",
  },
  {
    to: routesApp.communities,
    imgSrc: "/images/svg/choza.png",
    imgAlt: "iconIndigena",
    tooltip: "Comunidades indígenas",
  },
  {
    to: routesApp.authors,
    tooltip: "Autores",
    isBiotech: true, // Indica que debe usar BiotechIcon
  },
];

export const AppBarHeader = () => {
  const [open, setOpen] = useState(false);
  // const [isHover, setIsHover] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "primary.main", height: "auto" }}>
        <Container maxWidth={"lg"}>
          <Toolbar disableGutters>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              columns={{ xs: 4, sm: 4, md: 12, lg: 12 }}
            >
              <Box
                component={"a"}
                href="/"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <Box
                  component="img"
                  src="/favicon/logo_amazonia.png"
                  alt="logo"
                  sx={{
                    height: "100%",
                    maxHeight: "52px",
                  }}
                />
              </Box>
              <Stack direction={"row"} spacing={2}>
                <CustomTooltip />
                <SearchInput />
                <Box
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "flex",
                  }}
                  alignItems="center"
                  justifyItems="items"
                  gap={2}
                >
                  {iconOptions.map((iconOption, index) => (
                    <IconLink key={index} {...iconOption} />
                  ))}
                  <LoginIcon />
                </Box>
              </Stack>
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => handleDrawerToggle()}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
        <Drawer anchor="left" open={open} onClose={handleDrawerToggle}>
          <AppBarHeaderPhone onClose={handleDrawerToggle} />
        </Drawer>
      </Box>
    </>
  );
};
