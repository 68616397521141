import React from "react";
//threejs
import { ThreeMFLoader } from "three/examples/jsm/loaders/3MFLoader";
import { useLoader } from "@react-three/fiber";
// import { OBJLoader } from "three-obj-loader";
// import model from "./Scan123.obj";
import model from "./rana.3mf";

const Model = () => {
    const geom = useLoader(ThreeMFLoader, model);
    return (
        <group rotation={[-Math.PI / 2, 0, 0]}>
            <primitive object={geom} />
        </group>
    );
};

export default Model;
