import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./presentation/styles/colors";
import ReactGA from "react-ga";

import HomePage from "./presentation/pages/HomePage";
import SpeciesPage from "./presentation/pages/SpeciesPage";
import CommunityPage from "./presentation/pages/CommunityPage";
import CommunityView from "./presentation/pages/CommunityView";
import SpeciesView from "./presentation/pages/SpeciesView";
import AuthorPage from "./presentation/pages/AuthorPage";
import AuthorDetail from "./presentation/components/Authors/[id]/pageAuthor";
import NotFundPage from "./presentation/pages/NotFundPage";
import { AuthProvider } from "./context/AuthProvider";
import TemplateAdmin from "./presentation/layout/TemplateAdmin";
import Dashboard from "./presentation/components/Administrador/dashboard/Dashboard";
import TemplateLogin from "./presentation/layout/TemplateLogin";
import LoginPage from "./presentation/components/Administrador/LoginPage";
import TemplateUser from "./presentation/layout/TemplateUser";

//Forms specie
import IndexSpecies from "./presentation/components/Administrador/species/IndexSpecies";
import FormAddSpecies from "./presentation/components/Administrador/species/FormAddSpecies/FormAddSpecies";
import FormDetailsSpecie from "./presentation/components/Administrador/species/FormDetailsSpecies/FormDetailsSpecie";

//Forms authors
import FormAddAutores from "./presentation/components/Administrador/autores/FormAddAuthor/FormAddAuthor";
import FormDetailsAuthors from "./presentation/components/Administrador/autores/FormDetailsAuthor/FormDetailsAuthors";
import IndexAuthor from "./presentation/components/Administrador/autores/IndexAuthors";

//Forms towns
import IndexTows from "./presentation/components/Administrador/towns/IndexTows";
import DetailsTowsIndex from "./presentation/components/Administrador/towns/FormDetailsTowns/DetailsTownsIndex";
import FormAddTowns from "./presentation/components/Administrador/towns/FormAddTowns/FormAddTowns";

//Forms conservation
import IndexConservation from "./presentation/components/Administrador/conservation/IndexConservation";
import FormAddConservation from "./presentation/components/Administrador/conservation/FormAddConservation/FormAddConservation";
import FomrDetailsConservation from "./presentation/components/Administrador/conservation/DetailConservation/FormDetailsConservation";
import IndexTaxonomy from "./presentation/components/Administrador/taxonomy/indexTaxonomy";
import { IndexCategories } from "./presentation/components/Administrador/categories/indexCategories";
import { SplashScreen } from "./components";

ReactGA.initialize("G-GWZ4N9PCCD");

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga asíncrona, deberías reemplazarlo con tu lógica de carga real
    const fakeLoading = () => {
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Simula una carga de 2 segundos
    };

    ReactGA.pageview(window.location.pathname + window.location.search);
    fakeLoading();
  }, []);

  return loading ? (
    <SplashScreen />
  ) : (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<TemplateUser />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/species" element={<SpeciesPage />} />
              <Route path="/authors" element={<AuthorPage />} />
              <Route path="/authors/:id" element={<AuthorDetail />} />
              <Route path="species/:id" element={<SpeciesView />} />
              <Route path="/community" element={<CommunityPage />} />
              <Route path="/community/:id" element={<CommunityView />} />
              <Route path="*" element={<NotFundPage />} />
            </Route>

            <Route path="/login" element={<TemplateLogin />}>
              <Route index element={<LoginPage />} />
            </Route>
            <Route path="/admin" element={<TemplateAdmin />}>
              <Route index element={<Dashboard />} />
              <Route path="especies" element={<IndexSpecies />} />
              <Route path="especies/add" element={<FormAddSpecies />} />
              <Route path="especies/edit/:id" element={<FormAddSpecies />} />
              <Route path="autores" element={<IndexAuthor />} />
              <Route path="autores/add" element={<FormAddAutores />} />
              <Route path="autores/edit/:id" element={<FormAddAutores />} />
              <Route
                path="autores/details/:id"
                element={<FormDetailsAuthors />}
              />
              <Route
                path="especies/details/:id"
                element={<FormDetailsSpecie />}
              />
              <Route path="towns" element={<IndexTows />} />
              <Route path="towns/add" element={<FormAddTowns />} />
              <Route path="towns/edit/:id" element={<FormAddTowns />} />
              <Route path="towns/details/:id" element={<DetailsTowsIndex />} />
              <Route
                path="status-conservation"
                element={<IndexConservation />}
              />
              <Route
                path="status-conservation/add"
                element={<FormAddConservation />}
              />
              <Route
                path="status-conservation/edit/:id"
                element={<FormAddConservation />}
              />
              <Route
                path="status-conservation/details/:id"
                element={<FomrDetailsConservation />}
              />
              <Route path="taxonomia" element={<IndexTaxonomy />} />
              <Route path="category" element={<IndexCategories />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
