import { Box } from "@mui/material";
import { SearchInputSection } from "../../SearchInputSection";
import { TableListSpecie } from "../ListTableSpecie/TableListSpecies";
import AutocompleteCustom from "../AutocompleteCustom";

export const TableListSectionSpecies = ({
  loading,
  data,
  dataDefault,
  dataFilter,
  ...props
}) => {
  return (
    <>
      <Box
        sx={{
          paddingBottom: 2,
        }}
      >
        <SearchInputSection
          defaultValue={dataDefault && dataDefault.search}
          onResult={props.onChangeSearch}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          paddingBottom: 2,
          display: { xs: "block", sm: "flex" },
          gap: 2,
          columnGap: 2,
        }}
      >
        <AutocompleteCustom
          size="small"
          placeholder={"Categoria"}
          fullWidth
          options={dataFilter?.category && dataFilter.category}
          value={dataDefault?.category && dataDefault.category}
          onChange={(event, value) =>
            props.onChangeCategory(value ? value.id : null)
          }
        />
        <AutocompleteCustom
          size="small"
          placeholder={"Clase"}
          fullWidth
          options={dataFilter?.class && dataFilter.class}
          value={dataDefault?.class && dataDefault.class}
          onChange={(event, value) =>
            props.onChangeClass(value ? value.id : null)
          }
        />
        <AutocompleteCustom
          size="small"
          placeholder={"Orden"}
          fullWidth
          options={dataFilter?.order && dataFilter.order}
          value={dataDefault?.order && dataDefault.order}
          onChange={(event, value) =>
            props.onChangeOrder(value ? value.id : null)
          }
        />
        <AutocompleteCustom
          size="small"
          placeholder={"Familia"}
          fullWidth
          options={dataFilter?.family && dataFilter.family}
          value={dataDefault?.family && dataDefault.family}
          onChange={(event, value) =>
            props.onChangeFamily(value ? value.id : null)
          }
        />
        <AutocompleteCustom
          size="small"
          placeholder={"Est. conservación"}
          fullWidth
          // defaultValue={dataDefault && dataDefault.search}
          // onResult={props.onChangeSearch}
        />
      </Box>
      <Box>
        <TableListSpecie
          loading={loading}
          data={data ? data : []}
          dataDefault={dataDefault && dataDefault}
          handlePage={props.handlePage}
          handleRows={props.handleSize}
          confirmDelete={props.confirmDelete}
          onChangeView={props.onChangeView}
          onChangeEdit={props.onChangeEdit}
          onChangeStatus={props.onChangeStatus}
        />
      </Box>
    </>
  );
};
