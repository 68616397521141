/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useCategories } from "../../hooks/clients/useCategories";

const CategoriesContex = createContext();

const CategoriesProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { listCategories, getCategories } = useCategories();
  const selectedCategoryId =
    searchParams.get("categoria") === null
      ? ""
      : parseInt(searchParams.get("categoria"));

  useEffect(() => {
    getCategories();
  }, []);

  const handleCategorySelection = (value) => {
    const params = new URLSearchParams(window.location.search);

    if (value === "") {
      params.delete("categoria");
      navigate(`?${params.toString()}`);
      return;
    }
    params.set("page", 1);
    params.set("categoria", value);
    navigate(`?${params.toString()}`);
  };

  const clearCategorySelected = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("categoria");
    navigate(`?${params.toString()}`);
  };

  return (
    <CategoriesContex.Provider
      value={{
        selectedCategoryId,
        listCategories,
        handleCategorySelection,
        clearCategorySelected,
      }}
    >
      {children}
    </CategoriesContex.Provider>
  );
};

export { CategoriesProvider };
export const useLogicCategories = () => useContext(CategoriesContex);
