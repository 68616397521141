import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListSpeciesByFilter = async (body) => {
  const response = await axios
    .post(`${API_BASE_URL}/species/filter`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Species not found");
        return null;
      }

      throw err;
    });
  return response;
};

export const getSpecieById = async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}/species/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Specie not found");
        return null;
      }

      throw err;
    });
  return response;
};

export const getListSpeciesPagination = async (page, limit) => {
  const response = await axios
    .get(`${API_BASE_URL}/species/${page}/${limit}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("List Species not found");
        return null;
      }

      throw err;
    });
  return response;
};
