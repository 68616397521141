import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";

const styleTextField = {
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "#fff",
  //   },
  //   "&.Mui-focused fieldset": {
  //     borderColor: "#fff",
  //   },
  // },
};

export const SearchInputSection = ({
  onResult,
  defaultValue,
  loading,
  ...props
}) => {
  const [textSearch, setTextSearch] = useState("");
  const activeDelete =
    textSearch?.length > 0 || defaultValue?.length > 0 ? true : false;

  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setTextSearch(searchText);
    onResult(searchText); // Envia el prop a medida que el usuario escribe
  };

  const handleClear = () => {
    setTextSearch("");
    onResult(""); // Borra el contenido del prop
  };

  return (
    <FormControl fullWidth variant="standard">
      <TextField
        {...props}
        placeholder={props.placeholder ? props.placeholder : "Buscar..."}
        size="small"
        sx={styleTextField}
        value={defaultValue ? defaultValue : textSearch}
        onChange={onResult ? (e) => handleSearchChange(e) : () => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <SearchIcon style={{ color: "#9e9e9e" }} />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {activeDelete && (
                <ClearIcon
                  onClick={handleClear}
                  style={{ cursor: "pointer" }}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default SearchInputSection;
