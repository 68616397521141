export const sIconsAppBar = {
  color: "white",
  padding: 1,
  borderRadius: 2,
  border: "1px solid gray",
  ":hover": {
    backgroundColor: "rgba(192, 192, 192, 0.2)",
    border: "1px solid white",
  },
};
