/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, TextField } from "@mui/material";

import { useLogicCommunitiesAdmin } from "../../../../../context/admin/CommunitiesProvider";

import ButtonCustom from "../../../../../components/Admin/ButtonCustom";
import { styleSections, titleSection } from "../../styles";
import { SectionMultimedia } from "./SectionMultimedia";
import { LayoutFrmAdd } from "../../../../../components/Admin/layouts/layoutFrmAdd";

const FormAddTowns = () => {
  const { id_community, community, saveOrEditTown, loading } =
    useLogicCommunitiesAdmin();

  const [formData, setFormData] = useState({
    vc_nombre: "",
    te_descripcion: "",
    de_longitud: 0,
    de_latitud: 0,
  });

  useEffect(() => {
    // Cargar datos del pueblo indígena seleccionado en el formulario si existe
    if (id_community) {
      setFormData({
        vc_nombre: community?.vc_nombre,
        te_descripcion: community?.te_descripcion,
        de_longitud: community?.de_longitud,
        de_latitud: community?.de_latitud,
      });
    }
  }, [community, id_community]);

  const changeSaveOrEdit = () => {
    saveOrEditTown(id_community, formData);
  };

  return (
    <Box>
      <LayoutFrmAdd
        loading={loading}
        title={
          id_community ? "Editar Pueblo Indígena" : "Agregar Pueblo Indígena"
        }
        label={"Pueblos Indígenas"}
      >
        <Box sx={{ display: { xs: "block", md: "flex" }, gap: 2 }}>
          <Box sx={{ ...styleSections }}>
            <Typography variant="h6" gutterBottom sx={titleSection}>
              Detalles del Pueblo
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nombre del pueblo"
                  name="vc_nombre"
                  value={formData.vc_nombre}
                  placeholder={"Escribe el nombre..."}
                  onChange={(e) =>
                    setFormData({ ...formData, vc_nombre: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="de_longitud"
                  label="Longitud"
                  value={formData.de_longitud}
                  placeholder={"Longitud"}
                  onChange={(e) =>
                    setFormData({ ...formData, de_longitud: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="de_latitud"
                  label="Latitud"
                  placeholder={"Latitud"}
                  value={formData.de_latitud}
                  onChange={(e) =>
                    setFormData({ ...formData, de_latitud: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  placeholder="Escribe una descripción..."
                  variant="outlined"
                  label="Descripción"
                  multiline
                  rows={8}
                  fullWidth
                  value={formData.te_descripcion}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      te_descripcion: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              sx={{ gap: 2, mt: 2 }}
            >
              <ButtonCustom
                label={id_community ? "Editar" : "Guardar"}
                color={"primary"}
                onClick={() => changeSaveOrEdit()}
              />
              <ButtonCustom
                label={"Cancelar"}
                color={"error"}
                to={"/admin/towns"}
              />
            </Box>
          </Box>

          {id_community ? (
            <Box
              sx={{ ...styleSections, maxWidth: { xs: "100%", md: "340px" } }}
            >
              <SectionMultimedia data={community} />
            </Box>
          ) : null}
        </Box>
      </LayoutFrmAdd>
    </Box>
  );
};

export default FormAddTowns;
