/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import { stylesFonts } from "../../styles";

import { LayoutsFrmDetails } from "../../../../../components";
import { useLogicAuthorsAdmin } from "../../../../../context/admin/authorsProvider";
import { Box } from "@mui/system";

const FormDetailsAuthor = () => {
  const { author, loading } = useLogicAuthorsAdmin();

  const details = [
    {
      title: "Profesión",
      value: author.vc_profesion ? author.vc_profesion : "No registrado",
    },
    {
      title: "Año de nacimiento",
      value: author.vc_ano_nacimiento
        ? author.vc_ano_nacimiento
        : "No registrado",
    },
    {
      title: "Año de deceso",
      value: author.vc_ano_deceso ? author.vc_ano_deceso : "No registrado",
    },
  ];

  return (
    <>
      <LayoutsFrmDetails
        image={author?.vc_imagen}
        title={author?.vc_nombre + " " + author?.vc_apellido}
        label={"Autores"}
        loading={loading}
      >
        <Typography sx={stylesFonts.titleSection}>
          Información general
        </Typography>
        <Box>
          {details?.map((detail, index) => (
            <Box
              key={index}
              sx={{
                display: { xs: "block", sm: "flex" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: { xs: "100%", sm: "20%" },
                }}
              >
                <Typography sx={stylesFonts.subtitleSection}>
                  {detail.title} :
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography sx={stylesFonts.textSection}>
                  {detail.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box mt={2}>
          <Typography sx={stylesFonts.subtitleSection}>Descripción</Typography>
          <Typography sx={{ ...stylesFonts.textSection, textAlign: "justify" }}>
            {author?.te_biografia}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography sx={stylesFonts.titleSection}>
            Especies Relacionadas ( {author?.species?.length})
          </Typography>
          {author?.species?.map((item, index) => (
            <Typography
              key={index}
              sx={{ ...stylesFonts.textSection, textAlign: "justify" }}
            >
              {item?.vc_nombre}
            </Typography>
          ))}
        </Box>
      </LayoutsFrmDetails>
    </>
  );
};

export default FormDetailsAuthor;
