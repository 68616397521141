import React from "react";
import BannerSection from "../../../components/BannerSection";

import { useParams } from "react-router-dom";
import { AuthorsProvider } from "../../../context/client/authorsProvider";

const LayoutAuthors = ({ title, currentPage, children }) => {
  const { id } = useParams();

  const arrayLinks = id ? [{ url: "species/authors", label: "Autores" }] : [];
  return (
    <>
      <AuthorsProvider>
        <BannerSection
          title={title}
          image={"/images/banner_authors.avif"}
          currentPage={currentPage}
          arrayLinks={arrayLinks}
        />
        {children}
      </AuthorsProvider>
    </>
  );
};

export default LayoutAuthors;
