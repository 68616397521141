import { useState } from "react";

import {
  getListSpeciesByFilter,
  getSpecieById,
} from "../../infrastructure/api/client/species";

export const useSpecies = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [listDataSpecies, setListDataSpecies] = useState({
    listSpecies: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
  });
  const [detailSpecie, setDetailSpecie] = useState({});

  const getSpeciesByFilter = async (filter) => {
    setLoading(true);
    const response = await getListSpeciesByFilter(filter).then((res) => res);
    if (response) {
      setListDataSpecies({
        listSpecies: response.data.species,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage,
      });
      setLoading(false);
    } else {
      setListDataSpecies({
        listSpecies: [],
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
      });
      setError(true);
      setLoading(false);
    }
  };

  const getDetailSpecie = async (id) => {
    setLoading(true);
    const response = await getSpecieById(id).then((res) => res);
    if (response) {
      setDetailSpecie(response.data);
      setLoading(false);
    } else {
      setDetailSpecie({});
      setError(true);
      setLoading(false);
    }
  };

  return {
    listDataSpecies,
    error,
    loading,
    getSpeciesByFilter,
    detailSpecie,
    getDetailSpecie,
  };
};
