import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  Box,
  Avatar,
  Skeleton,
  TablePagination,
  Typography,
  CircularProgress,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { formatDate } from "../../../infrastructure/ui/formatData";
import { ActionsRowTable } from "./ActionsTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(248, 249, 250)",
    color: "rgb(47, 55, 70)",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const headRowDefault = [
  "#",
  "Imagen",
  "Nombre",
  "Modificación",
  "Estado",
  "Acciones",
];

const headRowSimple = ["#", "Nombre", "Estado", "Acciones"];

export const TableList = ({
  loading,
  data,
  dataDefault,
  isSimple,
  ...props
}) => {
  return (
    <>
      <TableContainer
        component="div"
        sx={{
          borderRadius: 2,
          minHeight: 500,
          maxHeight: "calc(100vh - 200px)",
          backgroundColor: "#ffffff",
          // maxHeight: 500,
        }}
      >
        <Table aria-label="customized table" stickyHeader>
          {
            <TableHead>
              <TableRow>
                {isSimple
                  ? headRowSimple?.map((item, index) => (
                      <StyledTableCell key={index}>{item}</StyledTableCell>
                    ))
                  : headRowDefault?.map((item, index) => (
                      <StyledTableCell key={index}>{item}</StyledTableCell>
                    ))}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={
                    isSimple ? headRowSimple.length : headRowDefault.length
                  }
                  align="center"
                  height={300}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <>
                {data?.data?.length > 0 ? (
                  data?.data?.map((item, index) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell component="th" scope="row">
                        {item.id}
                      </StyledTableCell>
                      {!isSimple && (
                        <StyledTableCell align="right">
                          {item.image ? (
                            <Avatar
                              alt="Remy Sharp"
                              src={item.image}
                              sx={{
                                width: 42,
                                height: 42,
                                backgroundColor: "#ffffff",
                              }}
                            />
                          ) : (
                            <Skeleton
                              variant="circular"
                              width={42}
                              height={42}
                            />
                          )}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left" width={300}>
                        {item.name ? item.name : ""}
                      </StyledTableCell>
                      {!isSimple && (
                        <StyledTableCell align="left" width={300}>
                          {item.date
                            ? formatDate(item.date)
                            : "-- / -- / --  -- : --"}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left" width={100}>
                        <Typography
                          variant="body2"
                          fontWeight={900}
                          color={
                            item.status === 1 ? "success.main" : "error.main"
                          }
                        >
                          {item.status === 1 ? "Activo" : "Elimninado"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <ActionsRowTable
                          data={item}
                          confirmDelete={(value, data) =>
                            props.confirmDelete(value, data)
                          }
                          onChangeView={props.onChangeView}
                          onChangeEdit={props.onChangeEdit}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={6} align="center">
                      No hay datos
                    </StyledTableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.isPagination !== false && (
        <TablePagination
          component={"div"}
          rowsPerPageOptions={[15, 20, 30, 50]}
          count={data?.totalItems ? data.totalItems : 0}
          rowsPerPage={dataDefault ? dataDefault.sizePage : 15}
          page={dataDefault ? dataDefault.currentPage - 1 : 0}
          onPageChange={(e, page) => props.handlePage(page + 1)}
          onRowsPerPageChange={(e) => props.handleRows(e.target.value)}
        />
      )}
    </>
  );
};
