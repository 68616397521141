import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const rowHeaders = [
  {
    label: "#",
    id: "id",
    width: 50,
  },
  {
    label: "Nombre",
    id: "name",
    width: "100%",
  },
  {
    label: "Acciones",
    id: "actions",
    width: 100,
  },
];

const TableList = ({
  //News props
  titleTable,
  data,
  dataDefault,
  isPagination,
  // response,
  loading,
  ...props
}) => {
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          sx={{
            maxHeight: isPagination ? 288 : 340,
            minHeight: isPagination ? 288 : 340,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {titleTable && (
                <TableRow>
                  <TableCell
                    sx={{ backgroundColor: "#F5F5F5" }}
                    align="center"
                    colSpan={rowHeaders.length}
                  >
                    {titleTable}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                {rowHeaders?.map((row) => (
                  <TableCell
                    sx={{ backgroundColor: "rgb(248, 249, 250)" }}
                    key={row.id}
                    align={row.align}
                    width={row.width}
                  >
                    {row.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <CircularProgress color="inherit" />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {!data || data?.data?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No hay datos
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.data?.map((row, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {row.image ? (
                              <Avatar
                                src={row.image}
                                sx={{
                                  width: 32,
                                  height: 32,
                                  marginRight: 2,
                                  borderRadius: 1,
                                }}
                              />
                            ) : null}
                            {row.name}
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip
                            title={!props.isDelete ? "Añadir" : "Quitar"}
                          >
                            <IconButton
                              size="small"
                              color="success"
                              onClick={() => {
                                props.optionValue(row);
                              }}
                              sx={{
                                border: "2px solid ",
                                borderRadius: "0.5rem",
                              }}
                            >
                              {props.isDelete ? (
                                <RemoveCircleOutlineIcon />
                              ) : (
                                <ControlPointIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isPagination ? (
          <TablePagination
            disabled={false}
            rowsPerPageOptions={[15, 25, 100]}
            component="div"
            count={data?.totalItems ? data?.totalItems : 0}
            rowsPerPage={dataDefault?.sizePage ? dataDefault?.sizePage : 15}
            page={dataDefault?.currentPage ? dataDefault?.currentPage - 1 : 0}
            onPageChange={props.handlePage}
            onRowsPerPageChange={props.handleRows}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default TableList;
