/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect } from "react";

import {
  listDataClase,
  listDataOrden,
  listDataFamilia,
} from "../infrastructure/api/axiosDataTaxonomiaAdmin";

// import useSpecies from "../utils/hooks/useSpecies";

const TaxaSpecieContext = createContext();

const TaxaSpecieProvider = ({ children }) => {
  // const { id_especie_selected, specieDetail } = useSpecies();
  const [loading, setLoading] = useState(true);

  const [dataListApi, setDataListApi] = useState({
    listClase: [],
    listOrden: [],
    listFamilia: [],
  });

  const [options, setOptions] = useState({
    optionsClase: [],
    optionsOrden: [],
    optionsFamilia: [],
  });

  const [selectedIds, setSelectedIds] = useState({
    id_clase: "",
    id_orden: "",
    id_familia: "",
  });

  //Si existe un id de especie seleccionado, obtener los datos de la especie
  // useEffect(() => {
  //   if (specieDetail) {
  //     const getDetailSpecie = () => {
  //       setSelectedIds((prevState) => ({
  //         ...prevState,
  //         id_clase: specieDetail?.id_clase,
  //         id_orden: specieDetail?.id_orden,
  //         id_familia: specieDetail?.id_familia,
  //       }));
  //     };
  //     getDetailSpecie();
  //   }
  // }, [id_especie_selected, specieDetail]);

  const transformData = (data, nameKey, nameFkey) => {
    return data?.map((item) => {
      return {
        id: item[`id_${nameKey}`],
        fk_id: item[`id_${nameFkey}`],
        label: item.vc_nombre,
        value: item[`id_${nameKey}`],
      };
    });
  };

  //Llamar las lista de las opciones de las apis
  const loadListData = (dataLoader, nameList, optionsKey, optionsFkey) => {
    setLoading(true);
    dataLoader().then((response) => {
      setLoading(false);
      setOptions((prevState) => ({
        ...prevState,
        [`options${nameList}`]: transformData(
          response.data,
          optionsKey,
          optionsFkey
        ),
      }));
      setDataListApi((prevState) => ({
        ...prevState,
        [`list${nameList}`]: transformData(
          response.data,
          optionsKey,
          optionsFkey
        ),
      }));
    });
  };

  useEffect(() => {
    loadListData(listDataClase, "Clase", "clase", "filo");
    loadListData(listDataOrden, "Orden", "orden", "clase");
    loadListData(listDataFamilia, "Familia", "familia", "orden");
  }, []);

  //Function filter
  const filterData = (data, id) => {
    return data?.filter((item) => item.fk_id === id);
  };

  useEffect(() => {
    if (selectedIds.id_clase) {
      setOptions((prevState) => ({
        ...prevState,
        optionsOrden: filterData(dataListApi.listOrden, selectedIds.id_clase),
      }));
    } else {
      setOptions((prevState) => ({
        ...prevState,
        optionsOrden: dataListApi.listOrden,
      }));
    }
  }, [selectedIds.id_clase]);

  useEffect(() => {
    if (selectedIds.id_orden) {
      setOptions((prevState) => ({
        ...prevState,
        optionsFamilia: filterData(
          dataListApi.listFamilia,
          selectedIds.id_orden
        ),
      }));
    } else {
      setOptions((prevState) => ({
        ...prevState,
        optionsFamilia: dataListApi.listFamilia,
      }));
    }
  }, [selectedIds.id_orden]);

  //Problemas en mi filtro
  useEffect(() => {
    if (selectedIds.id_familia) {
      const newIdOrden = dataListApi.listFamilia.find(
        (item) => item.id === selectedIds.id_familia
      )?.fk_id;
      if (newIdOrden !== selectedIds.id_orden) {
        setSelectedIds((prevState) => ({
          ...prevState,
          id_orden: newIdOrden,
        }));
      }
    }
  }, [selectedIds.id_familia, dataListApi.listFamilia, selectedIds.id_orden]);

  useEffect(() => {
    if (selectedIds.id_orden) {
      const newIdClase = dataListApi.listOrden.find(
        (item) => item.id === selectedIds.id_orden
      )?.fk_id;
      if (newIdClase !== selectedIds.id_clase) {
        setSelectedIds((prevState) => ({
          ...prevState,
          id_clase: newIdClase,
        }));
      }
    }
  }, [selectedIds.id_orden, dataListApi.listOrden, selectedIds.id_clase]);

  //Seleccionar los id de las opciones
  const setIdSelected = (id, value) => {
    setSelectedIds((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <TaxaSpecieContext.Provider
      value={{
        options,
        setIdSelected,
        selectedIds,
        loading,
      }}
    >
      {children}
    </TaxaSpecieContext.Provider>
  );
};

export { TaxaSpecieProvider };

export default TaxaSpecieContext;
