/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { stylesFont } from "../../../../../styles";
import MapSection from "./mapSection";
import { useEffect } from "react";

const InfoSection = ({ community }) => {
  const [coordinates, setCoordinates] = useState({
    lat: -4.853257,
    lng: -74.272563,
  });

  useEffect(() => {
    if (community.de_latitud && community.de_longitud) {
      setCoordinates({
        lat: parseFloat(community.de_latitud),
        lng: parseFloat(community.de_longitud),
      });
    }
  }, [community]);

  return (
    <>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12} sm={6}>
          <Box
            component={"img"}
            src={community?.vc_image}
            sx={{ width: "100%", height: "100%", maxHeight: "420px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography
              sx={{ ...stylesFont.titleLarge, mb: 2, color: "inherit" }}
            >
              {community.vc_nombre}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ ...stylesFont.titleSection, mb: 2 }}>
              Ubicación
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }} gap={2}>
              <Typography sx={{ ...stylesFont.text, mb: 1 }}>
                Longitud: {community.de_longitud}
              </Typography>
              <Typography sx={{ ...stylesFont.text, mb: 1 }}>
                Latitud: {community.de_latitud}
              </Typography>
            </Box>
            <Box pt={2}>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1990.5979699140187!2d-73.27601966156098!3d-3.7674969226365267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ea10271e72f725%3A0xfb6dde79feef3c8e!2sInstituto%20de%20Investigaciones%20de%20la%20Amazon%C3%ADa%20Peruana!5e0!3m2!1ses-419!2spe!4v1698418306109!5m2!1ses-419!2spe"
                style={{
                  width: "100%",
                  height: "220px",
                  border: 0,
                }}
              ></iframe> */}
              <MapSection coordinates={coordinates} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...stylesFont.titleSection, mb: 2 }}>
            Descripción:
          </Typography>
          <Typography sx={{ ...stylesFont.text, mb: 1 }}>
            {community.te_descripcion}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default InfoSection;
