/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { TableListSectionSpecies } from "../../../../../components/Admin/sectionsSearch/TableListSectionSpecie";
import { CustonSnackBar } from "../../../../../components";
import { useNavigate } from "react-router-dom";

import { usePagination } from "../../../../../hooks/admin/usePagination";
import { useLogicSpeciesAdmin } from "../../../../../context/admin/speciesProvider";
import { useCategories } from "../../../../../hooks/clients/useCategories";
import { useTaxonomies } from "../../../../../hooks/clients/useTaxonomies";

const ListSpecies = () => {
  const {
    dataDefault,
    getListDataSpecies,
    listSpecies,
    changeStatusSpecie,
    deleteOrRecovery,
    loading,
    message,
  } = useLogicSpeciesAdmin();

  const navigate = useNavigate();
  const { updateUrlParam } = usePagination();
  const { listForAutocomplete, getCategories } = useCategories();
  const { getListDataClass, getListDataOrder, getListDataFamily, taxonomies } =
    useTaxonomies();

  const [open, setOpen] = React.useState(false);
  const [dataFilter, setDataFilter] = React.useState({
    category: [],
    class: [],
    order: [],
    family: [],
    conservation: [],
  });

  useEffect(() => {
    if (message?.message !== "") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message?.message]);

  useEffect(() => {
    getListDataSpecies();
  }, []);

  useEffect(() => {
    getCategories();
    getListDataClass();
    getListDataOrder();
    getListDataFamily();
  }, []);

  useEffect(() => {
    setDataFilter({
      ...dataFilter,
      category: listForAutocomplete,
      class: taxonomies.listClass,
      order: taxonomies.listOrder,
      family: taxonomies.listFamily,
    });
  }, [listForAutocomplete, taxonomies]);

  const handleStatus = (id, value) => {
    changeStatusSpecie(id, value);
  };

  const handleDelete = (value, data) => {
    if (value) {
      deleteOrRecovery(data.id, data.status);
    }
  };

  const changeView = (id) => {
    navigate(`details/${id}`);
  };

  const changeEdit = (id) => {
    navigate(`edit/${id}`);
  };

  return (
    <>
      <TableListSectionSpecies
        loading={loading}
        dataDefault={dataDefault}
        data={listSpecies}
        onChangeSearch={(value) => updateUrlParam("search", value)}
        handlePage={(value) => updateUrlParam("page", value)}
        handleSize={(value) => updateUrlParam("size", value)}
        onChangeStatus={handleStatus}
        dataFilter={dataFilter && dataFilter}
        onChangeEdit={changeEdit}
        onChangeView={changeView}
        onChangeCategory={(value) => updateUrlParam("category", value)}
        onChangeClass={(value) => updateUrlParam("class", value)}
        onChangeOrder={(value) => updateUrlParam("order", value)}
        onChangeFamily={(value) => updateUrlParam("family", value)}
        confirmDelete={handleDelete}
      />
      <CustonSnackBar
        open={open}
        onClose={() => setOpen(false)}
        message={message?.message}
        severity={
          message?.status === 200
            ? "success"
            : message?.status === 401
            ? "warning"
            : "error"
        }
      />
    </>
  );
};

export default ListSpecies;
