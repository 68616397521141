/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "220px",
};

const MapSection = ({ coordinates }) => {
    console.log(coordinates);
    const center = {
        lat: coordinates.lat,
        lng: coordinates.lng,
    };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyAA5Bx5lnDkEfrFHSd3WoRR0cv3s5IQY3c",
    });
    // const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(
        function callback(map) {
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            // const bounds = new window.google.maps.LatLngBounds(center);
            // map.fitBounds(bounds);
            map.setCenter(center);
            // setMap(map);
        },
        [center]
    );

    // const onUnmount = React.useCallback(function callback(map) {
    //     // setMap(null);
    // }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={8}
            onLoad={onLoad}
            // onUnmount={onUnmount}
        >
            {center && <Marker position={center} />}
        </GoogleMap>
    ) : (
        <></>
    );
};

export default MapSection;
