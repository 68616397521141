import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { stylesFonts } from "../../styles";

export const MultimediaSection = ({ data }) => {
  const audioRef = useRef();
  return (
    <Box mt={2}>
      <Typography sx={stylesFonts.titleSection}>Multimedia</Typography>
      <Box>
        <Typography
          sx={stylesFonts.subtitleSection}
          fontWeight={700}
          flexGrow={1}
          maxWidth={180}
        >
          Audio de especie
        </Typography>
        <Box>
          {data.vc_sonido ? (
            <audio
              controls
              ref={audioRef}
              style={{
                marginTop: "1rem",
                height: "30px",
              }}
            >
              <source src={data.vc_sonido} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <Typography
              sx={stylesFonts.textSection}
              flexGrow={2}
              width={"100%"}
            >
              No tiene audio registrado
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
