import { Stack } from "@mui/material";
import AutocompleteCustom from "../../../../../components/Admin/AutocompleteCustom";
import { useLogicTaxonomies } from "../../../../../context/client/taxonomiesProvider";

export const FiltersTaxonomy = () => {
  const { taxonomies, loading, taxaSelected, setOptionSelected } =
    useLogicTaxonomies();

  return (
    <>
      <Stack spacing={1}>
        <AutocompleteCustom
          placeholder={loading ? "Cargando..." : "Selecione Clase"}
          options={taxonomies?.listClass}
          value={taxaSelected?.class}
          onChange={(e, value) => {
            setOptionSelected("clase", value ? value.id : null);
          }}
          disabled={taxaSelected?.order ? true : false}
        />

        <AutocompleteCustom
          placeholder={loading ? "Cargando..." : "Selecione Orden"}
          options={taxonomies?.listOrder}
          value={taxaSelected?.order}
          onChange={(e, value) => {
            setOptionSelected("orden", value ? value.id : null);
          }}
        />
        <AutocompleteCustom
          placeholder={loading ? "Cargando..." : "Selecione la Familia"}
          options={taxonomies?.listFamily}
          value={taxaSelected?.family}
          onChange={(e, value) => {
            setOptionSelected("familia", value ? value.id : null);
          }}
          disabled={taxaSelected?.class && !taxaSelected?.order ? true : false}
        />
      </Stack>
    </>
  );
};
