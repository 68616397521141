/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { transformDataSpecies } from "./transformsData";
// import { validateYearRange } from "../../infrastructure/ui/validatorInputs";

import { usePagination } from "../../hooks/admin/usePagination";
import { useAdminSpecies } from "../../hooks";

const SpeciesContext = createContext();
const token = localStorage.getItem("token_raices");

const SpeciesAdminProvider = ({ children }) => {
  const { getParams, searchParams } = usePagination();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const hasSpecies = location.pathname.includes("especies");
  const id_especie = hasSpecies ? id : null;

  const {
    speciesList,
    getSpecieById,
    specie,
    //For crud
    saveOrEditSpecieData,
    deleteOrRecoverySpecie,
    getSpeciesListByFilter,
    changeStatus,
    // updateOrDeleteSound,
    // updateOrDeleteImgSpecie,
    // response,
    loading,
    message,
  } = useAdminSpecies();

  const [listSpecies, setListSpecies] = useState({
    data: [],
    totalItems: 0,
    totalPages: 0,
  });

  const dataDefault = {
    category: getParams("category", null),
    class: getParams("class", null),
    order: getParams("order", null),
    family: getParams("family", null),
    currentPage: getParams("page", 1),
    sizePage: getParams("size", 15),
    search: searchParams.get("search", ""),
  };

  const getListDataSpecies = () => {
    getSpeciesListByFilter(token, {
      taxonomyId: dataDefault.category,
      classId: dataDefault.class,
      orderId: dataDefault.order,
      familyId: dataDefault.family,
      search: dataDefault.search,
      page: dataDefault.currentPage,
      pageSize: dataDefault.sizePage,
    });
  };

  const getDataSpecie = async () => {
    if (id_especie) {
      await getSpecieById(token, id_especie);
    }
  };

  useEffect(() => {
    if (hasSpecies) {
      getListDataSpecies();
    }
  }, [searchParams]);

  useEffect(() => {
    if (speciesList?.species) {
      setListSpecies({
        ...listSpecies,
        data: transformDataSpecies(speciesList.species),
        totalItems: speciesList.totalItems,
        totalPages: speciesList.totalPages,
      });
    } else {
      setListSpecies({
        ...listSpecies,
        data: [],
        totalItems: 0,
        totalPages: 0,
      });
    }
  }, [speciesList]);

  useEffect(() => {
    if (id_especie) {
      getDataSpecie();
    }
  }, [id_especie]);

  const saveOrEditDataSpecie = async (data, id) => {
    await saveOrEditSpecieData(token, data, id);
    getListDataSpecies();
    if (id_especie) {
      navigate(`/admin/especies`);
    }
  };

  const changeStatusSpecie = async (id, value) => {
    await changeStatus(token, id, { ch_estado: value });
    getListDataSpecies();
  };

  const deleteOrRecovery = async (id, status) => {
    const isDelete = status === 0 ? false : true;
    await deleteOrRecoverySpecie(token, id, isDelete);
    getListDataSpecies();
  };

  // const addOrDeleteSound = async (id, file) => {
  //   const body = file ? { vc_sonido: file } : null;
  //   await updateOrDeleteSound(token, id, body);
  //   getSpecieById(token, id);
  // };

  // const addOrDeleteImg = async (id, file) => {
  //   console.log(file);
  //   const body = file ? { vc_imagen: file } : null;
  //   await updateOrDeleteImgSpecie(token, id, body);
  //   getDataSpecie();
  // };

  return (
    <SpeciesContext.Provider
      value={{
        dataDefault,
        getListDataSpecies,
        listSpecies,
        getDataSpecie,
        id_especie,
        specie,
        //For crud
        saveOrEditDataSpecie,
        changeStatusSpecie,
        deleteOrRecovery,
        // addOrDeleteSound,
        // addOrDeleteImg,
        //response
        loading,
        message,
      }}
    >
      {children}
    </SpeciesContext.Provider>
  );
};

export { SpeciesAdminProvider };
export const useLogicSpeciesAdmin = () => useContext(SpeciesContext);
