import React from "react";
import { Box } from "@mui/material";
import { TableListSection } from "../../../../../components/Admin/sectionsSearch/TableListSection";
import { useLogicCategorieContext } from "../../../../../context/admin/categoriesProvider";

export const ListSectionCategories = () => {
  const { listCategories, selecteCategory } = useLogicCategorieContext();

  const [search, setSearch] = React.useState("");

  return (
    <>
      <Box>
        <TableListSection
          dataDefault={{ search: search }}
          onChangeSearch={(value) => setSearch(value)}
          data={listCategories}
          isSimple={true}
          isPagination={false}
          onChangeEdit={(value) => selecteCategory(value)}
        />
      </Box>
    </>
  );
};
