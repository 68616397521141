import { AppBar } from "@mui/material";
import { AppBarHeader } from "./sections/AppBarHeader";
import { MenuBar } from "./sections/MenuBar";
import { useLocation } from "react-router-dom";

export const AppBarCuston = () => {
  const isHome = useLocation().pathname === "/";

  return (
    <AppBar
      position={isHome ? "fixed" : "sticky"}
      sx={{
        backgroundColor: isHome
          ? "rgb(143, 180, 66, 0.7)"
          : "rgb(143, 180, 66,0.9)",
        backdropFilter: "blur(10px)",
      }}
    >
      <AppBarHeader />
      <MenuBar />
    </AppBar>
  );
};
