import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListPhylumUSer = async () => {
  const apiUrl = `${API_BASE_URL}/phylum`;

  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getListClassUSer = async () => {
  const apiUrl = `${API_BASE_URL}/classes`;

  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getListOrderUSer = async () => {
  const apiUrl = `${API_BASE_URL}/orders`;

  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getListFamilyUSer = async () => {
  const apiUrl = `${API_BASE_URL}/families`;

  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};
