import React, { useState } from "react";
import {
  MenuItem,
  Typography,
  Menu,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { sIconsAppBar } from "../styles";

import useAuth from "../../../utils/hooks/useAuth";
import { Link} from "react-router-dom";

const settings = ["Profile", "Dashboard", "Logout"];

export default function LoginIcon() {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { auth } = useAuth();
  // console.log("auth", auth);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOption = (option) => {
    if (option === "Logout") {
      localStorage.removeItem("token_raices");
      window.location.reload();
    } else if (option === "Profile") {
      console.log("Profile");
    } else if (option === "Dashboard") {
      window.location.href = "/admin";
    }
    handleCloseUserMenu();
  };

  return (
    <>
      {auth && auth.id_usuario ? (
        <>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => handleOption(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Tooltip title="Iniciar sesión">
          <IconButton LinkComponent={Link} to={"/login"} sx={sIconsAppBar}>
            <AccountCircleRoundedIcon
              fontSize="small"
              sx={{
                color: "white",
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
