/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Grid,
  Divider,
  Box,
  IconButton,
  Tooltip,
  Avatar,
  Skeleton,
} from "@mui/material";
import { useState } from "react";
import { stylesDetail, styleDivider } from "../../styles";
import { stylesFont } from "../../../../../styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ModalConservation from "../../modalConservation";

const DetailSection = ({ species }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pb: 2,
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Divider sx={styleDivider} />
          <Typography sx={stylesDetail.titleSection}>
            Información Taxonómica
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          {species?.taxonomia ? (
            Object.entries(species?.taxonomia).map(([clave, valor], index) => (
              <Box key={index} sx={{ display: "flex" }} width={"100%"}>
                <Typography
                  sx={{
                    ...stylesFont.subTitleText,
                    mb: 1,
                    minWidth: { xs: "100px", sm: "110px" },
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  {`${clave}`}
                </Typography>

                <Typography
                  sx={{ ...stylesFont.text, display: "flex" }}
                  mb={1}
                >
                  {`: ${valor.vc_nombre}`}
                </Typography>
              </Box>
            ))
          ) : (
            <Skeleton width={200} />
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pb: 2,
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Divider sx={styleDivider} />
          <Typography sx={stylesDetail.titleSection}>
            Estado(s) de conservación
          </Typography>
        </Box>
        <Tooltip title={"Ver los estados de conservación"}>
          <IconButton onClick={() => handleOpenModal()}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          maxHeight: "220px",
        }}
      >
        {species?.estados_conservacion ? (
          <>
            {species?.estados_conservacion?.length > 0 ? (
              <>
                {species?.estados_conservacion?.map((item, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    mb={1}
                  >
                    <Avatar
                      src={item?.imagen_estado}
                      alt={item.vc_nombre}
                      sx={{
                        width: 50,
                        height: 50,
                        mr: 1,
                        borderRadius: "8px",
                      }}
                    />
                    <Typography variant="h6" sx={stylesFont.subTitleText}>
                      {item.vc_nombre} -({item.institucion})
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <Typography sx={stylesFont.text}>
                No hay datos de estado de conservación
              </Typography>
            )}
          </>
        ) : (
          <Box
            display={"flex"}
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
          >
            <Skeleton variant="circular" height={50} width={50} />
            <Skeleton variant="rectangular" height={20} width={100} />
          </Box>
        )}
      </Box>
      <ModalConservation open={openModal} onClose={handleOpenModal} />
    </>
  );
};

export default DetailSection;
