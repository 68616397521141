/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
// import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { transformDataCommunity } from "./transformsData";

import { useCommunities } from "../../hooks";
import { usePagination } from "../../hooks/admin/usePagination";

const CommunitiesAdminContext = createContext();
const token = localStorage.getItem("token_raices");

const CommunitiesAdminProvider = ({ children }) => {
  const navigate = useNavigate();
  const { getParams, searchParams } = usePagination();
  const { id } = useParams();
  const location = useLocation();
  const hasTowns = location.pathname.includes("towns");
  const id_community = hasTowns ? id : null;

  const {
    getCommunitiesData,
    communities,
    //For crud town
    deleteCommunityData,
    community,
    getDataCommunityById,
    editCommunityData,
    saveCommunityData,
    //response
    message,
    loading,
  } = useCommunities();

  const [listDataCommunities, setListDataCommunities] = useState({
    data: [],
    totalItems: 0,
    totalPages: 0,
  });

  const dataDefault = {
    currentPage: getParams("page", 1),
    sizePage: getParams("size", 15),
    search: searchParams.get("search", ""),
  };

  const getListData = () => {
    getCommunitiesData(
      token,
      dataDefault.search,
      dataDefault.currentPage,
      dataDefault.sizePage
    );
  };

  useEffect(() => {
    if (hasTowns) {
      getListData();
    }
  }, [searchParams]);

  useEffect(() => {
    if (communities?.pueblosIndigenas) {
      setListDataCommunities({
        ...listDataCommunities,
        data: transformDataCommunity(communities?.pueblosIndigenas),
        totalPages: communities?.totalPages,
        totalItems: communities?.totalItems,
      });
    } else {
      setListDataCommunities({
        ...listDataCommunities,
        data: [],
        totalPages: 0,
        totalItems: 0,
      });
    }
  }, [communities?.pueblosIndigenas]);

  useEffect(() => {
    if (id_community) {
      getDataCommunityById(id_community);
    }
  }, [id_community]);

  const deleteCommunity = async (id, body) => {
    await deleteCommunityData(token, id, body);
    getCommunitiesData(
      token,
      dataDefault.search,
      dataDefault.currentPage,
      dataDefault.sizePage
    );
  };

  const saveOrEditTown = async (id, body) => {
    if (id) {
      await editCommunityData(id, body);
      await getListData();
      navigate("/admin/towns");
    } else {
      await saveCommunityData(body);
      await getListData();
      navigate("/admin/towns");
    }
  };

  return (
    <CommunitiesAdminContext.Provider
      value={{
        id_community,
        dataDefault,
        getListData,
        listDataCommunities,
        community,
        //Crud town
        getDataCommunityById,
        saveOrEditTown,
        deleteCommunity,
        //response
        loading,
        message,
      }}
    >
      {children}
    </CommunitiesAdminContext.Provider>
  );
};

export { CommunitiesAdminProvider };
export const useLogicCommunitiesAdmin = () =>
  useContext(CommunitiesAdminContext);
