/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box } from "@mui/material";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const labels = ["Especies", "Autores", "Comunidades", "Estados"];

const ChartSection = ({ dataValues }) => {
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState({
    Especies: {
      total: 0,
      active: 0,
    },
    Comunidades: {
      total: 0,
      active: 0,
    },
    Autores: {
      total: 0,
      active: 0,
    },
  });

  const getData = async () => {
    setTotalData({
      Especies: {
        total: dataValues?.species?.totalSpecies,
        active: dataValues?.species?.publishedSpecies,
      },
      Comunidades: {
        total: dataValues?.communities?.totalCommunities,
        active: dataValues?.communities?.activeCommunities,
      },
      Autores: {
        total: dataValues?.authors?.totalAuthors,
        active: dataValues?.authors?.activeAuthors,
      },
      Estados: {
        total: dataValues?.conservation?.totalConservation,
        active: dataValues?.conservation?.activeConservation,
      },
    });
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [dataValues]);

  const data = {
    labels,
    datasets: [
      {
        label: "Totales",
        //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        data: loading
          ? labels.map(() => Math.floor(Math.random() * 1000))
          : labels.map((label) => totalData[label]?.total),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Activos",
        //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        data: loading
          ? labels.map(() => Math.floor(Math.random() * 1000))
          : labels.map((label) => totalData[label]?.active),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: 'top' as const,
      },
      title: {
        display: true,
        text: loading ? "Cargando..." : "Total de datos",
      },
    },
  };

  return (
    <Box paddingY={2}>
      <Box
        paddingY={2}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
          p: 2,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Bar
          data={data}
          options={options}
          //   style={{ maxHeight: "300px", maxWidth: "500px" }
          // style={{
          //     height: "320px",
          // }}
        />
      </Box>
    </Box>
  );
};

export default ChartSection;
