/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import { styleTitlePage } from "../styles";
import { TableListSection } from "../../../../components/Admin/sectionsSearch/TableListSection";

// import TabContext from "@mui/lab/TabContext";

import { usePagination } from "../../../../hooks/admin/usePagination";
import { useTaxasAdmin } from "../../../../hooks";
import { CrudTaxonomies } from "./sections/crudSection";

const taxonomies = [
  {
    id: 0,
    name: "Clases",
    api: "class",
    title: "clase",
    subTitle: "filo",
  },
  {
    id: 1,
    name: "Orden",
    api: "order",
    title: "orden",
    subTitle: "clase",
  },
  {
    id: 2,
    name: "Familia",
    api: "family",
    title: "familia",
    subTitle: "orden",
  },
];

const IndexTaxonomy = () => {
  const navigate = useNavigate();
  const [dataForTable, setDataForTable] = useState([]);
  const { getParams, updateUrlParam } = usePagination();
  const { getListTaxas, dataTaxas, loading, deleteOrRestoreTaxa } =
    useTaxasAdmin();
  const [value, setValue] = useState(0);

  const page = getParams("page", 1);
  const sizePage = getParams("size", 15);
  const search = getParams("query", "");

  const handleChange = async (event, newValue) => {
    updateUrlParam("page", 1);
    updateUrlParam("id", "");
    updateUrlParam("view", "");
    setValue(newValue);
  };

  useEffect(() => {
    getListTaxas(taxonomies[value].api, search, page, sizePage);
  }, [value, page, search, sizePage]);

  useEffect(() => {
    setDataForTable(dataTaxas);
  }, [dataTaxas]);

  const handleEdit = (id) => {
    navigate(`?id=${id}`);
  };

  const handleView = (id) => {
    navigate(`?view=${id}`);
  };

  const changeStatus = async (isConfirm, id, state) => {
    if (isConfirm) {
      await deleteOrRestoreTaxa(taxonomies[value].api, id, state);
      getListTaxas(taxonomies[value].api, search, page, sizePage);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
        <Typography sx={styleTitlePage}>Taxonomías</Typography>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
      >
        <Tab label="Clases" value={0} />
        <Tab label="Orden" value={1} />
        <Tab label="Familia" value={2} />
      </Tabs>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, bgcolor: "background.paper" }}>
            <CrudTaxonomies
              apikey={taxonomies[value].name}
              value={value}
              taxaSelected={taxonomies[value]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, bgcolor: "background.paper" }}>
            <TableListSection
              isSimple={true}
              data={dataForTable}
              loading={loading}
              dataDefault={{
                search: search,
                currentPage: page,
                sizePage: sizePage,
              }}
              handlePage={(page) => {
                updateUrlParam("page", page);
              }}
              handleRows={(size) => {
                updateUrlParam("size", size);
              }}
              onChangeSearch={(search) => {
                updateUrlParam("query", search);
              }}
              onChangeEdit={handleEdit}
              onChangeView={handleView}
              confirmDelete={(value, taxa) =>
                changeStatus(value, taxa.id, taxa.status)
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IndexTaxonomy;
