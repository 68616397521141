import { Alert, Box, Snackbar } from "@mui/material";

export const CustonSnackBar = ({ open, onClose, ...props }) => {
  return (
    <Box>
      {props.message && (
        <Snackbar
          {...props}
          key={"top"}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={onClose}
          autoHideDuration={4000}
        >
          <Alert onClose={onClose} severity={props.severity || "success"}>
            {props.message && props.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};
