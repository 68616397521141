import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Breadcrumbs,
  IconButton,
} from "@mui/material";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import { stylesFonts } from "../../../presentation/components/Administrador/styles";
import { useNavigate, Link } from "react-router-dom";

export const LayoutFrmAdd = ({ title, label, loading, children }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box py={2}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton
            sx={{ borderRadius: "0.5rem" }}
            component={Link}
            to="/admin"
            size="small"
          >
            <DashboardRoundedIcon sx={{ fontSize: 18 }} />
          </IconButton>
          <Button
            onClick={() => handleBack()}
            size="small"
            sx={{
              borderRadius: "0.5rem",
              textTransform: "capitalize",
              color: "GrayText",
              fontWeight: 700,
            }}
          >
            {label ? label : "Regresar"}
          </Button>
        </Breadcrumbs>
      </Box>
      <Box>
        <Typography sx={stylesFonts.titlePage}>{title}</Typography>
      </Box>
      <Box> {children}</Box>
      <Backdrop
        open={loading}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
