import { Box, Grid, Typography } from "@mui/material";
import { stylesFonts } from "../styles";
import { ListSectionCategories } from "./sections/listSection";
import { CrudSectionCategories } from "./sections/crudSection";
import { CategoriesAdminProvider } from "../../../../context/admin/categoriesProvider";

export const IndexCategories = () => {
  return (
    <>
      <CategoriesAdminProvider>
        <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
          <Typography sx={stylesFonts.titlePage}>Categorias</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CrudSectionCategories />
          </Grid>
          <Grid item xs={12} md={6}>
            <ListSectionCategories />
          </Grid>
        </Grid>
      </CategoriesAdminProvider>
    </>
  );
};
