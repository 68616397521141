import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getListFamilies = async (token, search, currentPage, sizePage) => {
  const apiUrl = search
    ? `${API_BASE_URL}/families/admin/search/${search}/${currentPage}/${sizePage}`
    : `${API_BASE_URL}/families/admin/${currentPage}/${sizePage}`;

  try {
    const response = await axios.get(apiUrl, axiosConfig(token));

    return response;
  } catch (error) {
    return error;
  }
};

export const getFamilyById = async (token, id) => {
  const response = await axios.get(
    `${API_BASE_URL}/families/admin/${id}`,
    axiosConfig(token)
  );

  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const saveOrEditFamily = async (token, id, body) => {
  const apiUrl = id
    ? `${API_BASE_URL}/families/edit/${id}`
    : `${API_BASE_URL}/families/create`;
  const method = id ? "patch" : "post";
  const response = await axios[method](apiUrl, body, axiosConfig(token));
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteFamily = async (token, id, body) => {
  const response = await axios.delete(`${API_BASE_URL}/families/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: body,
  });
  try {
    return response;
  } catch (error) {
    return error;
  }
};
