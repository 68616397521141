import React from "react";
import LayoutSpecies from "../layoutSpecies";
import {
  Grid,
  Container,
  Box,
  Drawer,
  useMediaQuery,
  Divider,
} from "@mui/material";

import { styles } from "../styles";

import ConsultSpeciesSection from "./sections/consultSpeciesSection";
import ListSpeciesSection from "./sections/listSpeciesSection";

import { HeaderFilter } from "./sections/headerFilter";

const IndexListSpecie = () => {
  const [open, setOpen] = React.useState(false);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const filterAndSearch = (
    <Box style={styles.leftColumn}>
      <ConsultSpeciesSection />
    </Box>
  );

  return (
    <LayoutSpecies title={"Especies"} currentPage={"Consulta de especies"}>
      <Box sx={styles.styleSticky}>
        <Container maxWidth="xl">
          <HeaderFilter open={open} setOpen={setOpen} />
        </Container>
        <Divider sx={{ paddingBottom: 2 }} />
      </Box>
      <Container maxWidth="xl" sx={{ paddingBottom: { xs: 0, sm: 2, md: 4 } }}>
        <Grid container spacing={4}>
          {isSmallScreen ? (
            <>
              <Drawer open={open} anchor="top">
                <Box p={1}>
                  <HeaderFilter open={open} setOpen={setOpen} />
                  <ConsultSpeciesSection />
                </Box>
              </Drawer>
            </>
          ) : (
            <Grid item xs={12} md={3} lg={2.5}>
              {filterAndSearch}
            </Grid>
          )}
          <Grid item xs={12} md={9} lg={9.5}>
            <ListSpeciesSection />
          </Grid>
        </Grid>
      </Container>
    </LayoutSpecies>
  );
};

export default IndexListSpecie;
