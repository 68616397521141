import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ButtonCustom = ({ label, color, to, ...props }) => {
  return (
    <Button
      variant="contained"
      LinkComponent={Link}
      to={to}
      color={color}
      sx={{
        // fontFamily: "poppins",
        fontWeight: 700,
        fontSize: 14,
        textTransform: "none",
        borderRadius: "0.5rem",
        boxShadow: "none",
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ButtonCustom;
