/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";

import { useSpecieConservation } from "../../hooks";
import { useLogicSpeciesAdmin } from "./speciesProvider";

const ConservationSpecieContext = createContext();
const token = localStorage.getItem("token_raices");

const transformData = (data) => {
  if (data) {
    return data.map((item) => {
      return {
        id: item.id_est_conservacion,
        name: item.vc_nombre + " (" + item.institucion + ")",
        key: item.id_est_conservacion,
        status: parseInt(item.estado),
        image: item.imagen_estado,
      };
    });
  } else {
    return [];
  }
};

const SpecieConservationProviderAdmin = ({ children }) => {
  const { getDataSpecie } = useLogicSpeciesAdmin();
  const {
    getConservationsNotInSpecieData,
    dataListConservations,
    addOrDeleteConservationToSpecie,
    //response
    loading,
    message,
  } = useSpecieConservation();
  const [listConservation, setListConservation] = useState({
    data: [],
    totalItems: 0,
    totalPages: 0,
  });

  const [dataDefault, setDataDefault] = useState({
    currentPage: 1,
    sizePage: 15,
    search: "",
  });

  const getListDataConservation = (id) => {
    getConservationsNotInSpecieData(token, id, {
      page: dataDefault.currentPage,
      pageSize: dataDefault.sizePage,
      search: dataDefault.search,
    });
  };
  useEffect(() => {
    if (dataListConservations) {
      setListConservation({
        data: transformData(dataListConservations.statusData),
        totalItems: dataListConservations.totalItems,
        totalPages: dataListConservations.totalPages,
      });
    }
  }, [dataListConservations]);

  const addOrDeleteConservation = async (isDelete, data) => {
    const { id_especie } = data;
    await addOrDeleteConservationToSpecie(token, isDelete, data);
    await await getConservationsNotInSpecieData(token, id_especie, {
      page: 1,
      pageSize: 15,
      search: "",
    });
    getDataSpecie(token, id_especie);
  };

  return (
    <ConservationSpecieContext.Provider
      value={{
        loading,
        listConservation,
        dataDefault,
        setDataDefault,
        getListDataConservation,
        addOrDeleteConservation,
        message,
      }}
    >
      {children}
    </ConservationSpecieContext.Provider>
  );
};

export { SpecieConservationProviderAdmin };
export const useLogicConservationSpecie = () =>
  useContext(ConservationSpecieContext);
