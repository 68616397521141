import React from "react";

import {
  CarouselSection,
  AboutSection,
  ChartsSection,
  CommunitySection,
  MovilSection,
} from "../components/HomePage/sections";

const HomePage = () => {
  return (
    <>
      <CarouselSection />
      <AboutSection />
      <ChartsSection />
      <MovilSection />
      <CommunitySection />
    </>
  );
};

export default HomePage;
