import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { DialogActionsTable } from "./DialogActionsTable";

export const ActionsRowTable = ({
  data,
  onChangeView,
  onChangeEdit,
  confirmDelete,
}) => {
  const [open, setOpen] = React.useState(false);
  const { id, status } = data;

  const changeDialog = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip title="Ver detalles">
        <IconButton onClick={() => onChangeView(id)}>
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Editar">
        <IconButton color="primary" onClick={() => onChangeEdit(id)}>
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Eliminar">
        <IconButton
          color={status === 0 ? "success" : "error"}
          onClick={() => {
            changeDialog();
          }}
        >
          {status === 0 ? <ReplayRoundedIcon /> : <DeleteOutlineOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <DialogActionsTable
        open={open}
        onClose={() => changeDialog()}
        confirmValue={(value) => confirmDelete(value, { id, status })}
      />
    </>
  );
};
