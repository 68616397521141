import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  IconButton,
  Skeleton,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { CustonDialogAdmin } from "../DialogCuston";

const styles = {
  card: {
    position: "relative", // Añadido para posicionar correctamente los iconos
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "3px dashed #c4c4c4",
    borderRadius: "0.5rem",
    boxShadow: "none",
    p: 1,
  },
  cardActionArea: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.5rem",
    alignItems: "center",
    cursor: "pointer", // Hacer que el área sea clickeable
    position: "relative", // Añadido para posicionar correctamente los iconos
  },
  iconButtonContainer: {
    position: "absolute",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.3)", // Añadido para un fondo semi-transparente
    transition: "opacity 0.7s", // Agregado para una transición suave
  },
  iconButton: {
    borderRadius: "0.5rem",
    border: "2px solid #fff",
    color: "#fff",
    margin: "0.5rem",
  },
};

export const UpLoadImage = ({ data, handleDelete, handleUpLoad, ...props }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(data?.image || null);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const dropAreaRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      handleImageUpload(file);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const currentDropArea = dropAreaRef.current;

    if (currentDropArea) {
      currentDropArea.addEventListener("drop", handleDrop);
      currentDropArea.addEventListener("dragover", handleDragOver);
    }

    return () => {
      if (currentDropArea) {
        currentDropArea.removeEventListener("drop", handleDrop);
        currentDropArea.removeEventListener("dragover", handleDragOver);
      }
    };
  }, []);

  useEffect(() => {
    if (data?.image) {
      setImage(data.image);
    } else {
      setImage(null);
    }
  }, [data]);

  const handleDialog = () => setOpen(!open);

  const handleImageUpload = (file) => {
    if (file) {
      setSelectedFile(file); // Almacena el archivo seleccionado en el estado
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCardClick = () => {
    if (!image) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleImageUpload(file);
  };

  const removeImage = () => {
    if (!data?.image) {
      setImage(null);
      setSelectedFile(null);
    } else {
      handleDialog();
      setSelectedFile(null);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box>
      <Card
        sx={{
          ...styles.card,
          width: props.width || "100%",
          maxWidth: props.maxWidth || "100%",
          // height: props.height || "100%",
        }}
        onMouseEnter={handleMouseEnter}
        ref={dropAreaRef}
      >
        <CardActionArea
          sx={styles.cardActionArea}
          onClick={handleCardClick}
          onMouseLeave={handleMouseLeave}
        >
          {image ? (
            <>
              {isLoaded === false && (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    // height: props.height
                    //   ? props.height
                    //   : { xs: "20rem", lg: "22rem" },
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              <Box
                component={"img"}
                src={image}
                alt="Imagen"
                sx={{
                  // width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  display: isLoaded ? "block" : "none",
                }}
                onLoad={() => setIsLoaded(true)}
              />
              {isHovered && (
                <Box sx={styles.iconButtonContainer}>
                  <IconButton
                    sx={styles.iconButton}
                    onClick={removeImage}
                    color="secondary"
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                  <IconButton
                    sx={styles.iconButton}
                    // Agrega aquí la lógica para mostrar la vista previa
                  >
                    <RemoveRedEyeRoundedIcon />
                  </IconButton>
                </Box>
              )}
            </>
          ) : (
            <AddPhotoAlternateIcon
              sx={{
                fontSize: "5rem",
                color: "#c4c4c4",
              }}
            />
          )}
        </CardActionArea>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </Card>

      <Box
        mt={2}
        sx={{
          display: data?.image ? "none" : "block",
        }}
      >
        <Button
          onClick={() => (handleUpLoad ? handleUpLoad(selectedFile) : {})}
          disabled={data?.image || selectedFile ? false : true}
          fullWidth
        >
          Guardar imagen
        </Button>
      </Box>
      <CustonDialogAdmin
        title={"Eliminar imagen"}
        message={"¿Está seguro que desea eliminar la imagen?"}
        isDelete={true}
        open={open}
        onClose={handleDialog}
        handleAction={() => {
          handleDelete();
          handleDialog();
        }}
      />
    </Box>
  );
};
