/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Typography, Box, TextField } from "@mui/material";
import { titleSection } from "../../styles";
import { useLogicConservationAdmin } from "../../../../../context/admin/conservationProvider";

const SectionDetails = ({ conservation, handleValues }) => {
  const { id_conservation } = useLogicConservationAdmin();
  const [formData, setFormData] = useState({
    vc_nombre: "",
    vc_iniciales: "",
    vc_institucion: "",
    te_descripcion: "",
  });

  useEffect(() => {
    if (id_conservation) {
      setFormData({
        vc_nombre: conservation?.vc_nombre || "",
        vc_iniciales: conservation?.vc_iniciales || "",
        vc_institucion: conservation?.vc_institucion || "",
        te_descripcion: conservation?.te_descripcion || "",
      });
    }
  }, [conservation, id_conservation]);

  useEffect(() => {
    handleValues(formData);
  }, [formData]);

  return (
    <>
      <Typography sx={titleSection}>
        Detalles del estado de conservación
      </Typography>
      <Box>
        <TextField
          fullWidth
          margin="dense"
          label={"Nombre"}
          placeholder={"Escribe el nombre..."}
          value={formData.vc_nombre}
          onChange={(e) =>
            setFormData({ ...formData, vc_nombre: e.target.value })
          }
        />
        <Box sx={{ display: { sm: "block", md: "flex" }, gap: 2 }}>
          <TextField
            fullWidth
            margin="dense"
            label={"Iniciales"}
            placeholder={"Escribe las iniciales..."}
            value={formData.vc_iniciales}
            onChange={(e) =>
              setFormData({ ...formData, vc_iniciales: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label={"Institución"}
            placeholder={"Escribe la institución..."}
            value={formData.vc_institucion}
            onChange={(e) =>
              setFormData({ ...formData, vc_institucion: e.target.value })
            }
          />
        </Box>
        <TextField
          fullWidth
          margin="dense"
          label={"Descripción"}
          placeholder={"Escribe la descripción..."}
          value={formData.te_descripcion}
          onChange={(e) =>
            setFormData({ ...formData, te_descripcion: e.target.value })
          }
          multiline
          rows={4}
        />
      </Box>
    </>
  );
};

export default SectionDetails;
