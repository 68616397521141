import { useState } from "react";
import {
  getCategories,
  getCategory,
  saveOrEditCategory,
  deleteCategory,
} from "../../infrastructure/api/admin/categories";

export const useCategoriesAdmin = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});
  const [error, setError] = useState(false);

  const handleApiError = (error) => {
    if (error.response) {
      setError(true);
      setResponse({
        status: error.response.status,
        message: error.response.data.message || "Error de servidor",
      });
    } else {
      setError(true);
      setResponse({
        status: 500,
        message: "Error de conexión al servidor",
      });
    }
  };

  const getCategoriesData = async (token, search) => {
    setLoading(true);

    try {
      const response = await getCategories(token, search);

      if (response && response.status === 200) {
        setCategories(response.data);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataCategoryById = async (token, id) => {
    setLoading(true);
    try {
      const response = await getCategory(token, id);
      if (response && response.status === 200) {
        setCategory(response.data);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveOrEditCategoryData = async (token, data) => {
    setLoading(true);
    try {
      const response = await saveOrEditCategory(token, data);
      if (response && response.status === 200) {
        setResponse(response.data);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCategoryData = async (token, id) => {
    setLoading(true);
    try {
      const response = await deleteCategory(token, id);
      if (response && response.status === 200) {
        setResponse(response.data);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    categories,
    category,
    getCategoriesData,
    getDataCategoryById,
    //For crud
    saveOrEditCategoryData,
    deleteCategoryData,
    //For response,
    loading,
    response,
    error,
  };
};
