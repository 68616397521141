/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { SectionSearch } from "../../../../../components";

import { useNavigate } from "react-router-dom";

import { useLogicCommunity } from "../../../../../context/client/comunitiesProvider";
import { CardDetails } from "../../../../../components";

export default function ListSection() {
  const { dataBody, listCommunities, updateUrlParam, loading } =
    useLogicCommunity();

  const navigate = useNavigate();

  const handlePage = (e, newPage) => {
    updateUrlParam("page", newPage);
  };

  const handleItems = (value) => {
    updateUrlParam("size", value);
  };

  const handleDetail = (id) => {
    navigate(`/community/${id}`);
  };

  return (
    <>
      <SectionSearch
        title={"Comunidades indígenas"}
        totalPages={listCommunities?.totalPages}
        onChangeSearch={(value) => {
          updateUrlParam("search", value);
        }}
        onChangePage={(e, value) => handlePage(e, value)}
        onChangeItems={handleItems}
        loading={loading}
        defaultValues={{
          search: dataBody?.search,
          currentPage: dataBody?.page,
          pageSize: dataBody?.size,
        }}
      >
        <Grid container spacing={2}>
          {listCommunities?.pueblosIndigenas?.length > 0 ? (
            listCommunities?.pueblosIndigenas?.map((item, index) => (
              <Grid item xs={6} sm={4} md={3} key={index}>
                <CardDetails
                  data={{
                    id: item.id_pueblo_indigena,
                    title: item.vc_nombre,
                    subtitle: item.vc_descripcion,
                    tag: item.vc_nombre,
                    image: item.vc_image,
                  }}
                  height={220}
                  onClick={() => handleDetail(item.id_pueblo_indigena)}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="subtitle1">No hay resultados</Typography>
            </Grid>
          )}
        </Grid>
      </SectionSearch>
    </>
  );
}
