import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  Box,
  Avatar,
  Skeleton,
  TablePagination,
  CircularProgress,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { formatDate } from "../../../infrastructure/ui/formatData";
import { ActionsRowTable } from "./ActionsTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(248, 249, 250)",
    color: "rgb(47, 55, 70)",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const styleSelect = {
  fontWeight: 900,
  fontSize: 14,
  borderRadius: "0.5rem",
  "& .MuiSelect-select": {
    borderRadius: "0.5rem",
    border: "2px solid",
    padding: "8px 16px",
    ": hover": {
      // border: "2px solid",
    },
  },
};

const headRowDefault = [
  "#",
  "Imagen",
  "Nombre",
  "N. científico",
  "Modificación",
  "Estado",
  "Acciones",
];

const optionsStatus = [
  { value: 2, label: "Publicado", color: "success.main", chip: "Desactivar" },
  { value: 1, label: "No Publicado", color: "warning.main", chip: "Publicar" },
];

export const TableListSpecie = ({ loading, data, dataDefault, ...props }) => {
  return (
    <>
      <TableContainer
        component="div"
        sx={{
          borderRadius: 2,
          minHeight: 500,
          maxHeight: "calc(100vh - 200px)",
          bgcolor: "#ffffff",
          // maxHeight: 500,
        }}
      >
        <Table aria-label="customized table" stickyHeader>
          {
            <TableHead>
              <TableRow>
                {headRowDefault?.map((item, index) => (
                  <StyledTableCell key={index}>{item}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={headRowDefault?.length}
                  align="center"
                  height={300}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <>
                {data?.data?.length > 0 ? (
                  data?.data?.map((item, index) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell component="th" scope="row">
                        {item.id}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.image ? (
                          <Avatar
                            alt="Remy Sharp"
                            src={item.image}
                            sx={{
                              width: 42,
                              height: 42,
                              backgroundColor: "#ffffff",
                            }}
                          />
                        ) : (
                          <Skeleton variant="circular" width={42} height={42} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left" width={100}>
                        {item.name ? item.name : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left" width={210}>
                        {item.last_name ? item.last_name : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left" width={230}>
                        {item.date
                          ? formatDate(item.date)
                          : "-- / -- / --  -- : --"}
                      </StyledTableCell>
                      <StyledTableCell align="left" width={100}>
                        {item?.status === 0 ? (
                          <Chip
                            label="Eliminado"
                            color="error"
                            sx={{
                              borderRadius: "0.5rem",
                              fontWeight: 900,
                            }}
                          />
                        ) : (
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            value={item?.status}
                            onChange={(e) =>
                              props.onChangeStatus(item.id, e.target.value)
                            }
                            sx={{
                              ...styleSelect,
                              color:
                                item.status === 2
                                  ? "success.main"
                                  : "warning.main",
                            }}
                          >
                            {optionsStatus?.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <ActionsRowTable
                          data={item}
                          confirmDelete={(value, data) =>
                            props.confirmDelete(value, data)
                          }
                          onChangeView={props.onChangeView}
                          onChangeEdit={props.onChangeEdit}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={6} align="center">
                      No hay datos
                    </StyledTableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={"div"}
        rowsPerPageOptions={[15, 20, 30, 50]}
        count={data?.totalItems ? data.totalItems : 0}
        rowsPerPage={dataDefault ? parseInt(dataDefault.sizePage) : 15}
        page={dataDefault ? dataDefault.currentPage - 1 : 0}
        onPageChange={(e, page) => props.handlePage(page + 1)}
        onRowsPerPageChange={(e) => props.handleRows(e.target.value)}
      />
    </>
  );
};
