import React, { useRef } from "react";

const AudioPlayer = ({ audioUrl }) => {
  const audioRef = useRef(null);

  return (
    <div style={{ width: "100%" }}>
      <audio ref={audioRef} controls style={{ width: "100%" }}>
        <source
          src={audioUrl}
          type="audio/ogg"
          style={{ borderRadius: "8px", border: "1px solid #e0e0e0" }}
        />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
