import React from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Stack,
  Badge,
  useMediaQuery,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import SearchInputSection from "../../../../../components/SearchInputSection";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { styles } from "../../styles";
import { stylesComponents } from "../../../../styles/stylesComponents";

import { useLogicSpecies } from "../../../../../context/client/speciesProvider";
import { dowlonadFileForExcel } from "../../../../../infrastructure/ui/functionsProvider";
import { getListSpeciesByFilter } from "../../../../../infrastructure/api/client/species";

const countFiltersActive = (obj) => {
  return Object.values(obj).filter((value) => value !== "").length;
};

export const HeaderFilter = ({ open, setOpen }) => {
  const [searchParams] = useSearchParams();
  const [loadingDownload, setLoadingDownload] = React.useState(false);

  const textSearch = searchParams.get("search");
  const {
    dataSpecies,
    listDataSpecies,
    filtersApplied,
    loading,
    updateUrlParam,
  } = useLogicSpecies();
  const countFilters = countFiltersActive(filtersApplied);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const buttonTitleFilter = (
    <>
      {isSmallScreen ? (
        <Badge badgeContent={countFilters} color="primary">
          <IconButton onClick={() => handleDrawerOpen()}>
            <TuneOutlinedIcon color="dark.main" />
          </IconButton>
        </Badge>
      ) : (
        <Badge badgeContent={countFilters} color="primary">
          <TuneOutlinedIcon />
        </Badge>
      )}
    </>
  );

  const handleDownloadExcel = async () => {
    setLoadingDownload(true);
    const data = await getListSpeciesByFilter({
      ...dataSpecies,
      page: 1,
      pageSize: listDataSpecies?.totalItems,
    });
    dowlonadFileForExcel(data?.data?.species, "listado_especies");
    setLoadingDownload(false);
  };

  const titleFilter = (
    <Stack
      spacing={1}
      direction={"row"}
      paddingTop={1}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: { md: 1 } }}>
        {buttonTitleFilter}
        <Typography sx={styles.TitleSection}>FILTROS</Typography>
      </Box>
      {open && (
        <IconButton onClick={() => handleDrawerOpen()}>
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
  return (
    <>
      <Grid container spacing={{ xs: 1, md: 4 }}>
        <Grid item xs={12} sm={!open ? 4 : 12} md={3} lg={2.5}>
          {titleFilter}
        </Grid>
        {!open && (
          <Grid item xs={12} sm={8} md={9} lg={9.5}>
            <Box
              sx={{
                display: "flex",
                gap: { xs: 1, md: 2 },
              }}
            >
              <SearchInputSection
                fullWidth
                defaultValue={textSearch}
                onResult={(value) => updateUrlParam("search", value)}
                loading={loading}
              />
              {isSmallScreen ? (
                <IconButton
                  color="primary"
                  onClick={() => handleDownloadExcel()}
                  variant="outlined"
                  size="small"
                  sx={{
                    borderRadius: 2,
                    border: "2px solid ",
                  }}
                >
                  {loadingDownload ? (
                    <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                  ) : (
                    <FileDownloadOutlinedIcon />
                  )}
                </IconButton>
              ) : (
                <Tooltip title={"Descargar lista de resultados en excel"}>
                  <Button
                    sx={stylesComponents.buttonOutlined}
                    variant="outlined"
                    size="small"
                    onClick={handleDownloadExcel}
                    startIcon={
                      loadingDownload ? (
                        <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                      ) : (
                        <FileDownloadOutlinedIcon sx={{ marginLeft: 1 }} />
                      )
                    }
                  >
                    <Typography
                      marginRight={1}
                      variant="caption"
                      fontWeight={900}
                    >
                      Descargar
                    </Typography>
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
