import React from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "80%", md: "60%", lg: "50%" },
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const ImgModalZoom = ({ src, alt, ...props }) => {
  return (
    <>
      <Modal
        {...props}
        onClose={props.onClose}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open} timeout={500} unmountOnExit>
          <Box sx={styleBox}>
            <IconButton
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
                zIndex: "1",
                borderRadius: "0.5rem",
                border: "1px solid #e0e0e0",
                m: 1,
              }}
              onClick={props.onClose}
            >
              <Close />
            </IconButton>
            <Box
              id="zoom"
              component={"img"}
              src={src}
              alt={alt}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
