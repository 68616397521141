import { Button, Typography } from "@mui/material";
import { useLogicMultimedia } from "../../../../../context/client/multimediaProvider";
import { stylesComponents } from "../../../../styles/stylesComponents";

export const FiltersMultimedia = () => {
  const { selectedMultimedia, setSelectedMultimedia } = useLogicMultimedia();

  const options = [
    { value: 2, label: "Todos" },
    { value: 1, label: "Con sonido" },
    { value: 0, label: "Sin sonido" },
  ];

  return (
    <>
      {options.map((option) => (
        <Button
          key={option.value}
          disableElevation
          name="multimedia"
          variant={
            selectedMultimedia === option.value ? "contained" : "outlined"
          }
          size="small"
          sx={stylesComponents.buttonSolid}
          onClick={(e) => setSelectedMultimedia(e, option.value)}
        >
          <Typography fontWeight={900} variant="caption">
            {option.label}
          </Typography>
        </Button>
      ))}
    </>
  );
};
