import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListAuthor = async (currentPage, sizeItems, search = "") => {
  const apiUrl = search
    ? `${API_BASE_URL}/authors/search/${search}/${currentPage}/${sizeItems}`
    : `${API_BASE_URL}/authors/${currentPage}/${sizeItems}`;
  const response = await axios
    .get(apiUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("List Author not found");
        return null;
      }

      throw err;
    });
  return response;
};

export const getAuthorById = async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}/authors/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Author not found");
        return null;
      }

      throw err;
    });
  return response;
};
