import { useState } from "react";
import {
  getListCommunitiesForPagination,
  GetCommunityById,
} from "../../infrastructure/api/client/communities";

export const useCommunity = () => {
  const [community, setCommunity] = useState({});
  const [listCommunities, setListCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getCommunities = async (currentPage, sizePage, search) => {
    const response = await getListCommunitiesForPagination(
      currentPage,
      sizePage,
      search
    ).then((res) => res);
    if (response) {
      setListCommunities(response?.data);
      setLoading(false);
    } else {
      setListCommunities([]);
      setError(true);
      setLoading(false);
    }
  };

  const getCommunityById = async (id) => {
    const response = await GetCommunityById(id).then((res) => res);

    if (response.status === 200) {
      setCommunity(response.data);
      setLoading(false);
    } else {
      setCommunity({});
      setError(true);
      setLoading(false);
    }
  };

  return {
    getCommunityById,
    getCommunities,
    listCommunities,
    community,
    error,
    loading,
  };
};
