import { useEffect, useState } from "react";
import {
  getListSpecies,
  getListSpeciesByFilter,
  getDataSpecieById,
  changeStatusSpecies,
  saveOrEditSpecie,
  deleteSpecies,
  recoverySpecie,
  addUpdateSound,
  deleteSound,
  updateOrDeleteImg,
} from "../../api";

export const useAdminSpecies = () => {
  const [speciesList, setSpeciesList] = useState([]);
  const [specie, setSpecie] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const handleResponse = (response, successMessage, errorMessage) => {
    setLoading(false);
    if (response && response.status === 200) {
      setMessage({
        ...message,
        status: response.status,
        message: successMessage,
      });
    } else {
      setMessage({
        ...message,
        status: response.status,
        message: errorMessage,
      });
    }
  };

  const getSpeciesList = async (token, search, page, size) => {
    setLoading(true);
    const response = await getListSpecies(token, search, page, size);
    if (response && response.status === 200) {
      setSpeciesList(response.data);
    } else {
      setSpeciesList([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const getSpeciesListByFilter = async (token, filter) => {
    setLoading(true);
    const response = await getListSpeciesByFilter(token, filter);
    if (response && response.status === 200) {
      setSpeciesList(response.data);
    } else {
      setSpeciesList([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const getSpecieById = async (token, id) => {
    setLoading(true);
    const response = await getDataSpecieById(token, id);
    if (response && response.status === 200) {
      setSpecie(response.data);
    } else {
      setSpecie({});
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const saveOrEditSpecieData = async (token, data, id) => {
    setLoading(true);
    const response = await saveOrEditSpecie(token, data, id);
    handleResponse(
      response,
      "Datos guardados correctamente",
      "Error al guardar los datos"
    );
  };

  const changeStatus = async (token, id, data) => {
    setLoading(true);
    const response = await changeStatusSpecies(token, id, data);
    handleResponse(
      response,
      "Estado actualizado correctamente",
      "Error al actualizar el estado"
    );
  };

  const deleteOrRecoverySpecie = async (token, id, isDelete) => {
    setLoading(true);
    const apiUrl = isDelete ? deleteSpecies : recoverySpecie;
    const response = await apiUrl(token, id);
    handleResponse(
      response,
      "Especie eliminada correctamente",
      "Error al eliminar la especie"
    );
  };

  const updateOrDeleteSound = async (token, id, data) => {
    setLoading(true);
    const apiUrl = data ? addUpdateSound : deleteSound;
    const response = await apiUrl(token, id, data);
    handleResponse(
      response,
      "Sonido actualizado correctamente",
      "Error al actualizar el sonido"
    );
  };

  const updateOrDeleteImgSpecie = async (token, id, data) => {
    setLoading(true);
    const response = await updateOrDeleteImg(token, id, data);
    handleResponse(
      response,
      "Imagen actualizada correctamente",
      "Error al actualizar la imagen"
    );
  };

  return {
    // Data
    speciesList,
    specie,
    getSpeciesListByFilter,
    getSpeciesList,
    //For crud
    getSpecieById,
    changeStatus,
    saveOrEditSpecieData,
    deleteOrRecoverySpecie,
    updateOrDeleteSound,
    updateOrDeleteImgSpecie,
    //Response
    loading,
    message,
  };
};
