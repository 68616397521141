/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import SectionIndicator from "./SectionIndicator";
// import { useSpecies } from "../../../../hooks/admin/useSpecies";
// import SectionSpecieRecent from "./SectionSpecieRecent";

// import useSpecies from "../../../../utils/hooks/useSpecies";
import SectionTaxonomia from "./SectionTaxonomia";
import ChartSection from "./ChartSection";

//new hooks
import { useGeneralCounter } from "../../../../hooks/admin/useGeneralCounter";

function Dashboard() {
  const { getGeneralCounterData, generalCounter, loading } =
    useGeneralCounter();
  // const {getSpeciesList} = useSpecies();
  // const { speciesList } = useSpecies();

  useEffect(() => {
    getGeneralCounterData();
  }, []);

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <SectionIndicator data={generalCounter} loading={loading} />
          <ChartSection dataValues={generalCounter} />
          {/* <SectionSpecieRecent specie={speciesList} /> */}
        </Grid>
        <Grid item xs={12} md={3}>
          <SectionTaxonomia />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
