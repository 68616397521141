/* eslint-disable react-hooks/exhaustive-deps */
import LayoutSpecies from "../layoutSpecies";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import InfoSpecieSection from "./sections/infoSpecieSection";
import { Box, Breadcrumbs, Container, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { stylesSections } from "../../../../styles";
import HomeIcon from "@mui/icons-material/Home";

import { useSpecies } from "../../../../hooks/clients/useSpecies";

const IndexDetailSpecie = () => {
  const { id } = useParams();
  const { error, getDetailSpecie, detailSpecie } = useSpecies();
  const navigate = useNavigate();
  useEffect(() => {
    getDetailSpecie(id);
  }, [id]);

  if (error) {
    navigate("/*");
    return null;
  }

  return (
    <>
      <LayoutSpecies title={"Detalle de especie"} currentPage={"Detalles"}>
        <Box sx={{ ...stylesSections.sectionGeneral, paddingY: 3 }}>
          <Container maxWidth="lg">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                <HomeIcon fontSize="inherit" />
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/species"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Lista de especies
              </Link>
            </Breadcrumbs>
            <InfoSpecieSection specie={detailSpecie} />
          </Container>
        </Box>
      </LayoutSpecies>
    </>
  );
};

export default IndexDetailSpecie;
