export function validateInput(input, type) {
  switch (type) {
    case "text":
      return validateText(input);
    case "number":
      return validateNumber(input);
    case "email":
      return validateEmail(input);
    case "password":
      return validatePassword(input);
    case "phone":
      return validatePhone(input);
    case "notNull":
      return notNullInput(input);
    case "onlyLetters":
      return onlyLetters(input);
    case "numberExactLength":
      return validateNumberExactLength(input, 4);
    default:
      return false;
  }
}

export function notNullInput(input) {
  if (!input) {
    return {
      valid: false,
      message: "Este campo no puede estar vacío",
    };
  }
  return { valid: true, message: "" };
}

export function validateText(text) {
  const soLetters = /^[A-Za-z\s]+$/;
  if (!soLetters.test(text)) {
    return {
      valid: false,
      message: "Ingresa un nombre válido (solo letras)",
    };
  }
  return { valid: true, message: "" };
}

export function validateNumber(number) {
  const soNumbers = /^(\d{4})?$/;
  if (!soNumbers.test(number)) {
    return {
      valid: false,
      message: "Ingresa un número válido de 4 dígitos",
    };
  }
  return { valid: true, message: "" };
}

export function validateEmail(email) {
  const soEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!soEmail.test(email)) {
    return {
      valid: false,
      message: "Ingresa una dirección de correo electrónico válida",
    };
  }
  return { valid: true, message: "" };
}

export function validatePassword(password) {
  const soPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return soPassword.test(password);
}

export function validatePhone(phone) {
  const soPhone = /^\d{9}$/;
  if (!soPhone.test(phone)) {
    return {
      valid: false,
      message: "Ingresa un número de teléfono válido",
    };
  }
  return { valid: true, message: "" };
}

export function onlyLetters(input) {
  if (/\s/.test(input)) {
    return {
      valid: false,
      message: "Este campo no debe contener espacios",
    };
  }
  const regex = /^[A-Za-z]+$/;
  if (!regex.test(input)) {
    return {
      valid: false,
      message: "Este campo solo puede contener letras",
    };
  }
  return { valid: true, message: "" };
}

export function validateNumberExactLength(number, length) {
  const parsedNumber = parseInt(number, 10);
  if (!isNaN(parsedNumber) && parsedNumber >= 1000 && parsedNumber <= 9999) {
    return { valid: true, message: "" };
  } else {
    return {
      valid: false,
      message: `Ingresa un número válido de ${length} dígitos`,
    };
  }
}

export function validateYearRange(yearOfBirth, yearOfDeath) {
  // const yearOfBirthValid = validateNumber(yearOfBirth);
  // const yearOfDeathValid = validateNumber(yearOfDeath);

  // if (!yearOfBirthValid.valid) {
  //   return yearOfBirthValid;
  // }

  // if (!yearOfDeathValid.valid) {
  //   return yearOfDeathValid;
  // }

  const parsedYearOfBirth = parseInt(yearOfBirth, 10);
  const parsedYearOfDeath = parseInt(yearOfDeath, 10);

  if (parsedYearOfBirth > parsedYearOfDeath) {
    return {
      valid: false,
      message: "El año de nacimiento no debe ser mayor al año de fallecimiento",
    };
  }

  return { valid: true, message: "" };
}
