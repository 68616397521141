/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Box, Skeleton } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Fade } from "react-awesome-reveal";
import { useMediaQuery } from "@mui/material";

import CardSpecie from "../../Species/listSpecies/cardSpecies";

import { useSpecies } from "../../../../hooks/clients/useSpecies";

const getSpeciesColor = (speciesName) => {
  const colorMap = {
    1: "#F36A2A",
    2: "#886D5B",
    3: "#3B8476",
    4: "#77A69D",
    5: "#0E69A8",
    6: "#E3B409",
    7: "#4CB848",
    8: "#8C62AA",
  };

  return colorMap[speciesName] || "#000000";
};

const responsive = {
  // add breakpoint query extra large
  desktopXL: {
    breakpoint: { max: 5000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  desktopSmall: {
    breakpoint: { max: 1024, min: 720 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 720, min: 465 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 465, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

export const SpeciesSection = () => {
  const { listDataSpecies, loading, getSpeciesByFilter } = useSpecies();
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const itemsPerPage = isMdScreen ? 16 : isSmScreen ? 9 : 4;
  const itemsSkeleton = isXsScreen ? 2 : isSmScreen ? 4 : 3;

  React.useEffect(() => {
    getSpeciesByFilter({
      page: 1,
      pageSize: itemsPerPage,
    });
  }, [itemsPerPage]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            gap: "1.5rem",
          }}
        >
          {Array.from(Array(itemsSkeleton).keys()).map((item) => (
            <Skeleton
              key={item}
              variant="rectangular"
              width="100%"
              height={isMdScreen ? "320px" : isSmScreen ? "300px" : "250px"}
              sx={{ borderRadius: "1rem" }}
              animation="wave"
            />
          ))}
        </Box>
      ) : (
        <>
          {listDataSpecies?.listSpecies?.length > 0 ? (
            <Fade>
              <Carousel
                responsive={responsive}
                infinite={true}
                partialVisible={true}
              >
                {listDataSpecies?.listSpecies.map((specie, index) => (
                  <Box key={index} sx={{ padding: "0 10px" }}>
                    <CardSpecie
                      commonName={specie?.vc_nombre}
                      scientificName={specie?.vc_nombre_cientifico}
                      urlImage={specie?.vc_imagen}
                      arrayState={specie?.estados_conservacion}
                      audioValue={specie?.vc_sonido}
                      colorDivider={getSpeciesColor(specie?.taxa?.id_taxa)}
                      link={`/species/${specie?.id_especie}`}
                      height={
                        isMdScreen ? "320px" : isSmScreen ? "300px" : "250px"
                      }
                    />
                  </Box>
                ))}
              </Carousel>
            </Fade>
          ) : (
            <div>No hay datos</div>
          )}
        </>
      )}
    </>
  );
};
