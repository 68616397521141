import { useState } from "react";
import { getGeneralCounter } from "../../infrastructure/api/admin/generalCounter";

export const useGeneralCounter = () => {
  const [generalCounter, setGeneralCounter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getGeneralCounterData = async () => {
    const response = await getGeneralCounter().then((res) => res);
    if (response.status === 200) {
      setGeneralCounter(response.data);
      setLoading(false);
    } else {
      setGeneralCounter([]);
      setError(true);
      setLoading(false);
    }
  };

  return {
    getGeneralCounterData,
    generalCounter,
    error,
    loading,
  };
};
