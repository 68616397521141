import React from "react";
import BannerSection from "../../../components/BannerSection";

import { useParams } from "react-router-dom";
import { CommunityProvider } from "../../../context/client/comunitiesProvider";

const LayoutCommunity = ({ title, currentPage, children }) => {
  const { id } = useParams();

  const arrayLinks = id ? [{ url: "/community", label: "Comunidades" }] : [];
  return (
    <CommunityProvider>
      <BannerSection
        title={title}
        image={"/images/img2.avif"}
        currentPage={currentPage}
        arrayLinks={arrayLinks}
      />
      {children}
    </CommunityProvider>
  );
};

export default LayoutCommunity;
