/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

import { getListCategories } from "../../../../infrastructure/api/client/categories";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

function hexToTransparentRgba(hex, alpha) {
  hex = hex.replace("#", ""); // Elimina el símbolo "#"
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const SectionTaxonomia = () => {
  const [listCategories, setListCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const transformData = (data) => {
    return data.map((item) => ({
      id: item.id_taxonomia,
      name: item.vc_nombre,
      quantity: item.in_total_especies,
      porcentage: item.de_porcentaje,
      color: getSpeciesColor(item.vc_nombre),
    }));
  };

  useEffect(() => {
    const getList = async () => {
      setLoading(true);
      const response = await getListCategories();
      // setListCategories(response);
      setListCategories(transformData(response.data));
      setLoading(false);
    };
    getList();
  }, []);

  const getSpeciesColor = (speciesName) => {
    const colorMap = {
      Aves: "#F36A2A",
      Mamiferos: "#886D5B",
      Anfibios: "#3B8476",
      Reptiles: "#77A69D",
      Peces: "#0E69A8",
      Insectos: "#E3B409",
      Arboles: "#4CB848",
      Palmeras: "#8C62AA",
    };

    return colorMap[speciesName] || "#000000";
  };

  const data = {
    labels: listCategories?.map((category) => `${category?.name}`),
    datasets: [
      {
        label: "Distribución de especies",
        data: listCategories?.map((category) => category?.quantity),
        backgroundColor: listCategories?.map((category) =>
          hexToTransparentRgba(category.color, 0.5)
        ),
        borderColor: listCategories?.map((category) => category.color),
      },
    ],
  };

  const legendOptions = {
    // position: window.innerWidth < 600 ? "bottom" : "left",
    position: "left",
    labels: {
      // usePointStyle: true,
      boxWidth: 15,
    },
  };

  const titleOptions = {
    display: true,
    text: "Categorias",
    position: "top",
  };

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #E0E0E0",
            // height: "100%",
            height: { sx: "280px", md: "240px" },
            p: 1,
          }}
        >
          <Pie
            data={data}
            options={{
              plugins: {
                legend: legendOptions,
                title: titleOptions,
                datalabels: {
                  display: true,
                },
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default SectionTaxonomia;
