import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";

const CardIndicatorsDashboard = ({
  title,
  subTitle,
  value,
  icon: IconComponent,
  color,
  loading,
  url,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Card sx={{ borderRadius: 2, minWidth: 200 }}>
        <CardContent>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Box>{IconComponent}</Box>
            <Box>
              <Typography
                variant="body2"
                component="h2"
                fontFamily={"poppins"}
                color={"gray"}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                fontFamily={"poppins"}
                fontWeight={"bold"}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  value
                )}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Divider sx={{ color: "gray", width: "100%" }} />
        <CardActions>
          <Typography
            variant="caption"
            component="h2"
            paddingX={1}
            color={"gray"}
            sx={{
              cursor: "pointer",
              fontWeight: 700,
              fontFamily: "poppins",
              ":hover": {
                color: "#1976d2",
              },
              ":focus": {
                color: "#1976d4",
              },
            }}
            onClick={() => navigate(url)}
          >
            Ver más {subTitle}
          </Typography>
        </CardActions>
      </Card>
    </>
  );
};

export default CardIndicatorsDashboard;
