export const transformDataCommunity = (data) => {
  if (data) {
    return data?.map((item) => {
      return {
        id: item.id_pueblo_indigena,
        name: item.vc_nombre,
        image: item.vc_image,
        date: item.updated_at,
        status: parseInt(item.ch_estado),
      };
    });
  } else return [];
};

export const transformDataAuthors = (data) => {
  if (data) {
    return data?.map((item) => {
      return {
        id: item.id_autor,
        name: item.vc_nombre,
        image: item.vc_imagen,
        date: item.updated_at,
        status: parseInt(item.ch_estado),
      };
    });
  } else return [];
};

export const transformConservation = (data) => {
  if (data) {
    return data?.map((item) => {
      return {
        id: item.id_est_conservacion,
        name: item.vc_nombre,
        image: item.vc_imagen_estado,
        date: item.updated_at,
        status: parseInt(item.ch_estado),
      };
    });
  } else return [];
};

export const transformDataSpecies = (data) => {
  if (data) {
    return data?.map((item) => {
      return {
        id: item.id_especie,
        name: item.vc_nombre,
        last_name: item.vc_nombre_cientifico,
        hasSound: item.vc_sonido,
        image: item.vc_imagen,
        date: item.fecha_modificacion,
        status: parseInt(item.estado),
      };
    });
  } else return [];
};

export const transformDataTaxonomies = (data, key, fk_key) => {
  if (data) {
    return data?.map((item) => {
      return {
        id: item[`id_${key}`],
        fk_id: item[`id_${fk_key}`],
        name: item.vc_nombre,
        status: parseInt(item.ch_estado),
      };
    });
  } else return [];
};
