import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AudioPlayer from "../audioPlayer";
import CustomizedMenus from "../IconButtonMoreOptions";
import { ImgModalZoom } from "../../../../../components";

const ImageSection = ({ species }) => {
  const [open, setOpen] = React.useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box
        height={{
          md: "450px",
          sm: "auto",
          xs: "auto",
        }}
        minHeight={{
          md: "450px",
          sm: "400px",
          xs: "320px",
        }}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
        }}
      >
        {!imageLoaded && (
          <Skeleton
            variant="rect"
            width="100%"
            height="100%"
            style={{ display: "block" }}
          />
        )}

        <img
          onClick={() => handleOpen()}
          src={species.vc_imagen}
          alt="imagen"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            cursor: "zoom-in",
            display: imageLoaded ? "block" : "none",
            //add zoom if is hover
          }}
          onLoad={() => setImageLoaded(true)}
        />
        <Box
          sx={{
            // display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "auto",
            p: 2,
            position: "absolute",
            bottom: "0",
            left: "0",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {
              // if species is object empty, render a loading indicator
              Object.keys(species).length !== 0 && (
                <CustomizedMenus
                  specie={species}
                  disableButton={imageLoaded ? false : true}
                />
              )
            }
          </Box>
        </Box>
      </Box>

      {species?.vc_sonido ? (
        <>
          <Box mt={2}>
            <AudioPlayer audioUrl={species.vc_sonido} />
          </Box>
        </>
      ) : null}
      <ImgModalZoom
        src={species.vc_imagen}
        alt={species.vc_nombre_comun}
        open={open}
        onClose={() => handleOpen()}
      />
    </>
  );
};

export default ImageSection;
