import ListSpecies from "./ListSpecies/ListSpecies";
import { LayoutFrmList } from "../../../../components";

const IndexSpecies = () => {
  return (
    <>
      <LayoutFrmList title={"Especies"} to={"/admin/especies/add"}>
        <ListSpecies />
      </LayoutFrmList>
    </>
  );
};

export default IndexSpecies;
