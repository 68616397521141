import ListConservationStatus from "./ListConservation/ListConservation";
import { LayoutFrmList } from "../../../../components";

const IndexConservation = () => {
  return (
    <>
      <LayoutFrmList
        title={"Estado de conservación"}
        to={"/admin/status-conservation/add"}
      >
        <ListConservationStatus />
      </LayoutFrmList>
    </>
  );
};

export default IndexConservation;
