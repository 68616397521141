export const TitleLarge = {
  fontSize: 28,
  component: "div",
  marginBottom: 2,
  marginTop: 4,
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
};

export const TitleSection = {
  display: "flex",
  align: "left",
  fontWeight: "bold",
  // fontFamily: "poppins",
};

export const SubTitleSection = {
  fontSize: 13,
  align: "left",
  fontWeight: "bold",
  // fontFamily: "poppins",
};

export const StyleCardSpecie = {
  styleCardMedia: {
    objectFit: "contain",
    height: "100%",
    objectPosition: "center top",
    // transform: "scale(0.9)",
    backgroundColor: "#ffffff",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  styleCArdContent: {
    textAlign: "left",
    position: "absolute",
    bottom: 0,
    color: "#ffffff",
    width: "100%",
    padding: "0",
  },
  styleTextContent: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    paddingX: 2,
  },
  title: {
    fontSize: { xs: "0.9rem", sm: "1rem" },
    paddingTop: 1,
    fontWeight: "bold",
    color: "#000000",
    // fontFamily: "poppins",
  },
  subTitle: {
    fontSize: { xs: "0.8rem", sm: "0.9rem" },
    color: "#000000",
    fontStyle: "italic",
    marginBottom: 0,
    // fontFamily: "poppins",
  },
};

export const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
  },
  leftColumn: {
    maxHeight: "calc(100vh - 200px)",
    height: "100vh",
    position: "sticky",
    overflowY: "auto",
    top: 200,
    paddingRight: "20px",
  },
  rightColumn: {
    flex: 1,
    overflowY: "auto",
    maxHeight: "100vh",
  },
  styleSticky: {
    width: "100%",
    maxHeight: "100vh",
    position: "sticky",
    overflowY: "auto",
    top: { xs: 56, sm: 64, md: 124 },
    backgroundColor: "#fff",
    zIndex: 100,
    paddingTop: { xs: 2, sm: 4 },
  },
  TitleSection: {
    display: "flex",
    align: "left",
    fontWeight: "bold",
  },
};

export const stylesDetail = {
  titleLarge: {
    fontSize: { xs: "2.2rem", lg: "3.5rem" },
    fontWeight: "bold",
    component: "div",
    textTransform: "capitalize",
    // fontFamily: "poppins",
  },
  titleMedium: {
    fontSize: { xs: "1.5rem", lg: "2rem" },
    fontWeight: "bold",
    component: "div",
    textTransform: "capitalize",
    // fontFamily: "poppins",
  },
  titleSmall: {
    fontSize: { xs: "1rem", lg: "1.5rem" },
    component: "div",
    "&::first-letter": { textTransform: "uppercase" },
    fontStyle: "italic",
    // fontFamily: "poppins",
  },
  titleSection: {
    fontSize: { xs: "1rem", md: "1.2rem" },
    component: "div",
    fontWeight: "bold",
    // fontFamily: "poppins",
  },
};

export const styleDivider = {
  width: "2px",
  height: "18px",
  backgroundColor: "#8B0000",
  borderWidth: "2px",
  margin: "0 16px 0 0",
};
