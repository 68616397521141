import { Modal, Fade, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { stylesFont } from "../styles";

const style = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: { xs: "100%", sm: "90%" },
    maxWidth: { xs: "100%", sm: "80%", xl: "60rem" },
    maxHeight: { xs: "100%", sm: "90%", xl: "80%" },
    borderRadius: "0.5rem",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "auto",
  },
  header: {
    backgroundColor: "primary.main",
    color: "white",
    px: 2,
    py: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: -2,
    zIndex: 100,
  },
  body: {
    paddingX: { xs: 2, sm: 4, md: 6, lg: 8 },
  },
  titleLarge: {
    fontSize: { xs: "1.4rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
    fontWeight: "bold",
    textTransform: "capitalize",
    textShadow: "0px 2px 2px rgba(0,0,0,0.3)",
  },
  personalInfo: {
    display: { xs: "block", sm: "flex" },
    gap: 2,
    columnGap: 2,
    mb: { xs: "0.7rem", sm: "1rem" },
    // alignItems: "end",
  },
  listSpecies: {
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(2, 1fr)",
      sm: "repeat(3, 1fr)",
      lg: "repeat(4, 1fr)",
    },
    gap: 2,
  },
};

export const ModalDetails = ({ children, ...props }) => {
  const handleClose = () => {
    props.onClose();
  };
  return (
    <>
      <Modal
        {...props}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style.modal}>
            <Box sx={style.header}>
              <Typography
                sx={{
                  ...stylesFont.subTitleSection,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {props.title}
              </Typography>
              <IconButton color="inherit" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={style.body}>{children}</Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
