import { Grid, Typography } from "@mui/material";
import { stylesFonts } from "../../styles";

const SectionDetailsTown = ({ town }) => {
  const details = [
    {
      title: "Fecha de registro",
      value: town?.created_at ? town?.created_at : "Sin registro",
    },
    { title: "Latitud", value: town?.de_latitud },
    { title: "Longitud", value: town?.de_longitud },
  ];
  return (
    <>
      <Typography sx={stylesFonts.titleSection}>Información general</Typography>
      <Grid
        container
        spacing={{
          xs: 1,
          sm: 2,
          md: 3,
        }}
      >
        {details?.map((detail, index) => (
          <Grid item key={index}>
            <Typography sx={stylesFonts.subtitleSection}>
              {detail.title}
            </Typography>
            <Typography sx={stylesFonts.textSection}>{detail.value}</Typography>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography sx={stylesFonts.subtitleSection}>Descripción</Typography>
          <Typography sx={{ ...stylesFonts.textSection, textAlign: "justify" }}>
            {town?.te_descripcion}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionDetailsTown;
