import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Avatar } from "@mui/material";

export default function SelectInput({
  label,
  id,
  value,
  onChange,
  options,
  ...props
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          id={id}
          value={value}
          label={label}
          labelId="demo-simple-select-label"
          displayEmpty
          onChange={onChange}
          {...props}
          sx={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            textAlign: "left",
            borderRadius: "8px",
            "& .MuiSelect-select": {
              // padding: "8px",
              borderRadius: "8px",
              border: "2px solid #E0E0E0",
              paddingY: "0.7rem",
            },
          }}
        >
          {Array.isArray(options) &&
            options.map((option, index) => (
              <MenuItem
                key={index + 1}
                value={option.id}
                sx={{
                  alignItems: "center",
                  textAlign: "left",
                }}
              >
                {option.icon && (
                  <Avatar
                    alt={option.name}
                    src={option.icon}
                    sx={{
                      display: "inline-flex",
                      bgcolor: option.bgColor ? option.bgColor : "#FFFFFF",
                      width: 18,
                      height: 18,
                      p: 1,
                      mr: 2,
                    }}
                    variant="rounded"
                  ></Avatar>
                  // <Box
                  //     component="img"
                  //     src={option.icon}
                  //     alt={option.name}
                  //     sx={{
                  //         width: 32,
                  //         mr: 2,
                  //         background: "red",
                  //     }}
                  // />
                )}
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
