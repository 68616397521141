import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getListConservation = async (
  token,
  search,
  currentPage,
  sizePage
) => {
  const apiUrl = search
    ? `${API_BASE_URL}/status/admin/search/${search}/${currentPage}/${sizePage}`
    : `${API_BASE_URL}/status/admin/${currentPage}/${sizePage}`;

  try {
    const response = await axios.get(apiUrl, axiosConfig(token));

    return response;
  } catch (error) {
    return error;
  }
};

export const getConservationById = async (token, id) => {
  const response = await axios.get(
    `${API_BASE_URL}/status/admin/${id}`,
    axiosConfig(token)
  );
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const saveOrEditConservation = async (token, id, body) => {
  const apiUrl = id
    ? `${API_BASE_URL}/status/edit/${id}`
    : `${API_BASE_URL}/status/create`;
  const method = id ? "patch" : "post";

  const response = await axios[method](apiUrl, body, axiosConfig(token));
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteConservation = async (token, id, body) => {
  const response = await axios.delete(`${API_BASE_URL}/status/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: body,
  });
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const addUpdateImgConservation = async (token, id, data) => {
  const response = axios.put(
    `${API_BASE_URL}/status/addUpdateImg/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteImgConservation = async (token, id) => {
  const response = axios.delete(
    `${API_BASE_URL}/status/deleteImg/${id}`,
    axiosConfig(token)
  );
  try {
    return response;
  } catch (error) {
    return error;
  }
};
