import React from "react";
import IndexListSpecie from "../components/Species/listSpecies/indexListSpecie";

const SpeciesPage = () => {
  return (
    <>
      <IndexListSpecie />
    </>
  );
};

export default SpeciesPage;
