import React from "react";
// import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import { styleSections } from "../../styles";
import SectionDetails from "./SectionDetails";
import ButtonCustom from "../../../../../components/Admin/ButtonCustom";

import { useLogicAuthorsAdmin } from "../../../../../context/admin/authorsProvider";
import SectionMultimedia from "./SectionMultimedia";
import { LayoutFrmAdd } from "../../../../../components/Admin/layouts/layoutFrmAdd";

const FormAddAuthor = () => {
  const { id_autor, author, editOrSaveAuthor, loading, warningMsg } =
    useLogicAuthorsAdmin();
  const [dataValues, setDataValues] = React.useState({});

  const handleSaveOrEditData = () => {
    editOrSaveAuthor(dataValues, id_autor);
  };

  return (
    <>
      <LayoutFrmAdd
        loading={loading}
        title={id_autor ? "Editar Autor" : "Agregar Autor"}
        label={"Autores"}
      >
        <Box sx={{ display: { xs: "block", md: "flex" }, gap: 2 }}>
          <Box sx={styleSections}>
            <SectionDetails
              author={author}
              warningMsg={warningMsg}
              setValues={(data) => setDataValues(data)}
            />
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              sx={{ mt: 2, gap: 2 }}
            >
              <ButtonCustom
                label={id_autor ? "Editar" : "Guardar"}
                color={"primary"}
                onClick={handleSaveOrEditData}
              />

              <ButtonCustom
                label={"Cancelar"}
                color={"error"}
                to={"/admin/autores"}
              />
            </Box>
          </Box>
          {id_autor ? (
            <Box
              sx={{ ...styleSections, maxWidth: { xs: "100%", md: "340px" } }}
            >
              <SectionMultimedia data={author} />
            </Box>
          ) : null}
        </Box>
      </LayoutFrmAdd>
    </>
  );
};

export default FormAddAuthor;
