import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const DialogActionsTable = ({
  title,
  question,
  confirmValue,
  ...props
}) => {
  const changeValue = (value) => {
    confirmValue(value);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{title || "Mensaje"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {question || "¿Está seguro de realizar la operación?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => changeValue(true)} color="primary">
          Aceptar
        </Button>
        <Button onClick={props.onClose} color="error">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
