import React from "react";
import { TaxonomyProvider } from "../../../context/specieUser/TaxonomiaProvider";

import { MultimediaProvider } from "../../../context/client/multimediaProvider";
import { ConservationProvider } from "../../../context/client/conservationProvider";
import { TaxonomiesProvider } from "../../../context/client/taxonomiesProvider";
import { SpeciesProvider } from "../../../context/client/speciesProvider";

const LayoutSpecies = ({ title, currentPage, children }) => {
  return (
    <>
      <SpeciesProvider>
        <TaxonomiesProvider>
          <ConservationProvider>
            <MultimediaProvider>
              <TaxonomyProvider>{children}</TaxonomyProvider>
            </MultimediaProvider>
          </ConservationProvider>
        </TaxonomiesProvider>
      </SpeciesProvider>
    </>
  );
};

export default LayoutSpecies;
