/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Alert } from "@mui/material";
import { stylesFonts } from "../../styles";
import { UpLoadImage } from "../../../../../components";
import { useCommunities } from "../../../../../hooks";
import { useLogicCommunitiesAdmin } from "../../../../../context/admin/CommunitiesProvider";

export const SectionMultimedia = ({ data }) => {
  const [townData, setTownData] = useState({});
  const { id_community } = useLogicCommunitiesAdmin();
  const {
    updateOrDeleteImgCommunity,
    loading,
    message,
    getDataCommunityById,
    community,
  } = useCommunities();

  useEffect(() => {
    if (data) {
      setTownData(data);
    }
  }, [data]);

  useEffect(() => {
    if (community) {
      setTownData(community);
    }
  }, [community]);

  const handleUpLoadFile = async (file) => {
    await updateOrDeleteImgCommunity(id_community, file);
    getDataCommunityById(id_community);
  };

  const severity = message?.status === 200 ? "success" : "error";

  return (
    <>
      <Typography sx={stylesFonts.titleSection}>Multimedia</Typography>
      {message?.message !== "" && message?.message !== null && (
        <Box py={1}>
          <Alert severity={severity}>
            <Typography sx={stylesFonts.messageAlert}>
              {message.message}
            </Typography>
          </Alert>
        </Box>
      )}
      <Box py={2}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <UpLoadImage
            data={{
              id: townData?.id_pueblo_indigena,
              image: townData?.vc_image,
            }}
            handleDelete={() => handleUpLoadFile()}
            handleUpLoad={(file) => handleUpLoadFile(file)}
          />
        )}
      </Box>
    </>
  );
};
