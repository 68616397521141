import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Stack,
  Box,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import useAuth from "../../../../utils/hooks/useAuth";
import { TitleSection, TitleInput } from "./styles";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(null);

  useEffect(() => {
    if (msgError) {
      setTimeout(() => {
        setMsgError(null);
      }, 5000);
    }
  }, [msgError]);

  const [vc_email, setUsername] = useState("");
  const [vc_password, setPassword] = useState("");

  const { setAuth } = useAuth();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ([vc_email, vc_password].includes("")) {
      setMsgError("Todos los campos son obligatorios");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        {
          vc_email,
          vc_password,
        }
      );
      const data = await response.data;
      if (data.token) {
        localStorage.setItem("token_raices", data.token);
        setAuth(data);
        window.location.href = "/admin";
      } else {
        setMsgError(data.msg);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Box position="absolute" top={0} left={0} zIndex={1} />
      <Box
        mx="auto"
        position="relative"
        zIndex={2}
        sx={{
          backgroundImage: "url(/images/fondo_login.avif)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.4)"
        >
          <Box
            sx={{
              borderRadius: "10px",
              padding: "0",
              color: "#015E80",
              width: { xs: "100%", sm: "100%", md: "50%" },
              backgroundColor: "#ffffff",
            }}
            component={"form"}
            role="form"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <Box
                  textAlign="center"
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "block",
                  }}
                >
                  <img
                    src={"/images/ave_login.avif"}
                    alt="Logo raíces"
                    width="100%"
                    style={{
                      height: "100%",
                      objectPosition: "center",
                      left: "386px",
                      top: "0",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box noValidate autoComplete="off" padding={4}>
                  <Stack spacing={3}>
                    <Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <img
                          src={"/favicon/android-chrome-192x192.png"}
                          alt="Logo raíces"
                          style={{
                            width: "72px",
                            height: "72px",
                            objectPosition: "center",
                          }}
                        />
                      </Box>

                      <Typography sx={TitleSection}>INICIAR SESIÓN</Typography>
                      <Typography variant="body2" component="p" align="center">
                        Ingrese sus datos para iniciar sesión
                      </Typography>
                      {msgError && (
                        <Alert severity="warning" sx={{ marginTop: "16px" }}>
                          <Typography variant="body2" component="p">
                            {msgError}
                          </Typography>
                        </Alert>
                      )}
                    </Box>
                    <Box mb={1}>
                      <Typography sx={TitleInput}>
                        Correo electrónico
                      </Typography>
                      <TextField
                        type="text"
                        placeholder="Ingrese tu Correo electrónico"
                        fullWidth
                        value={vc_email}
                        onChange={handleUsernameChange}
                        color="primary"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "8px", // Bordes redondeados
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box mb={2}>
                      <Typography sx={TitleInput}>Contraseña</Typography>
                      <OutlinedInput
                        fullWidth
                        id="filled-basic"
                        type={showPassword ? "text" : "password"}
                        value={vc_password}
                        onChange={handlePasswordChange}
                        placeholder="Ingrese su contraseña"
                        sx={{
                          borderRadius: 2,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Box>
                    <Box mt={4} mb={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "#015E80",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {loading ? "Cargando..." : "Iniciar sesión"}
                        </Typography>
                      </Button>
                    </Box>
                    <Typography
                      variant="body2"
                      align="center"
                      color="text.secondary"
                      component={Link}
                      to="/"
                    >
                      Volver al inicio
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default Login;
