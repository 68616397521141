import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Button,
  CardActionArea,
  Chip,
  Skeleton,
  CardContent,
  CardMedia,
} from "@mui/material";

import { stylesFont } from "../../../../styles";

export const SimpleStyle = ({ data, isItalic, onClick }) => {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleButtonClick = () => {
        onClick(data?.id);
    };

    const textColor = "black";
    const textFont = stylesFont.fontFamily.primary;

    return (
        <Card
            sx={{
                borderRadius: "0.5rem",
            }}
            maxWidth={345}
        >
            <CardActionArea
                onClick={onClick && handleButtonClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {!imgLoaded && (
                    <Skeleton variant="rectangular" width="100%" height={220} />
                )}
                <CardMedia
                    component="img"
                    height={220}
                    onLoad={() => setImgLoaded(true)}
                    alt={data?.title}
                    image={
                        data?.image !== ""
                            ? data?.image
                            : "/images/errorsImages/not_image.webp"
                    }
                    sx={{
                        objectFit: "cover",
                        display: imgLoaded ? "block" : "none",
                        transition: "transform 0.3s ease-in-out",
                        zIndex: 0,
                        position: "relative",
                        transform: isHovered ? "scale(1.05)" : "scale(1)",
                    }}
                />
                {data?.tag && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            p: 1,
                            zIndex: 1, // Coloca el Chip en la parte superior
                        }}
                    >
                        <Chip
                            label={data?.tag}
                            sx={{
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "0.5rem",
                                color: "white",
                            }}
                        />
                    </Box>
                )}
                <CardContent
                    sx={{
                        p: 2,
                        pb: 1.5,
                        minHeight: 40,
                        absolute: "bottom",
                        zIndex: 1,
                    }}
                >
                    <Typography
                        color={textColor}
                        variant="caption"
                        fontFamily={textFont}
                    >
                        {data?.caption}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        fontWeight={900}
                        color={textColor}
                        fontFamily={textFont}
                        transition="text-decoration 0.5s ease-in-out"
                        sx={{
                            textDecoration: isHovered ? "underline" : "none",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {data?.title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color={textColor}
                        fontFamily={textFont}
                        fontStyle={isItalic ? "italic" : "normal"}
                        maxWidth={100}
                    >
                        {data?.subtitle}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            sx={{
                                borderColor: "white",
                                borderRadius: "0.5rem",
                                fontSize: "0.6rem",
                                fontWeight: 900,
                            }}
                        >
                            Ver más
                        </Button>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
