import React from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";

const classes = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    minHeight: "100vh",
  },
};
export const SplashScreen = () => {
  return (
    <Container className={classes.root}>
      <Box
        sx={{
          textAlign: "center",
          justifyContent: "center",
          height: "100vh",
          pt: 50,
        }}
      >
        <CircularProgress color="success" sx={{ mb: 2 }} />
        <Typography variant="h4" color="success" fontWeight={600}>
          AMAZONÍA
        </Typography>
        <Typography variant="h6" color="success" fontWeight={600}>
          Guía Ilustrada de Flora y Fauna
        </Typography>
      </Box>
    </Container>
  );
};
