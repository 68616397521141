import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from "@react-pdf/renderer";

//Colors and icons
import { speciesArray } from "../../../styles/colors";
import { IconsTaxonomia } from "../../../../infrastructure/data/ArrayTaxas";

const BASE_URL = process.env.REACT_APP_URL;
const logo_amz = "/favicon/apple-touch-icon.png";
const logo_minam = "/images/logo_minam.png";
const logo_iiap = "/images/iiap_logo.png";
const logo_esp = "/images/logo_spain.png";

const getColors = (value) => {
  const colors = speciesArray.find((item) => item.name === value);
  return colors;
};

const getIcon = (id) => {
  const icon = IconsTaxonomia.find((item) => item.taxaValue === id);
  return icon;
};

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 700,
    },
    {
      fontStyle: "italic",
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
      fontWeight: 400,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    margin: 24,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: 40,
    height: 40,
  },
  titleLogo: {
    fontSize: 18,
    fontWeight: 500,
    margin: 2,
    fontFamily: "Roboto",
  },
  sectionHeader: {},
  subTitleLogo: {
    fontSize: 8,
    fontWeight: 500,
    fontFamily: "Roboto",
    width: "100%",
  },
  sectionLogo: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  dividerHeader: {
    borderBottom: "3px solid #015E80",
    width: 540,
    marginBottom: 2,
  },
  sectionNames: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "Roboto",
    paddingBottom: 8,
  },
  subTitle: {
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  description: {
    fontSize: 13,
    flexWrap: "wrap",
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  containerTaxo: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    width: "100%",
    marginBottom: 10,
  },
  footer: {
    position: "absolute",
    bottom: 28,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

// Create Document Component
const MyDocument = ({ data }) => {
  // console.log(data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.sectionLogo}>
            <Image src={logo_amz} style={styles.logo} />
            <View style={{ marginLeft: 8 }}>
              <Text style={styles.titleLogo}>Amazonía</Text>
              <Text style={styles.subTitleLogo}>
                Guía ilustrada de flora y fauna
              </Text>
            </View>
          </View>
          <View style={styles.dividerHeader} />
        </View>
        <View style={styles.sectionNames}>
          <View>
            <View
              style={{
                backgroundColor: getColors(data?.taxa?.vc_nombre).primaryColor,
                with: 40,
                height: 54,
              }}
            >
              <Image
                src={getIcon(data?.taxa?.id_taxa).taxaIcono}
                style={{ width: 20, height: 30, margin: 8, paddingTop: 8 }}
              />
            </View>
            <View
              style={{
                backgroundColor: getColors(data?.taxa?.vc_nombre)
                  .secondaryColor,
                with: 40,
                height: 10,
              }}
            />
          </View>
          <View>
            <Text
              style={{
                ...styles.subTitle,
                fontSize: 24,
                fontWeight: 900,
                color: getColors(data?.taxa?.vc_nombre).primaryColor,
              }}
            >
              {data?.vc_nombre}
            </Text>
            <Text
              style={{
                ...styles.subTitle,
                fontSize: 18,
                fontStyle: "italic",
                marginBottom: 4,
                color: getColors(data?.taxa?.vc_nombre).secondaryColor,
              }}
            >
              {data?.vc_nombre_cientifico}
            </Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {data?.vc_nombre_ingles !== "" ? data?.vc_nombre_ingles : ""}
            </Text>
          </View>
        </View>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ marginTop: 24, width: "100%", maxWidth: 210 }}>
              <Text style={styles.title}>Taxonomía</Text>
              <View style={styles.containerTaxo}>
                <View style={styles.subTitle}>
                  <Text>Reino</Text>
                  <Text>Filo</Text>
                  <Text>Clase</Text>
                  <Text>Orden</Text>
                  <Text>Familia</Text>
                </View>
                <View style={styles.description}>
                  <Text>: {data?.taxonomia?.reino?.vc_nombre}</Text>
                  <Text>: {data?.taxonomia?.filo?.vc_nombre}</Text>
                  <Text>: {data?.taxonomia?.clase?.vc_nombre}</Text>
                  <Text>: {data?.taxonomia?.orden?.vc_nombre}</Text>
                  <Text>: {data?.taxonomia?.familia?.vc_nombre}</Text>
                </View>
              </View>
              <Text style={styles.title}>EE.CC</Text>
              <View>
                {data?.estados_conservacion?.length > 0 ? (
                  data?.estados_conservacion?.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={item.imagen_estado}
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 8,
                          marginBottom: 8,
                        }}
                      />
                      <Text style={styles.description}>
                        {item.vc_nombre} - {item.institucion}
                      </Text>
                    </View>
                  ))
                ) : (
                  <Text style={{ ...styles.description, color: "#CECECE" }}>
                    No registrado
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                width: "100%",
              }}
            >
              <Image
                src={data.vc_imagen}
                style={{
                  height: 300,
                  width: "100%",
                  maxWidth: 420,
                  objectPosition: "center",
                  margin: 2,
                }}
              />
            </View>
          </View>
          <View></View>
        </View>
        <View style={{ width: "100%", maxWidth: 540 }}>
          <Text style={styles.title}>Descripción</Text>
          <Text style={styles.description} wrap>
            {data?.te_descripcion}
          </Text>
        </View>
        <View style={styles.footer}>
          <Link
            style={{
              fontSize: 10,
            }}
            src={`${BASE_URL}/species/${data?.id_especie}`}
          >{`${BASE_URL}/species/${data?.id_especie}`}</Link>
          <View
            style={{
              ...styles.dividerHeader,
              borderBottom: "3px solid rgba(0,90,20)",
              marginBottom: 8,
              marginTop: 8,
            }}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: 550,
            }}
          >
            {[logo_iiap, logo_minam, logo_esp].map((item, index) => (
              <Image
                key={index}
                src={item}
                style={{
                  //   width: 40,
                  height: 40,
                  marginRight: 8,
                  marginBottom: 8,
                }}
              />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
