import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListClass = async () => {
  const response = await axios
    .get(`${API_BASE_URL}/classes`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Species not found");
        return null;
      }
      throw err;
    });
  return response;
};

export const getListOrder = async () => {
  const response = await axios
    .get(`${API_BASE_URL}/orders`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Species not found");
        return null;
      }
      throw err;
    });
  return response;
};

export const getListFamily = async () => {
  const response = await axios
    .get(`${API_BASE_URL}/families`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Species not found");
        return null;
      }
      throw err;
    });
  return response;
};

export const getListOrderByIdClass = async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}/orders/by-class/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Species not found");
        return null;
      }
      throw err;
    });
  return response;
};

export const getListFamilyByIdOrder = async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}/families/by-order/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Species not found");
        return null;
      }
      throw err;
    });
  return response;
};
