/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { stylesFonts, styleSections } from "../../styles";
import ButtonCustom from "../../../../../components/Admin/ButtonCustom";
import { UpLoadImage } from "../../../../../components";
import { useLogicCategorieContext } from "../../../../../context/admin/categoriesProvider";
import { useEffect } from "react";

export const CrudSectionCategories = () => {
  const { category } = useLogicCategorieContext();

  const [fromData, setFromData] = React.useState({
    vc_nombre: "",
    vc_color_primario: "",
    vc_color_secundario: "",
    vc_imagen: "",
  });

  useEffect(() => {
    if (category) {
      setFromData({
        ...fromData,
        vc_nombre: category.vc_nombre,
        vc_color_primario: category.vc_color_primario,
        vc_color_secundario: category.vc_color_secundario,
        vc_imagen: category.vc_imagen,
      });
    }
  }, [category]);

  const handleFromData = (event) => {
    const { name, value } = event.target;
    setFromData({
      ...fromData,
      [name]: value,
    });
  };

  // console.log(fromData);

  return (
    <>
      <Box sx={styleSections}>
        <Typography sx={stylesFonts.titleSection}>
          Agregar o editar categorias
        </Typography>
        <Box>
          <TextField
            fullWidth
            placeholder="Nombre de la categoria"
            margin="dense"
            value={fromData.vc_nombre}
          />
          <TextField
            // type color
            fullWidth
            placeholder="Color de la categoria"
            type="color"
            margin="dense"
            value={fromData.vc_color_primario}
            name="vc_color_primario"
            onChange={(e) => handleFromData(e)}
          />
          <TextField
            // type color
            fullWidth
            placeholder="Color de la categoria"
            type="color"
            margin="dense"
            value={fromData.vc_color_secundario}
            name="vc_color_secundario"
            onChange={(e) => handleFromData(e)}
          />
          <UpLoadImage
            data={{
              image: fromData.vc_imagen,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <ButtonCustom
            label={"Guardar"}
            color={"primary"}
            // onClick={handleSaveOrEditData}
          />
          <ButtonCustom
            label={"Cancelar"}
            color={"error"}
            // to={"/admin/autores"}
          />
        </Box>
      </Box>
    </>
  );
};
