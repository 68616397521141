import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Stack, Skeleton } from "@mui/material";
import { ModalDetails } from "../../../components/modalDetails";
import { getListConservation } from "../../../infrastructure/api/client/conservation";
import { LoadingInfo } from "../../../components";

const styles = {
  styleBody: {
    paddingY: { xs: 2, sm: 4, md: 6 },
  },
  styleText: {
    textAlign: "justify",
    fontSize: "0.9rem",
    marginBottom: "1rem",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1rem",
    marginBottom: "0.9rem",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "0.9rem",

    marginBottom: "0.9rem",
  },
  caption: {
    fontSize: "0.7rem",
    textAlign: "justify",
  },
};

const ModalConservation = ({ open, onClose }) => {
  const [dataConservation, setDataConservation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);

  const getDataConservation = async () => {
    setLoading(true);
    const response = await getListConservation();
    setDataConservation(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getDataConservation();
  }, []);

  return (
    <ModalDetails
      open={open}
      onClose={onClose}
      title={"Sobre los Estados de Conservación"}
    >
      {loading ? (
        <LoadingInfo />
      ) : (
        <Box sx={styles.styleBody}>
          <Typography sx={styles.styleText}>
            Se presenta el estado de conservación a nivel mundial como a nivel
            regional. Este dato se presenta de manera visual al pie de cada
            ilustración, tomando como referencia los siguientes documentos:
          </Typography>
          <Typography sx={styles.subTitle}>
            Decreto Supremo Nº 004-2014-MINAGRI
          </Typography>
          <Typography sx={styles.styleText}>
            En el que se aprueba y actualiza la categorización de especies
            amenazadas de fauna silvestre a nivel nacional, siguiendo los
            criterios y categorías de la UICN a nivel mundial.
          </Typography>
          <Typography sx={styles.subTitle}>
            Decreto Supremo N° 043-2006-AG
          </Typography>
          <Typography sx={styles.styleText}>
            En el que se aprueba la categorización de especies amenazadas de
            flora silvestre a nivel nacional, siguiendo los criterios y
            categorías de la UICN a nivel mundial. en la que se presenta la
            categorización de especies amenazadas de flora y fauna silvestre a
            nivel mundial.
          </Typography>
          <Typography sx={styles.title}>
            Protección Global de Especies: Lista de especies amenazadas de la
            Unión Mundial para la Naturaleza-UICN y CITES
          </Typography>
          <Typography sx={styles.styleText}>
            La Lista Roja de la UICN y la CITES destacan la amenaza global a la
            flora y fauna, categorizando y regulando el comercio para garantizar
            la supervivencia de las especies en peligro
          </Typography>
          <Stack spacing={1} marginTop={2}>
            <Grid container spacing={1}>
              {dataConservation?.map((status, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box>
                    <Box display={"flex"} alignItems="center">
                      {loadingImg && (
                        <Skeleton
                          variant="rectangular"
                          width={40}
                          height={40}
                        />
                      )}
                      <img
                        src={status.vc_imagen_estado}
                        alt={status.vc_nombre}
                        width="40px"
                        height="40px"
                        onLoad={() => setLoadingImg(false)}
                      />

                      <Typography sx={styles.subTitle} m={2}>
                        {status.vc_nombre}: {status.vc_iniciales} - (
                        {status.vc_institucion})
                      </Typography>
                    </Box>
                    <Typography sx={styles.caption}>
                      {status.te_descripcion}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Box>
      )}
    </ModalDetails>
  );
};

export default ModalConservation;
