import { useState, useEffect } from "react";

import {
  getAuthorsNotInSpecie,
  addAuthorToSpecie,
  deleteAuthorFromSpecie,
} from "../../api";

export const useSpecieAuthor = () => {
  const [dataListAuhtors, setDataListAuhtors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const handleResponse = (response, successMessage, errorMessage) => {
    setLoading(false);
    if (response && response.status === 200) {
      setMessage({
        ...message,
        status: response.status,
        message: successMessage,
      });
    } else {
      setMessage({
        ...message,
        status: response.status,
        message: errorMessage,
      });
    }
  };

  const getAuthorsNotInSpecieData = async (token, id_especie, body) => {
    setLoading(true);
    const response = await getAuthorsNotInSpecie(token, id_especie, body);
    if (response && response.status === 200) {
      setDataListAuhtors(response.data);
    } else {
      setDataListAuhtors([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const addOrDeleteAuthorToSpecie = async (token, isDelete, body) => {
    setLoading(true);
    const response = isDelete
      ? await deleteAuthorFromSpecie(token, body)
      : await addAuthorToSpecie(token, body);

    const res = isDelete ? "eliminar" : "añadir";
    handleResponse(
      response,
      `Se pudo ${res} el autor a la especie`,
      `No se pudo ${res} el autor a la especie`
    );
  };

  return {
    dataListAuhtors,
    loading,
    getAuthorsNotInSpecieData,
    addOrDeleteAuthorToSpecie,
    message,
  };
};
