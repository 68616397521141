import { useState, useEffect } from "react";

import {
  getListTown,
  getTownById,
  deleteTown,
  saveOrEditTown,
  addUpdateImgTown,
  deleteImgTown,
} from "../../api";

const token = localStorage.getItem("token_raices");

export const useCommunities = () => {
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const handleResponse = (response, successMessage, errorMessage) => {
    setLoading(false);
    if (response && response.status === 200) {
      setMessage({
        ...message,
        status: response.status,
        message: successMessage,
      });
    } else {
      setMessage({
        ...message,
        status: response.status,
        message: errorMessage,
      });
    }
  };

  const getCommunitiesData = async (token, search, page, size) => {
    setLoading(true);
    const response = await getListTown(token, search, page, size);
    if (response && response.status === 200) {
      setCommunities(response.data);
    } else {
      setCommunities([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const getDataCommunityById = async (id) => {
    setLoading(true);
    const response = await getTownById(token, id);
    if (response && response.status === 200) {
      setCommunity(response.data);
    } else {
      setCommunity({});
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const editCommunityData = async (id, body) => {
    setLoading(true);
    const response = await saveOrEditTown(token, id, body);
    console.log(response);
    handleResponse(
      response,
      "Se ha actualizado correctamente",
      "No se ha podido actualizar"
    );
  };

  const saveCommunityData = async (body) => {
    setLoading(true);
    const response = await saveOrEditTown(token, null, body);
    handleResponse(
      response,
      "Se ha guardado correctamente",
      "No se ha podido guardar"
    );
  };

  const deleteCommunityData = async (token, id, body) => {
    setLoading(true);
    const response = await deleteTown(token, id, body);
    handleResponse(
      response,
      "Se ha cambiado el estado correctamente",
      "Error al cambiar el estado"
    );
  };

  const updateOrDeleteImgCommunity = async (id, file) => {
    setLoading(true);
    const response = file
      ? await addUpdateImgTown(token, { vc_image: file }, id)
      : await deleteImgTown(token, id);
    handleResponse(
      response,
      "Se ha actualizado correctamente la imagen",
      "No se ha podido actualizar la imagen"
    );
  };

  return {
    getCommunitiesData,
    getDataCommunityById,
    saveCommunityData,
    editCommunityData,
    deleteCommunityData,
    communities,
    community,
    updateOrDeleteImgCommunity,
    // response,
    loading,
    message,
  };
};
