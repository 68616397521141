import React from "react";
import {
  Avatar,
  // Alert,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  // Snackbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { paragraphSection } from "../styles";
import { CustonDialogAdmin } from "../../../../components/Admin/DialogCuston";

export const SectionListControls = ({ data, isDelete, handleDelete }) => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(null);

  const handleAction = () => {
    handleDelete(id);
    setOpen(false);
  };

  return (
    <>
      <List dense={false} sx={{ maxHeight: 400, overflow: "auto" }}>
        {data?.length > 0 ? (
          data?.map((row, index) => (
            <ListItem
              key={index}
              secondaryAction={
                isDelete ? (
                  <IconButton
                    size="small"
                    color="warning"
                    sx={{
                      borderRadius: "0.5rem",
                      border: "2px solid",
                    }}
                    onClick={() => {
                      setOpen(!open);
                      setId(row.id);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                ) : null
              }
            >
              <ListItemAvatar>
                <Avatar src={row.image} />
              </ListItemAvatar>
              <ListItemText
                primary={row.primary}
                secondary={row.secondary}
              />
            </ListItem>
          ))
        ) : (
          <Typography sx={paragraphSection}>No hay datos asignados</Typography>
        )}
      </List>
      <CustonDialogAdmin
        open={open}
        onClose={() => {
          setOpen(false);
          setId(null);
        }}
        title={"¿Está seguro de eliminar?"}
        isDelete={true}
        handleAction={() => handleAction()}
      ></CustonDialogAdmin>
    </>
  );
};
