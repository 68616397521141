import { useEffect, useState } from "react";
import { getListConservation } from "../../infrastructure/api/client/conservation";

const transformData = (data) => {
  const todosItem = { id: "", value: "", name: "Todos" };

  return [
    todosItem,
    ...data.map((item) => {
      return {
        id: item.id_est_conservacion,
        name: item.vc_nombre + " (" + item.vc_institucion + ")",
        icon: item.vc_imagen_estado,
      };
    }),
  ];
};

export const useConservation = () => {
  const [listConservation, setListConservation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getConservation = async () => {
    const response = await getListConservation().then((res) => res);
    if (response.status === 200) {
      setListConservation(transformData(response.data));
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getConservation();
  }, []);

  return {
    listConservation,
    error,
    loading,
  };
};
