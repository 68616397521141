import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Stack,
  Container,
  useMediaQuery,
  Tooltip,
  Avatar,
  Chip,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Link } from "react-router-dom";
import { getListCategories } from "../../infrastructure/api/client/categories";
import { routesApp } from "../../utils/constants";
import Diversity3Icon from "@mui/icons-material/Diversity3";

import { stylesSections } from "../../styles";
import { ModalStaff } from "./Staff/modalStaff";

const getSpeciesColor = (speciesName) => {
  const colorMap = {
    Aves: "#F36A2A",
    Mamiferos: "#886D5B",
    Anfibios: "#3B8476",
    Reptiles: "#77A69D",
    Peces: "#0E69A8",
    Insectos: "#E3B409",
    Arboles: "#4CB848",
    Palmeras: "#8C62AA",
  };

  return colorMap[speciesName] || "#000000";
};

const linksFooter = [
  { to: "/", text: "Ir a inicio" },
  { to: routesApp.species, text: "Consultar especies" },
  { to: routesApp.authors, text: "Conocer todos los autores" },
  { to: routesApp.communities, text: "Consultar comunidades indígenas" },
];

const socialMedia = [
  { to: "https://web.facebook.com/IIAPPERU", icon: <FacebookIcon /> },
  {
    to: "https://www.linkedin.com/company/instituto-de-investigaci%C3%B3n-de-la-amazon%C3%ADa-peruana-iiap/",
    icon: <LinkedInIcon />,
  },
  { to: "https://www.google.com", icon: <GoogleIcon /> },
  { to: "https://www.instagram.com/iiapperu/", icon: <InstagramIcon /> },
];

const sponsors = [
  { to: "https://www.gob.pe/iiap", image: "/images/iiap_logo.webp" },
  { to: "https://www.gob.pe/minam", image: "/images/logo_minam.webp" },
  {
    to: "https://www.cooperacionespanola.es/",
    image: "/images/logo_spain.webp",
  },
];

const styleFooter = {
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    marginBottom: "0.8rem",
    color: "#ffffff",
    "&:hover": {
      //add color yellow
      color: "#F9C440",
      textDecoration: "none",
    },
  },
};

export default function Footer() {
  const [apiData, setApiData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    getListCategories().then((res) => {
      setApiData(res.data);
    });
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const categories =
    apiData.length > 0
      ? apiData?.map((data) => ({
          id: data?.id_taxonomia,
          name: data?.vc_nombre,
          quantity: data?.in_total_especies,
          color: getSpeciesColor(data?.vc_nombre),
          taxaIcono: data?.vc_imagen,
        }))
      : null;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(18, 73, 29)",
          // backgroundImage: `url("/images/imgFooter.png")`,
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ ...stylesSections.sectionGeneral, color: "#ffffff" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography sx={styleFooter.title}>
                  Links Importantes
                </Typography>
                {linksFooter.map((link, i) => (
                  <Link to={link.to} key={i}>
                    <Typography sx={styleFooter.link}>{link.text}</Typography>
                  </Link>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography sx={styleFooter.title}>
                  Categorias de especies
                </Typography>
                <Box>
                  <Grid container spacing={2} sx={{ pt: "1rem" }}>
                    {categories
                      ? categories?.map((category) => (
                          <Grid
                            key={category.id}
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                          >
                            <Tooltip title={category.name} placement="top">
                              <Avatar
                                sx={{
                                  width: 24,
                                  height: 24,
                                  padding: 1,
                                  borderRadius: "8px",
                                  backgroundColor: category.color,
                                }}
                              >
                                <img
                                  src={category.taxaIcono}
                                  alt={category.name}
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                  }}
                                />
                              </Avatar>
                            </Tooltip>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography sx={styleFooter.title}>
                  Conéctate con el IIAP
                </Typography>
                <Box
                  component={Link}
                  to={"https://maps.app.goo.gl/54BqgvuT7PdgWY8C7"}
                  target="_blank"
                  sx={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#ffffff",
                  }}
                >
                  <PlaceOutlinedIcon
                    fontSize="medium"
                    sx={{ marginRight: "0.5rem" }}
                  />
                  <Typography sx={styleFooter.link}>
                    Carretera Iquitos - Nauta Km 4.5, Quistococha, Distrito de
                    San Juan Bautista, Maynas, Loreto
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <EmailSharpIcon
                    fontSize="medium"
                    sx={{ marginRight: "0.5rem" }}
                  />
                  <Typography sx={styleFooter.link}>
                    mmartin@iiap.gob.pe
                  </Typography>
                </Box>
                <Chip
                  label="Staff - GESCON IIAP"
                  icon={<Diversity3Icon color="inherit" />}
                  sx={{ color: "#ffffff", borderRadius: 2 }}
                  onClick={() => handleModal()}
                />
                <Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {socialMedia?.map((social, i) => (
                      <Link to={social.to} key={i} target="_blank">
                        <Box sx={styleFooter.link}>{social.icon}</Box>
                      </Link>
                    ))}
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography sx={styleFooter.title}>Colaboradores</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {sponsors?.map((sponsor, i) => (
                    <Link to={sponsor.to} key={i} target="_blank">
                      <Box
                        sx={{
                          height: { xs: "100%", sm: "38px", lg: "38px" },
                          mb: 1,
                        }}
                      >
                        <img
                          src={sponsor.image}
                          alt="sponsors"
                          style={{
                            maxWidth: "100%",
                            height: isMobile ? "auto" : "100%",
                            // height: "auto",
                          }}
                        />
                      </Box>
                    </Link>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#000000" }}>
        <Container maxWidth="xl">
          <Typography
            variant="caption"
            component="div"
            align="center"
            padding={0.5}
            color={"#ffffff"}
          >
            INSTITUTO DE INVESTIGACIONES DE LA AMAZONÍA PERUANA Av. José A.
            Quiñones km. 2.5 - Apartado Postal 784 TODOS LOS DERECHOS RESERVADOS
            © IQUITOS - PERÚ - {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>
      <ModalStaff open={openModal} onClose={() => handleModal()} />
    </>
  );
}
