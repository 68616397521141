import { useState, useEffect } from "react";
import {
  getListAuthors,
  getAuthorById,
  deleteAuthor,
  saveOrEditAuthor,
  addUpdateImgAuthor,
  deleteImgAuthor,
} from "../../api";
const token = localStorage.getItem("token_raices");

export const useAuthorsAdmin = () => {
  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState({});
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const handleResponse = (response, successMessage, errorMessage) => {
    setLoading(false);
    if (response && response.status === 200) {
      setMessage({
        ...message,
        status: response.status,
        message: successMessage,
      });
    } else {
      setMessage({
        ...message,
        status: response.status,
        message: errorMessage,
      });
    }
  };

  const getAuthorsData = async (search, page, size) => {
    setLoading(true);
    const response = await getListAuthors(token, search, page, size);
    if (response && response.status === 200) {
      setAuthors(response.data);
    } else {
      setAuthors([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const getDataAuthorById = async (id) => {
    setLoading(true);
    const response = await getAuthorById(token, id);
    if (response && response.status === 200) {
      setAuthor(response.data);
    } else {
      setAuthor({});
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const editAuthorData = async (body, id) => {
    setLoading(true);
    const response = await saveOrEditAuthor(token, body, id);
    handleResponse(
      response,
      "Se ha editado correctamente",
      "No se ha podido editar"
    );
  };

  const deleteAuthorData = async (id, body) => {
    setLoading(true);
    const response = await deleteAuthor(token, id, body);
    handleResponse(
      response,
      "Se ha eliminado correctamente",
      "No se ha podido eliminar"
    );
  };

  const addUpdateAuthorImg = async (id, file) => {
    setLoading(true);
    const response = await addUpdateImgAuthor(token, id, { vc_imagen: file });
    handleResponse(
      response,
      "Se ha actualizado correctamente",
      "No se ha podido actualizar"
    );
  };

  const deleteAuthorImg = async (id) => {
    setLoading(true);
    const response = await deleteImgAuthor(token, id);
    handleResponse(
      response,
      "Se ha eliminado correctamente",
      "No se ha podido eliminar"
    );
  };

  return {
    getAuthorsData,
    authors,
    getDataAuthorById,
    author,
    //For crud author
    editAuthorData,
    deleteAuthorData,
    addUpdateAuthorImg,
    deleteAuthorImg,
    // response,
    loading,
    message,
  };
};
