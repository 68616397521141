import IndexDetailSpecie from "../components/Species/[id]/IndexDetailSpecie";
const SpeciesView = () => {
  return (
    <>
      <IndexDetailSpecie />
    </>
  );
};

export default SpeciesView;
