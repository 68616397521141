/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
// import { useParams, useLocation } from "react-router-dom";
// import { transformDataAuthors } from "./transformsData";

import { usePagination } from "../../hooks/admin/usePagination";
import { useCategoriesAdmin } from "../../hooks/admin/useCategories";

const CategoriesContext = createContext();

const token = localStorage.getItem("token_raices");

const transformDataToTable = (data) => {
  if (data.length > 0) {
    const newData = data.map((item) => {
      return {
        id: item.id_taxonomia,
        name: item.vc_nombre,
        image: item.vc_imagen,
        status: parseInt(item.ch_estado),
      };
    });
    return newData;
  } else {
    return [];
  }
};

const CategoriesAdminProvider = ({ children }) => {
  const { getParams, searchParams, updateUrlParam } = usePagination();
  const {
    categories,
    category,
    getCategoriesData,
    getDataCategoryById,
    loading,
  } = useCategoriesAdmin();
  const [listCategories, setListCategories] = useState({
    data: [],
  });
  const id_category = getParams("id");

  const search = searchParams.get("search");
  useEffect(() => {
    getCategoriesData(token, search);
  }, [search]);

  useEffect(() => {
    if (categories.length > 0) {
      const newData = transformDataToTable(categories);
      setListCategories({
        ...listCategories,
        data: newData,
      });
    }
  }, [categories]);

  useEffect(() => {
    if (id_category) {
      getDataCategoryById(token, id_category);
    }
  }, [id_category]);

  const selecteCategory = (id) => {
    updateUrlParam("id", id);
  };

  return (
    <CategoriesContext.Provider
      value={{
        loading,
        listCategories,
        selecteCategory,
        updateUrlParam,
        category,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export { CategoriesAdminProvider };
export const useLogicCategorieContext = () => useContext(CategoriesContext);
