/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CustonSnackBar, TableListSection } from "../../../../../components";
import { useLogicCommunitiesAdmin } from "../../../../../context/admin/CommunitiesProvider";
import { usePagination } from "../../../../../hooks/admin/usePagination";

const ListTowns = () => {
  const navigate = useNavigate();
  const {
    getListData,
    dataDefault,
    listDataCommunities,
    deleteCommunity,
    loading,
    message,
  } = useLogicCommunitiesAdmin();
  const [open, setOpen] = React.useState(false);
  const { updateUrlParam } = usePagination();

  const severity = message?.status === 200 ? "success" : "error";

  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    if (message?.message !== "") {
      setOpen(true);
    }
  }, [message]);

  const changeToEdit = (id) => {
    navigate(`edit/${id}`);
  };

  const changeToView = (id) => {
    navigate(`details/${id}`);
  };

  const confirmDelete = (value, data) => {
    if (value === true) {
      const status = data.status === 1 ? 0 : 1;
      deleteCommunity(data.id, { ch_estado: status });
    }
  };

  return (
    <>
      <TableListSection
        loading={loading}
        data={listDataCommunities}
        dataDefault={dataDefault}
        onChangeSearch={(value) => updateUrlParam("search", value)}
        handlePage={(value) => updateUrlParam("page", value)}
        handleSize={(value) => updateUrlParam("size", value)}
        confirmDelete={(value, data) => confirmDelete(value, data)}
        onChangeView={(value) => changeToView(value)}
        onChangeEdit={(value) => changeToEdit(value)}
      />
      <CustonSnackBar
        open={open}
        onClose={() => setOpen(false)}
        message={message?.message}
        severity={severity}
      />
    </>
  );
};

export default ListTowns;
