/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@mui/material";

import { stylesFonts } from "../../../styles";
// import { validateInput } from "../../../../../../infrastructure/ui/validatorInputs";

// import useSpecies from "../../../../../utils/hooks/useSpecies";

const SectionDetails = ({ data, handleChange }) => {
  // // const { handleValuesSpecie } = useSpecies();

  // useEffect(() => {
  //   setFieldErrors({
  //     vc_nombre: "",
  //     vc_nombre_ingles: "",
  //     vc_nombre_cientifico: "",
  //     vc_ano: "",
  //     te_descripcion: "",
  //   });
  // }, []);

  // const validateInputNotNull = (value, fun, type) => {
  //   const { message } = validateInput(value, fun);
  //   setFieldErrors({ ...fieldErrors, [type]: message });
  // };

  return (
    <>
      <Typography sx={stylesFonts.titleSection}>
        Detalles de la especie
      </Typography>
      {/* {warningMessage !== "" ? (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          {warningMessage}
        </Alert>
      ) : null} */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>Nombre común</Typography>
          <TextField
            fullWidth
            placeholder={"Escribe el nombre común..."}
            // size="small"
            name="vc_nombre"
            value={data.vc_nombre}
            onChange={handleChange}
            // error={fieldErrors.vc_nombre !== ""}
            // helperText={fieldErrors.vc_nombre || "(Obligatorio)"}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>
            Nombre en inglés
          </Typography>
          <TextField
            fullWidth
            // size="small"
            placeholder={"Escribe el nombre en inglés..."}
            value={data.vc_nombre_ingles}
            name="vc_nombre_ingles"
            onChange={handleChange}
            // error={fieldErrors.vc_nombre_ingles !== ""}
            // helperText={fieldErrors.vc_nombre_ingles || "(Obligatorio))"}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>
            Nombre científico
          </Typography>
          <TextField
            fullWidth
            // size="small"
            placeholder={"Escribe el nombre científico..."}
            value={data.vc_nombre_cientifico}
            name="vc_nombre_cientifico"
            onChange={handleChange}
            // error={fieldErrors.vc_nombre_cientifico !== ""}
            // helperText={fieldErrors.vc_nombre_cientifico || "(Obligatorio)"}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>Año</Typography>
          <TextField
            fullWidth
            // size="small"
            placeholder={"Escribe el año..."}
            type="number"
            value={data.vc_ano}
            name="vc_ano"
            onChange={handleChange}
            // error={fieldErrors.vc_ano !== ""}
            // helperText={fieldErrors.vc_ano || "(Opcional)"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionDetails;
