/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { useTaxonomies } from "../../hooks/clients/useTaxonomies";
import { useLogicSpecies } from "./speciesProvider";
const TaxonomiesContext = createContext();

const TaxonomiesProvider = ({ children }) => {
  const { updateUrlParam, getFilters } = useLogicSpecies();

  const {
    getListDataClass,
    getListDataFamily,
    getListDataOrder,
    getListDataOrderByIdClass,
    getListDataFamilyByIdOrder,
    taxonomies,
    loading,
  } = useTaxonomies();
  const classSeleted = getFilters("clase", "");
  const orderSeleted = getFilters("orden", "");
  const familySeleted = getFilters("familia", "");

  const taxaSelected = {
    class: parseInt(classSeleted),
    order: parseInt(orderSeleted),
    family: parseInt(familySeleted),
  };

  useEffect(() => {
    getListDataClass();
    getListDataFamily();
    getListDataOrder();
  }, []);

  const setOptionSelected = (name, value) => {
    updateUrlParam(name, value);
  };

  useEffect(() => {
    if (classSeleted) {
      getListDataOrderByIdClass(classSeleted);
    } else {
      getListDataOrder();
    }
  }, [classSeleted]);

  useEffect(() => {
    if (orderSeleted) {
      getListDataFamilyByIdOrder(orderSeleted);
    } else {
      getListDataFamily();
    }
  }, [orderSeleted]);

  return (
    <TaxonomiesContext.Provider
      value={{
        taxonomies,
        taxaSelected,
        loading,
        setOptionSelected,
      }}
    >
      {children}
    </TaxonomiesContext.Provider>
  );
};

export { TaxonomiesProvider };
export const useLogicTaxonomies = () => useContext(TaxonomiesContext);
