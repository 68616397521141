import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Button,
  CardActionArea,
  Chip,
  Skeleton,
} from "@mui/material";

import { stylesFont } from "../../styles";

export const CardDetails = ({ data, height, isDark, isItalic, onClick }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleButtonClick = () => {
    onClick(data?.id);
  };

  const textColor = isDark ? "white" : "black";
  const textFont = stylesFont.fontFamily.primary;

  return (
    <>
      <CardActionArea
        onClick={onClick && handleButtonClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          paddingBottom: 0,
          "&:hover": {
            borderRadius: "0.5rem",
            cursor: "pointer",
          },
        }}
      >
        <Card
          sx={{
            borderRadius: "0.5rem",
            position: "relative", // Para posicionar elementos relativos al Card
            // maxHeight: 290,
            height: height ? height : 290,
          }}
        >
          {!imgLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={height ? height : 290}
            />
          )}
          <Box
            component="img"
            onLoad={() => setImgLoaded(true)}
            alt={data?.title}
            src={
              data?.image ? data?.image : "/images/errorsImages/not_image.webp"
            }
            sx={{
              display: imgLoaded ? "block" : "none",
              width: "100%",
              // height: height ? height : 290,
              height: "100%",
              objectFit: "cover",
              position: "relative", // Asegura que la imagen sea parte del flujo de contenido
              zIndex: 0, // Coloca la imagen en el fondo
              objectPosition: "center",
              "&:hover": {
                transform: "scale(1.1)",
                transition: "transform 0.3s ease-in-out",
              },
            }}
          />
          {data?.tag && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                p: 1,
                zIndex: 1, // Coloca el Chip en la parte superior
              }}
            >
              <Chip
                label={data?.tag}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "0.5rem",
                  color: "white",
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              p: 2,
              pb: 1.5,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              minHeight: 40,
              backgroundColor: isHovered
                ? isDark
                  ? "rgba(0, 0, 0, 0.8)"
                  : "rgba(255, 255, 255, 0.9)"
                : isDark
                ? "rgba(0, 0, 0, 0.5)"
                : "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Typography
              color={textColor}
              variant="caption"
              fontFamily={textFont}
            >
              {data?.caption}
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={900}
              color={textColor}
              fontFamily={textFont}
            >
              {data?.title}
            </Typography>
            <Typography
              variant="subtitle2"
              color={textColor}
              fontFamily={textFont}
              fontStyle={isItalic ? "italic" : "normal"}
              maxWidth={100}
            >
              {data?.subtitle}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                p: 1,
                zIndex: 1,
              }}
            >
              <Button
                size="small"
                sx={{
                  color: isDark ? "white" : "primary.main",
                  borderColor: "white",
                  borderRadius: "0.5rem",
                  fontSize: "0.6rem",
                  fontWeight: 900,
                }}
              >
                Ver más
              </Button>
            </Box>
          </Box>
        </Card>
      </CardActionArea>
    </>
  );
};
