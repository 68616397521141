/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { Typography, Badge, Menu, MenuItem, Toolbar, Box } from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";

//import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";

// import useAuth from "../../utils/hooks/useAuth";
import axios from "axios";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarAdmin = ({ open, toggleDrawer }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    // const { auth } = useAuth();
    const token = localStorage.getItem("token_raices"); // Obtiene el token del almacenamiento local
    const user_name = localStorage.getItem("user_name"); // Obtiene el nombre del usuario del almacenamiento local
    const apiUrl = `${process.env.REACT_APP_API_URL}/users/`; // Ruta del API

    // console.log(auth);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${apiUrl}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token_raices");
        localStorage.removeItem("user_name");
        window.location.href = "/login";
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar
                position="absolute"
                open={open}
                sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(5px)",
                }}
            >
                <Toolbar
                    sx={{
                        pr: "24px",
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: "36px",
                            ...(open && { display: "none" }),
                            backgroundColor: "white",
                            "&:hover": {
                                backgroundColor: "white",
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            },
                        }}
                    >
                        <MenuIcon sx={{ color: "black" }} />
                    </IconButton>

                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                        width={"100%"}
                    >
                        <Typography
                            sx={{
                                color: "black",
                                borderRadius: "10px",
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "1rem",
                                    sm: "1.1rem",
                                    md: "1.3rem",
                                    lg: "1.4rem",
                                },
                            }}
                        >
                            {user_name || "?"}
                        </Typography>
                        <IconButton color="inherit" onClick={handleMenuOpen}>
                            <Badge badgeContent={0} color="success">
                                <AccountCircleIcon
                                    fontSize="inherit"
                                    sx={{ color: "#668713" }}
                                />
                            </Badge>
                        </IconButton>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleMenuClose}>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                Volver a Inicio
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <Link
                                to="#"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                Cerrar Sesión
                            </Link>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default AppBarAdmin;
