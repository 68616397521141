/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import {
  getListDataClass,
  getListOrder,
  getListFamily,
} from "../../infrastructure/api/DataTaxonomia";

const TaxonomyContext = createContext();

const TaxonomyProvider = ({ children }) => {
  const [LoadingTaxas, setLoadingTaxas] = useState(false);
  const [listTaxasForOptions, setListTaxasForOptions] = useState({
    listClass: [],
    listOrder: [],
    listFamily: [],
  });

  const [taxaSelected, setTaxaSelected] = useState({
    clase: {},
    orden: {},
    familia: {},
  });

  const transformData = (data, nameKey, nameFkey) => {
    return data?.map((item) => {
      return {
        id: item[`id_${nameKey}`],
        fk_id: item[`id_${nameFkey}`],
        label: item.vc_nombre,
        value: item[`id_${nameKey}`],
      };
    });
  };

  const LoadDataForOptions = async (
    dataLoader,
    nameList,
    nameKey,
    nameFkey
  ) => {
    dataLoader().then((response) => {
      setListTaxasForOptions((prevState) => ({
        ...prevState,
        [nameList]: transformData(response, nameKey, nameFkey),
      }));
    });
  };

  const getData = async (dataLoader, nameKey, nameFkey) => {
    const response = await dataLoader();
    return transformData(response, nameKey, nameFkey);
  };

  useEffect(() => {
    LoadDataForOptions(getListDataClass, "listClass", "clase", "filo");
    LoadDataForOptions(getListOrder, "listOrder", "orden", "clase");
    LoadDataForOptions(getListFamily, "listFamily", "familia", "orden");
  }, []);

  const setOptionSelected = (data, nameKey) => {
    setTaxaSelected((prevState) => ({
      ...prevState,
      [nameKey]: data ? data : {},
    }));
  };

  //Filters
  const filterData = (data, id) => {
    return data?.filter((item) => item.fk_id === id);
  };

  const filterPrevData = (data, fk_id) => {
    return data?.filter((item) => item.id === fk_id);
  };

  //Filters for options
  //Filter for clase options
  useEffect(() => {
    setLoadingTaxas(true);
    const { id: id_clase } = taxaSelected?.clase || {};
    const { id: id_orden } = taxaSelected?.orden || {};
    const { id: id_familia } = taxaSelected?.familia || {};
    // console.log("id_clase", id_clase);
    // console.log("id_orden", id_orden);
    // console.log("id_familia", id_familia);

    const handleFilterOrders = async () => {
      if (id_clase) {
        if (id_clase && !id_orden && !id_familia) {
          try {
            const dataResponse = await getData(getListOrder, "orden", "clase");
            setListTaxasForOptions((prevState) => ({
              ...prevState,
              listOrder: filterData(dataResponse, id_clase),
            }));
            // setOptionSelected("", "orden");
          } catch (error) {
            console.error("Error al cargar datos", error);
          }
        }
      } else {
        try {
          setOptionSelected("", "orden");
          setOptionSelected("", "familia");
          await LoadDataForOptions(getListOrder, "listOrder", "orden", "clase");
          await LoadDataForOptions(
            getListFamily,
            "listFamily",
            "familia",
            "orden"
          );
        } catch (error) {
          console.error("Error al cargar datos", error);
        }
      }
    };

    handleFilterOrders();
    setLoadingTaxas(false);
  }, [taxaSelected?.clase]);

  //Filter for orden options
  useEffect(() => {
    setLoadingTaxas(true);
    const { id } = taxaSelected?.orden || {};

    const handleFilterFamily = async () => {
      if (id) {
        try {
          const dataResponse = await getData(getListFamily, "familia", "orden");
          setListTaxasForOptions((prevState) => ({
            ...prevState,
            listFamily: filterData(dataResponse, id),
          }));
        } catch (error) {
          console.error("Error al cargar datos", error);
        }
      } else {
        setOptionSelected("", "familia");
        try {
          await LoadDataForOptions(
            getListFamily,
            "listFamily",
            "familia",
            "orden"
          );
        } catch (error) {
          console.error("Error al cargar datos", error);
        }
      }
      setLoadingTaxas(false);
    };

    handleFilterFamily();
  }, [taxaSelected?.orden]);

  //Filter prev for orden options
  useEffect(() => {
    if (taxaSelected?.orden?.id) {
      const filterTaxa = filterPrevData(
        listTaxasForOptions?.listClass,
        taxaSelected?.orden?.fk_id
      );
      setTaxaSelected((prevState) => ({
        ...prevState,
        clase: filterTaxa[0],
      }));
    } else {
      LoadDataForOptions(getListDataClass, "listClass", "clase", "filo");
    }
  }, [taxaSelected?.orden]);

  useEffect(() => {
    if (taxaSelected?.familia?.id) {
      const filterTaxa = filterPrevData(
        listTaxasForOptions?.listOrder,
        taxaSelected?.familia?.fk_id
      );
      setTaxaSelected((prevState) => ({
        ...prevState,
        orden: filterTaxa[0],
      }));
    }
  }, [taxaSelected?.familia]);

  //Clear filters
  const clearFiltersTaxas = () => {
    setTaxaSelected({
      clase: {},
      orden: {},
      familia: {},
    });
  };

  return (
    <TaxonomyContext.Provider
      value={{
        listTaxasForOptions,
        LoadingTaxas,
        // For selected options
        setOptionSelected,
        taxaSelected,
        clearFiltersTaxas,
      }}
    >
      {children}
    </TaxonomyContext.Provider>
  );
};

export { TaxonomyProvider };
export default TaxonomyContext;
