export const stylesFont = {
  fontFamily: {
    primary: "Roboto, sans-serif",
  },
  titleExtraLarge: {
    color: "white",
    display: "block",
    fontSize: { xs: "2rem", sm: "2.6rem", md: "2.9rem", lg: "3.2rem" },
    fontWeight: 900,
    textAlign: "center",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    fontFamily: "Roboto",
  },
  subtitleExtraLarge: {
    color: "white",
    fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem", lg: "1.5rem" },
    textAlign: "center",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    fontFamily: "Roboto, sans-serif",
  },
  titleSectionAbout: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "primary.main",
    lineHeight: "1.2",
    fontFamily: "Roboto, sans-serif",
  },
  subTitleSectionAbout: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#8EB63E",
    fontFamily: "Roboto, sans-serif",
  },
  titleSection: {
    fontSize: { xs: "1.1rem", sm: "1.4rem" },
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
  },
  subTitleSection: {
    fontSize: { xs: "1rem", sm: "1.2rem" },
    color: "#666",
    textTransform: "uppercase",
    fontFamily: "Roboto, sans-serif",
  },
  text: {
    fontSize: { xs: "0.8rem", sm: "1rem" },
    textAlign: "justify",
    fontFamily: "Roboto, sans-serif",
  },
};

export const styleDivider = {
  dividerHome: {
    width: "90px",
    backgroundColor: "#8B0000",
    borderWidth: "4px",
    margin: "0 auto",
    borderRadius: "4px",
    marginY: "1rem",
  },
};

export const stylesSections = {
  sectionGeneral: {
    paddingY: { xs: "1rem", sm: "2rem", lg: "4rem" },
    pb: { xs: "1.5rem", sm: "4rem" },
  },
  sectionTitleSubTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "center",
    textAlign: "center",
  },
};

export const stylesCard = {
  titleCard: {
    fontSize: { xs: "1rem", sm: "1.1rem" },
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
  },
};
