import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems } from "./listItems";
import { Box, Typography } from "@mui/material";

const drawerWidth = 240;
const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
export default function DrawerAdmin({ open, toggleDrawer }) {
  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#1F1F1F",
            color: "white",
          },
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "#1F1F1F",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <Box>
            <img
              src="/favicon/favicon-32x32.png"
              alt="logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
            color="white"
            fontWeight="bold"
          >
            Administrador
          </Typography>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon sx={{ color: "white", fontSize: "35px" }} />
          </IconButton>
        </Toolbar>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            position: "relative",
          }}
          component="nav"
        >
          <MainListItems />
        </List>
      </Drawer>
    </>
  );
}
