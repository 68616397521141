import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <Box paddingY={10} alignItems={"center"}>
        <Box alignContent={"center"} justifyContent={"center"} display={"flex"}>
          <lottie-player
            src="https://lottie.host/6ec1e66e-7cce-43b3-8b2f-723ac8b177da/kqbvnEG6DH.json"
            background="transparent"
            speed="1"
            style={{ width: "400px", height: "300px" }}
            loop
            autoplay
          ></lottie-player>
          <lottie-player
            src="https://lottie.host/721398f1-f4f6-4211-bb86-2a9d849851fa/aV3ZnCiBLz.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px" }}
            loop
            autoplay
          ></lottie-player>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            fontWeight={800}
            sx={{ paddingBottom: 4 }}
          >
            Lo sentimos página no encontrada
          </Typography>
          <Button variant="contained" LinkComponent={Link} to="/">
            Ir a inicio
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NotFoundPage;
