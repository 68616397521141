import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getListCommunitiesForPagination = async (
  currentPage,
  sizePage,
  search
) => {
  const apiUrl = search
    ? `${API_BASE_URL}/indigenous-community/search/${search}/${currentPage}/${sizePage}`
    : `${API_BASE_URL}/indigenous-community/${currentPage}/${sizePage}`;

  const response = await axios
    .get(apiUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("List Communities not found");
        return null;
      }

      throw err;
    });
  return response;
};

export const GetCommunityById = async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}/indigenous-community/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Community not found");
        return null;
      }

      throw err;
    });
  return response;
};
