import { TextField } from "@mui/material";

const styleTextFields = {
  borderRadius: "8px", // Bordes redondeados
  fullWidth: true,
  marginY: "10px", // Margen vertical
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#015E80", // Borde gris
      borderRadius: "8px", // Bordes redondeados
      paddingY: "3px", // Padding vertical del input
    },
    "&:hover fieldset": {
      borderColor: "#015E80", // Borde  al hacer hover
    },
    // "&.Mui-focused fieldset": {
    //   borderColor: "#015E80", // Borde  cuando está enfocado
    // },
  },
  "& .MuiOutlinedInput-input": {
    color: "gray", // Texto en color blanco
  },
  "& .MuiInputLabel-root": {
    color: "#015E80", // Etiqueta en color blanco
  },
};
const TextFieldCustom = ({ label, name, placeholder, value, ...props }) => {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      // focused
      margin="dense"
      value={value}
      sx={styleTextFields}
      name={name}
      error={props.error}
      {...props}
    />
  );
};

export default TextFieldCustom;
