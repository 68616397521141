import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

const styles = {
  styleButton: {
    border: "1px solid",
    "&:hover": {
      border: "2px solid",
    },
    fontWeight: "bold",
  },
  titleDialog: {
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  textDialog: {
    fontFamily: "Poppins",
  },
};

export const CustonDialogAdmin = ({
  title,
  message,
  open,
  onClose,
  children,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={styles.titleDialog}>{title}</DialogTitle>
      <DialogContent sx={{ px: 3, py: 1 }}>
        {message ? (
          <DialogContentText sx={styles.textDialog}>
            {message}
          </DialogContentText>
        ) : (
          <Box>{children}</Box>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button
          size="small"
          variant="outlined"
          sx={styles.styleButton}
          onClick={props.handleAction}
        >
          {props.isDelete ? "Eliminar" : "Guardar"}
        </Button>
        <Button
          size="small"
          variant="outlined"
          sx={styles.styleButton}
          onClick={onClose}
          color="error"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
