/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { stylesFonts } from "../../../styles";
import AutocompleteCustom from "../../../../../../components/Admin/AutocompleteCustom";

import { useCategories } from "../../../../../../hooks/clients/useCategories";
import { useTaxonomies } from "../../../../../../hooks/clients/useTaxonomies";

const SectionTaxonomia = ({ data, otherData, handleChange }) => {
  const { getCategories, listForAutocomplete } = useCategories();
  const { getListDataClass, getListDataFamily, getListDataOrder, taxonomies } =
    useTaxonomies();

  useEffect(() => {
    getListDataClass();
    getListDataFamily();
    getListDataOrder();
    getCategories();
  }, []);

  return (
    <>
      <Typography sx={stylesFonts.titleSection}>Taxonomía</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>
            Tipo (Categoría)
          </Typography>
          <AutocompleteCustom
            placeholder={"Selecciona el tipo..."}
            options={listForAutocomplete}
            value={data.id_taxonomia}
            name="id_tipo"
            onChange={(e, value) =>
              handleChange("id_taxonomia", value ? value?.id : "")
            }
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>Clase</Typography>

          <AutocompleteCustom
            label="Clase"
            placeholder={"Selecciona el Clase.."}
            options={taxonomies.listClass}
            value={otherData?.id_clase}
            onChange={(e, value) =>
              handleChange("id_clase", value ? value?.id : "", true)
            }
            // value={selectedIds?.id_clase}
            // disabled={selectedIds?.id_orden ? true : false}
            // onChange={(e, value) => {
            //   if (value) {
            //     setIdSelected("id_clase", value?.id);
            //   } else {
            //     setIdSelected("id_clase", "");
            //   }
            // }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>Orden</Typography>

          <AutocompleteCustom
            label="Orden"
            placeholder={"Selecciona el Orden.."}
            options={taxonomies.listOrder}
            value={otherData?.id_orden}
            onChange={(e, value) =>
              handleChange("id_orden", value ? value?.id : "", true)
            }
            // disabled={selectedIds?.id_familia ? true : false}
            // onChange={(e, value) => {
            //   if (value) {
            //     setIdSelected("id_orden", value?.id);
            //   } else {
            //     setIdSelected("id_orden", "");
            //   }
            // }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Typography sx={stylesFonts.subtitleSection}>Familia</Typography>
          <AutocompleteCustom
            label="Familia"
            placeholder={"Selecciona el Familia.."}
            options={taxonomies.listFamily}
            value={data.id_familia}
            name="id_familia"
            onChange={(e, value) =>
              handleChange("id_familia", value ? value?.id : "")
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionTaxonomia;
