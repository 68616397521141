import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getListTown = async (token, search, currentPage, sizePage) => {
  const apiUrl = search
    ? `${API_BASE_URL}/indigenous-community/admin/search/${search}/${currentPage}/${sizePage}`
    : `${API_BASE_URL}/indigenous-community/admin/${currentPage}/${sizePage}`;
  try {
    const response = await axios.get(apiUrl, axiosConfig(token));
    return response;
  } catch (error) {
    console.log("Get List Town", error);
    return error;
  }
};

export const getTownById = async (token, id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/indigenous-community/${id}`,
      axiosConfig(token)
    );

    return response;
  } catch (error) {
    console.log("Get Town", error);
    return error;
  }
};

export const saveOrEditTown = async (token, id, body) => {
  const apiUrl = id
    ? `${API_BASE_URL}/indigenous-community/edit/${id}`
    : `${API_BASE_URL}/indigenous-community/create`;

  const method = id ? "patch" : "post";
  try {
    const response = await axios[method](apiUrl, body, axiosConfig(token));
    return response;
  } catch (error) {
    console.log("Save or Edit Town", error);
    return error;
  }
};

export const deleteTown = async (token, id, body) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/indigenous-community/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      }
    );
    return response;
  } catch (error) {
    console.log("Delete Town", error);
    return error;
  }
};

export const addUpdateImgTown = async (token, body, id) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/indigenous-community/addUpdateImg/${id}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteImgTown = async (token, id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/indigenous-community/deleteImg/${id}`,
      axiosConfig(token)
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
