/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { transformConservation } from "./transformsData";

import { usePagination } from "../../hooks/admin/usePagination";
import { useConservationAdmin } from "../../hooks";

const ConservationContext = createContext();

const ConservationAdminProvider = ({ children }) => {
  const { getParams, searchParams } = usePagination();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const hasStatus = location.pathname.includes("status-conservation");
  const id_conservation = hasStatus ? id : null;
  const {
    conservations,
    conservation,
    getConservationData,
    getDataConservationById,
    saveOrEditConservationData,
    deleteConservationData,
    addUpdateOrDeleteImg,
    // addUpdateOrDeleteImg,
    // response,
    loading,
    message,
  } = useConservationAdmin();

  const [listConservation, setListConservation] = useState({
    data: [],
    totalItems: 0,
    totalPages: 0,
  });

  const dataDefault = {
    currentPage: getParams("page", 1),
    sizePage: getParams("size", 15),
    search: searchParams.get("search", ""),
  };

  const getListData = () => {
    getConservationData(
      dataDefault.search,
      dataDefault.currentPage,
      dataDefault.sizePage
    );
  };

  useEffect(() => {
    if (hasStatus) {
      getListData();
    }
  }, [searchParams]);

  const getDataConservation = () => {
    getDataConservationById(id_conservation);
  };

  useEffect(() => {
    if (id_conservation) {
      getDataConservation();
    }
  }, [id_conservation]);

  useEffect(() => {
    setListConservation({
      ...listConservation,
      data: transformConservation(conservations?.statusData),
      totalPages: conservations?.totalPages,
      totalItems: conservations?.totalItems,
    });
  }, [conservations?.statusData]);

  const saveOrEditConservation = async (body, id) => {
    await saveOrEditConservationData(body, id);
    if (id) {
      navigate("/admin/status-conservation");
    } else {
      navigate("/admin/status-conservation");
    }
    // getListData();
  };

  const deleteConservation = async (id, body) => {
    await deleteConservationData(id, body);
    getListData();
  };

  const updateOrDeleteImg = async (id, file) => {
    await addUpdateOrDeleteImg(id, file);
    getDataConservation();
  };

  return (
    <ConservationContext.Provider
      value={{
        dataDefault,
        getListData,
        getDataConservation,
        id_conservation,
        listConservation,
        conservation,
        //For crud
        deleteConservation,
        saveOrEditConservation,
        addUpdateOrDeleteImg,
        updateOrDeleteImg,
        // addUpdateOrDeleteImage,
        //REPONSE
        loading,
        message,
      }}
    >
      {children}
    </ConservationContext.Provider>
  );
};

export { ConservationAdminProvider };
export const useLogicConservationAdmin = () => useContext(ConservationContext);
