// import MoreInfoSection from "../components/CommunityPage/sections/moreInfoSection";
// import InfoSection from "../components/CommunityPage/sections/infoSection";
// import ConsultSection from "../components/CommunityPage/sections/consultSection";
// import BannerSection from "../../components/BannerSection";
// import MoreTablesSection from "../components/CommunityPage/sections/moreTablesSection";
import IndexCommunity from "../components/Community/listCommunity/indexCommunity";

export default function ComunityPage() {
  // const handleButtonClick = () => {
  //   console.log("click");
  //   const consultSection = document.getElementById("consult-section");
  //   if (consultSection) {
  //     consultSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  return (
    // <div>
    //   <BannerSection
    //     title="Comunidades"
    //     image={"/images/img2.avif"}
    //     currentPage={"Comunidades"}
    //   />
    //   <InfoSection />
    //   <ConsultSection id={"consult-section"} />
    //   <MoreInfoSection />
    //   <MoreTablesSection onButtonClick={handleButtonClick} />
    // </div>
    <>
      <IndexCommunity />
    </>
  );
}
