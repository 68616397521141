import { useState, useEffect } from "react";
import {
  getListClass,
  getClassById,
  saveOrEditClass,
  deleteClass,
  getListOrders,
  getOrderById,
  saveOrEditOrder,
  deleteOrder,
  getListFamilies,
  getFamilyById,
  saveOrEditFamily,
  deleteFamily,
  getListPhylumUSer,
  getListClassUSer,
  getListOrderUSer,
} from "../../api";

import { transformDataTaxonomies } from "../../context/admin/transformsData";
import { transformDataForOptions } from "../../context/taxonomiaProviders/logicProviderTaxas";

const token = localStorage.getItem("token_raices");

const getApiDetails = (typeApi) => {
  let apiUrl,
    data,
    key,
    fk_key,
    prevApiUrl,
    apiUrlId,
    apiUrlUpdate,
    apiUrlDelete;

  if (typeApi === "class") {
    apiUrl = getListClass;
    apiUrlId = getClassById;
    apiUrlUpdate = saveOrEditClass;
    apiUrlDelete = deleteClass;
    data = "clases";
    key = "clase";
    fk_key = "filo";
    prevApiUrl = getListPhylumUSer;
  } else if (typeApi === "order") {
    apiUrl = getListOrders;
    apiUrlId = getOrderById;
    apiUrlUpdate = saveOrEditOrder;
    apiUrlDelete = deleteOrder;
    data = "ordenes";
    key = "orden";
    fk_key = "clase";
    prevApiUrl = getListClassUSer;
  } else if (typeApi === "family") {
    apiUrl = getListFamilies;
    apiUrlId = getFamilyById;
    apiUrlUpdate = saveOrEditFamily;
    apiUrlDelete = deleteFamily;
    data = "familias";
    key = "familia";
    fk_key = "orden";
    prevApiUrl = getListOrderUSer;
  } else {
    // Handle the case when typeApi is unknown
    apiUrl = "";
    apiUrlId = "";
    apiUrlUpdate = "";
    apiUrlDelete = "";
    data = "";
    key = "";
    fk_key = "";
    prevApiUrl = "";
  }

  return {
    apiUrl,
    data,
    key,
    fk_key,
    prevApiUrl,
    apiUrlId,
    apiUrlUpdate,
    apiUrlDelete,
  };
};

export const useTaxasAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [listForOptions, setListForOptions] = useState([]);
  const [dataTaxa, setDataTaxa] = useState({});
  const [dataTaxas, setDataTaxas] = useState({
    data: [],
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
  });
  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const getListTaxas = async (typeApi, search, page, sizePage) => {
    setLoading(true);
    const { apiUrl, data, key, fk_key } = getApiDetails(typeApi);
    const response = await apiUrl(token, search, page, sizePage);

    if (response?.status === 200) {
      setDataTaxas({
        data: transformDataTaxonomies(response?.data[data], key, fk_key),
        currentPage: response?.data.currentPage,
        totalItems: response?.data.totalItems,
        totalPages: response?.data.totalPages,
      });
    } else {
      setDataTaxas({
        data: [],
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
      });
      setMessage({
        status: "error",
        message: response.message,
      });
    }
    setLoading(false);
  };

  const getListForOptions = async (typeApi) => {
    const { fk_key, prevApiUrl } = getApiDetails(typeApi);
    const response = await prevApiUrl();

    if (response?.status === 200) {
      setListForOptions(
        transformDataForOptions(response?.data, `id_${fk_key}`)
      );
    } else {
      setListForOptions([]);
      setMessage({
        status: "error",
        message: response.message,
      });
    }
  };

  const getTaxaBy = async (typeApi, id) => {
    setLoading(true);
    const { apiUrlId } = getApiDetails(typeApi);
    const response = await apiUrlId(token, id);

    if (response?.status === 200) {
      setDataTaxa({
        ...response?.data,
      });
    } else {
      setMessage({
        status: "error",
        message: response.message,
      });
    }
    setLoading(false);
  };

  const createOeEditTaxa = async (typeApi, key, id, data) => {
    setLoading(true);
    const id_taxa = id === "" ? null : id;
    const { apiUrlUpdate } = getApiDetails(typeApi);

    const body = {
      vc_nombre: data.vc_nombre,
      [`${key}`]: data.id_taxa,
    };

    const response = await apiUrlUpdate(token, id_taxa, body);

    if (response?.status === 201) {
      setMessage({
        status: "success",
        message: response.message,
      });
    } else {
      setMessage({
        status: "error",
        message: response.message,
      });
    }
    setLoading(false);
  };

  const deleteOrRestoreTaxa = async (typeApi, id, state) => {
    setLoading(true);
    const { apiUrlDelete } = getApiDetails(typeApi);
    const body = {
      ch_estado: state === 1 ? 0 : 1,
    };
    const response = await apiUrlDelete(token, id, body);

    if (response?.status === 200) {
      setMessage({
        status: "success",
        message: response.message,
      });
    } else {
      setMessage({
        status: "error",
        message: response.message,
      });
    }
    setLoading(false);
  };

  return {
    loading,
    getListTaxas,
    getListForOptions,
    getTaxaBy,
    createOeEditTaxa,
    deleteOrRestoreTaxa,
    message,
    listForOptions,
    dataTaxa,
    dataTaxas,
  };
};
