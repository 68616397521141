/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect } from "react";
import {
  CircularProgress,
  Divider,
  Box,
  Typography,
  TextField,
  Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ResultSearch from "./ResultSearch";
import { FetchDataSearchGeneral } from "../../infrastructure/api/axiosDataSearchGeneral";
import RecentSearch from "./RecentsSearch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: { xs: "100%", md: 600 },
  maxHeight: { xs: "90vh", md: 600 },
  width: "100%",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: { sm: 0, md: 4 },
};

export default function ModalSearch({ openModal, setOpenModal }) {
  const [textSearch, setTextSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dataSearch, setDataSearch] = React.useState({
    especies: [],
    autores: [],
    pueblosIndigenas: [],
  });

  // Agrega un estado para almacenar el temporizador
  const [timerId, setTimerId] = React.useState(null);

  const handleClose = () => {
    setOpenModal(false);
    setTextSearch("");
  };

  const transformData = (data, name_id, name_url, tag, sub_title, color) => {
    return data.map((item) => {
      return {
        id: item[name_id], // Accede al atributo dinámico utilizando la variable nameid
        image: item.vc_imagen || item.vc_image,
        title: item.vc_nombre,
        subTitle: item[sub_title],
        tag: tag,
        url: `/${name_url}/${item[name_id]}`, // También se puede utilizar para la URL si es el mismo atributo
        color: color,
      };
    });
  };

  const getDataSearch = async () => {
    setLoading(true);
    const data = await FetchDataSearchGeneral(textSearch);
    setDataSearch({
      ...dataSearch,
      especies: transformData(
        data?.especies ? data?.especies : [],
        "id_especie",
        "species",
        "Especie",
        "vc_nombre_cientifico"
      ),
      autores: transformData(
        data?.autores ? data?.autores : [],
        "id_autor",
        "authors",
        "Autor",
        "vc_apellido"
      ),
      pueblosIndigenas: transformData(
        data?.pueblosIndigenas ? data?.pueblosIndigenas : [],
        "id_pueblo_indigena",
        "community",
        "Pueblo indígena",
        "vc_nombre"
      ),
    });
    setLoading(false);
  };

  useEffect(() => {
    // Limpiar el temporizador antes de configurar uno nuevo
    if (timerId) {
      clearTimeout(timerId);
    }

    // Configurar un nuevo temporizador para esperar 3 segundos después de que el usuario deje de escribir
    const newTimerId = setTimeout(() => {
      if (textSearch !== "") {
        getDataSearch();
      }
    }, 1000);

    // Guardar el ID del temporizador en el estado
    setTimerId(newTimerId);

    // Limpiar el temporizador si el componente se desmonta o si el texto cambia antes de que se complete el temporizador
    return () => {
      if (newTimerId) {
        clearTimeout(newTimerId);
      }
    };
  }, [textSearch]);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        width: "100%",
      }}
    >
      <Box sx={style}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            padding: { xs: "8px 16px", md: 0 },
            marginBottom: { xs: "0px", md: "16px" },
          }}
        >
          <Box>
            {loading ? (
              <CircularProgress
                sx={{
                  marginRight: "8px",
                }}
              />
            ) : (
              <SearchIcon
                fontSize="medium"
                sx={{
                  color: "#015E80",
                  marginRight: "8px",
                }}
              />
            )}
          </Box>
          <TextField
            id="outlined-basic"
            placeholder="Buscar..."
            variant="standard"
            size="large"
            autoFocus
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            fullWidth
            sx={{
              color: "#015E80",
            }}
          />
          <Box
            component={"button"}
            onClick={() => handleClose()}
            sx={{
              backgroundColor: "rgba(1, 94, 128, 0.1)",
              p: 1,
              borderColor: "rgba(1, 94, 128, 0.1)",
              borderRadius: "8px",
              marginLeft: "8px",
            }}
          >
            <Typography
              variant="caption"
              component="h2"
              color="gray"
              fontWeight={600}
            >
              Esc
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginY: "8px" }} />

        <Box
          sx={{
            overflowY: "auto",
            maxHeight: { md: "calc(100vh - 300px)" },
          }}
        >
          <Box>
            {textSearch ? null : (
              <>
                <RecentSearch handleClose={() => handleClose()} />
              </>
            )}
          </Box>

          {textSearch !== "" ? (
            <Box>
              <Typography
                id="modal-modal-title"
                variant="subtitle1"
                component="h2"
                color="gray"
                sx={{
                  paddingX: { xs: 2, md: 0 },
                }}
              >
                Resultados
              </Typography>

              <>
                {dataSearch?.especies?.length > 0 && (
                  <ResultSearch
                    label="Especies"
                    arrayItems={dataSearch?.especies}
                    handleClose={() => handleClose()}
                  />
                )}
                {dataSearch?.pueblosIndigenas?.length > 0 && (
                  <ResultSearch
                    label="Pueblos indígenas"
                    arrayItems={dataSearch?.pueblosIndigenas}
                    handleClose={() => handleClose()}
                  />
                )}
                {dataSearch?.autores?.length > 0 && (
                  <ResultSearch
                    label="Autores"
                    arrayItems={dataSearch?.autores}
                    handleClose={() => handleClose()}
                  />
                )}
              </>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}
