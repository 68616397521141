import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const dowlonadFileForExcel = (data, fileName) => {
  if (data && data?.length > 0) {
    const formattedData = data?.map((specie) => ({
      Id_Especie: specie.id_especie,
      "Nombre Comun": specie.vc_nombre,
      "Nombre Cientifico": specie.vc_nombre_cientifico,
      "Nombre en Ingles": specie.vc_nombre_ingles,
      Categoria: specie.taxa.vc_nombre,
      Reino: specie.taxonomia.reino.vc_nombre,
      Filo: specie.taxonomia.filo.vc_nombre,
      Clase: specie.taxonomia.clase.vc_nombre,
      Orden: specie.taxonomia.orden.vc_nombre,
      Familia: specie.taxonomia.familia.vc_nombre,
      "Link de detalles": `https://amazonia.iiap.gob.pe/species/${specie.id_especie}`,
    }));
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Especies");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, "Guia-Ilustrada_ListaEspecies.xlsx");
  } else {
    console.log("No hay datos para descargar.");
  }
};
