/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustonSnackBar, TableListSection } from "../../../../../components";
import { useLogicConservationAdmin } from "../../../../../context/admin/conservationProvider";
import { usePagination } from "../../../../../hooks/admin/usePagination";

const ListConservationStatus = () => {
  const {
    getListData,
    dataDefault,
    listConservation,
    deleteConservation,
    loading,
    message,
  } = useLogicConservationAdmin();

  const { updateUrlParam } = usePagination();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const severity = message?.status === 200 ? "success" : "error";

  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    if (message.message !== "") {
      setOpen(true);
    }
  }, [message]);

  const handleView = (id) => {
    navigate(`details/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };

  const confirmDelete = (value, data) => {
    if (value === true) {
      const status = data.status === 1 ? 0 : 1;
      deleteConservation(data.id, { ch_estado: status });
    }
  };

  return (
    <>
      <TableListSection
        loading={loading}
        data={listConservation}
        dataDefault={dataDefault}
        onChangeSearch={(value) => updateUrlParam("search", value)}
        handlePage={(value) => updateUrlParam("page", value)}
        handleSize={(value) => updateUrlParam("size", value)}
        onChangeView={(value) => handleView(value)}
        onChangeEdit={(value) => handleEdit(value)}
        confirmDelete={(value, data) => confirmDelete(value, data)}
      />
      <CustonSnackBar
        open={open}
        setOpen={setOpen}
        message={message.message}
        severity={severity}
      />
    </>
  );
};

export default ListConservationStatus;
