import SelectInput from "../../../../../components/SelectInput";
import { useConservation } from "../../../../../hooks/clients/useConservation";
import { useLogicConservation } from "../../../../../context/client/conservationProvider";

export const FiltersConservation = () => {
  const { listConservation } = useConservation();
  const { selectedConservation, setSelectedConservation } =
    useLogicConservation();

  return (
    <>
      <SelectInput
        options={listConservation}
        value={selectedConservation}
        onChange={(e) => setSelectedConservation(e.target.value)}
        size="small"
      />
    </>
  );
};
