export const formatDate = (fecha) => {
  const date = new Date(fecha);
  const dia = date.getDate();
  const mes = date.getMonth() + 1; // Los meses comienzan desde 0
  const año = date.getFullYear();
  const hora = date.getHours();
  const minutos = date.getMinutes();
  const segundos = date.getSeconds();

  // Formatear la fecha en el formato deseado (por ejemplo, "dd/mm/aaaa hh:mm:ss")
  return `${dia}/${mes}/${año} ${hora}:${minutos}:${segundos}`;
};

export const mapChEstadoToStatus = (chEstado) => {
  return chEstado === "1" || chEstado === "2" ? "2" : "0";
};

export const listTransformGroups = (listSpecies, visibleItems) => {
  return listSpecies.reduce((acc, species, index) => {
    const groupIndex = Math.floor(index / visibleItems);
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex].push(species);
    return acc;
  }, []);
};
