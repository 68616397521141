import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Typography } from "@mui/material";
import ModalSearch from "./SearchInputGeneral/ModalSearch";

const SearchInput = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        id="outlined-basic"
        placeholder="Buscar..."
        variant="outlined"
        size="small"
        autoComplete="off"
        onClick={() => setModalOpen(true)}
        startIcon={<SearchIcon fontSize="small" />}
        sx={{
          width: "200px",
          color: "white", // Texto en color blanco
          backgroundColor: "rgba(192, 192, 192, 0.2)", // Fondo gris opaco
          borderRadius: "8px", // Bordes redondeados
          border: "1px solid gray",
          justifyContent: "left",

          "&:hover": {
            backgroundColor: "rgba(192, 192, 192, 0.4)", // Fondo gris opaco
            border: "1px solid #ffffff",
          },
        }}
      >
        <Typography
          variant="body1"
          component="h2"
          style={{ textTransform: "capitalize" }}
        >
          Buscar ...
        </Typography>
      </Button>
      <ModalSearch openModal={modalOpen} setOpenModal={setModalOpen} />
    </>
  );
};

export default SearchInput;
