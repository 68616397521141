import { Box } from "@mui/material";
import LayoutAuthors from "../layoutAuthors";
import AuthorsPageIndex from "./sections/AuthorsPageIndex";
import { stylesSections } from "../../../../styles";

const IndexAuthors = () => {
  return (
    <>
      <LayoutAuthors title="Autores" currentPage="Glosario de autores">
        <Box sx={stylesSections.sectionGeneral}>
          <AuthorsPageIndex />
        </Box>
      </LayoutAuthors>
    </>
  );
};

export default IndexAuthors;
