import * as React from "react";
import {
  Box,
  CardActionArea,
  // Divider,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { StyleCardSpecie } from "../styles";

import { Link } from "react-router-dom";

export default function CardSpecie({
  urlImage,
  scientificName = "Nombre científico",
  commonName = "Nombre común",
  link,
  colorDivider = "blue",
  arrayState = [],
  audioValue,
  onButtonClick,
  ...props
}) {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imgStateLoaded, setImgStateLoaded] = React.useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Card
      {...props}
      sx={{
        height: props.height ? props.height : { xs: "20rem", lg: "20rem" },
        borderRadius: "1rem",
        borderStyle: "solid",
        borderColor: colorDivider,
        borderWidth: "1.5px",
        boxShadow: "none",
        ":hover": {
          borderWidth: "2px",
        },
      }}
    >
      <Link to={link}>
        <CardActionArea
          onClick={scrollToTop}
          sx={{
            height: props.height ? props.height : { xs: "20rem", lg: "20rem" },
          }}
        >
          {imageLoaded === false && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{
                height: props.height
                  ? props.height
                  : { xs: "20rem", lg: "22rem" },
              }}
            />
          )}
          <CardMedia
            component="img"
            image={urlImage}
            alt={commonName}
            sx={{
              ...StyleCardSpecie.styleCardMedia,
              display: imageLoaded ? "block" : "none",
              mt: 4,
            }}
            onLoad={() => setImageLoaded(true)}
          ></CardMedia>
          <CardContent sx={StyleCardSpecie.styleCArdContent}>
            <Box sx={StyleCardSpecie.styleTextContent}>
              {arrayState?.map((item, index) => (
                <Box
                  component={"img"}
                  key={index}
                  src={item.imagen_estado}
                  alt={`Tag ${index + 1}`}
                  sx={{
                    display: imgStateLoaded ? "block" : "none",
                    width: {
                      xs: "1.8rem",
                      sm: "2.5rem",
                    },
                    marginLeft: "4px",
                  }}
                  onLoad={() => setImgStateLoaded(true)}
                />
              ))}
              {audioValue !== null && (
                <VolumeUpIcon
                  color="info"
                  fontSize="large"
                  sx={{ marginLeft: "auto" }}
                />
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                marginTop: "8px",
              }}
            >
              <Box
                sx={{
                  px: { xs: "0.5rem", sm: "1rem" },
                  pt: "0.7rem",
                  pb: "1rem",
                  backgroundColor: `${colorDivider}18`,
                }}
              >
                {/* <Divider
                  sx={{
                    width: "100%",
                    borderRadius: "999px",
                    height: "2px",
                    backgroundColor: colorDivider,
                  }}
                /> */}
                <Typography
                  component="div"
                  sx={{ ...StyleCardSpecie.title, color: colorDivider }}
                >
                  {commonName}
                </Typography>
                <Typography sx={StyleCardSpecie.subTitle}>
                  {scientificName}
                </Typography>

                {onButtonClick && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      textAlign: "center",
                      color: "blue",
                    }}
                  >
                    <ChevronRightIcon />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
