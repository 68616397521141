import { useNavigate, useSearchParams } from "react-router-dom";

export const usePagination = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const updateUrlParam = (name, value) => {
    const params = new URLSearchParams(window.location.search);

    if (name === "page") {
      params.set(name, value);
    } else {
      if (value === "" || value === null) {
        params.delete(name);
      } else {
        params.set(name, value);
      }
      params.set("page", 1);
    }

    navigate(`?${params.toString()}`);
  };

  const getParams = (name, defaultValue) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name) === null
      ? defaultValue
      : parseInt(searchParams.get(name));
  };

  return {
    updateUrlParam,
    getParams,
    searchParams,
  };
};
