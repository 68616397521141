/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useCommunity } from "../../hooks/clients/useCommunity";

const CommunityContex = createContext();

const getParams = (name, defaultValue) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name) === null
    ? defaultValue
    : searchParams.get(name);
};

const CommunityProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { listCommunities, getCommunities, loading } = useCommunity();
  const selectedCommunityId =
    searchParams.get("comunidad") === null
      ? ""
      : parseInt(searchParams.get("comunidad"));

  const dataBody = {
    page: parseInt(getParams("page", 1)),
    size: parseInt(getParams("size", 15)),
    search: getParams("search", ""),
  };

  const updateUrlParam = (name, value) => {
    const params = new URLSearchParams(window.location.search);

    if (name === "page") {
      params.set(name, value);
    } else {
      if (value === "") {
        params.delete(name);
      } else {
        params.set(name, value);
      }
      params.set("page", 1);
    }

    navigate(`?${params.toString()}`);
  };

  useEffect(() => {
    getCommunities(dataBody.page, dataBody.size, dataBody.search);
  }, [searchParams]);
  return (
    <CommunityContex.Provider
      value={{
        dataBody,
        selectedCommunityId,
        listCommunities,
        updateUrlParam,
        loading,
      }}
    >
      {children}
    </CommunityContex.Provider>
  );
};

export { CommunityProvider };
export const useLogicCommunity = () => useContext(CommunityContex);
