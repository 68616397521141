import { useState } from "react";
import { Box, Alert } from "@mui/material";
import { styleSections } from "../../styles";
import SectionDetails from "./SectionDetails";
import ButtonCustom from "../../../../../components/Admin/ButtonCustom";

import SectionMultimedia from "./SectionMultimedia";

import { useLogicConservationAdmin } from "../../../../../context/admin/conservationProvider";
import { LayoutFrmAdd } from "../../../../../components/Admin/layouts/layoutFrmAdd";

const FormAddConservation = () => {
  const {
    id_conservation,
    conservation,
    saveOrEditConservation,
    loading,
    message,
    updateOrDeleteImg,
  } = useLogicConservationAdmin();
  const [dataValues, setDataValues] = useState({});

  const handleSaveData = () => {
    if (id_conservation) {
      saveOrEditConservation(dataValues, id_conservation);
    } else {
      saveOrEditConservation(dataValues, null);
    }
  };

  const saveOrDeleteImg = (id, file) => {
    if (file) {
      updateOrDeleteImg(id, file);
    } else {
      updateOrDeleteImg(id, null);
    }
  };

  const severity = message?.status === 200 ? "success" : "error";

  return (
    <>
      <LayoutFrmAdd
        loading={loading}
        title={id_conservation ? "Editar Conservación" : "Agregar Conservación"}
        label={"Est. de Conservación"}
      >
        <Box
          sx={{
            display: { sm: "block", md: "flex" },
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box sx={styleSections}>
              <SectionDetails
                conservation={conservation}
                handleValues={(data) => setDataValues(data)}
              />
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                sx={{ gap: 2, mt: 2 }}
              >
                <ButtonCustom
                  label={id_conservation ? "Editar" : "Guardar"}
                  color={"primary"}
                  onClick={() => handleSaveData()}
                />

                <ButtonCustom
                  label={"Cancelar"}
                  color={"error"}
                  to={"/admin/status-conservation"}
                />
              </Box>
            </Box>
          </Box>

          {id_conservation ? (
            <Box sx={styleSections}>
              {message?.message !== "" && message?.message !== null && (
                <Box py={1}>
                  <Alert severity={severity}>
                    {/* <Typography sx={stylesFonts.messageAlert}> */}
                    {message.message}
                    {/* </Typography> */}
                  </Alert>
                </Box>
              )}
              <SectionMultimedia
                conservation={conservation}
                // handleDelete, handleUpLoad
                handleDelete={() => saveOrDeleteImg(id_conservation, null)}
                handleUpLoad={(file) => saveOrDeleteImg(id_conservation, file)}
                loading={loading}
              />
            </Box>
          ) : null}
        </Box>
      </LayoutFrmAdd>
    </>
  );
};

export default FormAddConservation;
