import SelectInput from "../../../../../components/SelectInput";
import { useLogicCategories } from "../../../../../context/client/categoriesProvider";

export const FiltersCategory = () => {
  const { listCategories, selectedCategoryId, handleCategorySelection } =
    useLogicCategories();
  return (
    <>
      <SelectInput
        id={"Taxonomias"}
        value={selectedCategoryId}
        options={listCategories}
        onChange={(e) => handleCategorySelection(e.target.value)}
        // size="small"
      />
    </>
  );
};
