import { useState, useEffect } from "react";
import {
  getListConservation,
  getConservationById,
  saveOrEditConservation,
  deleteConservation,
  addUpdateImgConservation,
  deleteImgConservation,
} from "../../api";

const token = localStorage.getItem("token_raices");

export const useConservationAdmin = () => {
  const [conservations, setConservations] = useState([]);
  const [conservation, setConservation] = useState({});
  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    if (message.message !== "") {
      setTimeout(() => {
        setMessage({
          status: null,
          message: "",
        });
      }, 4000);
    }
  }, [message]);

  const handleResponse = (response, successMessage, errorMessage) => {
    setLoading(false);
    if (response && response.status === 200) {
      setMessage({
        ...message,
        status: response.status,
        message: successMessage,
      });
    } else {
      setMessage({
        ...message,
        status: response.status,
        message: errorMessage,
      });
    }
  };

  const getConservationData = async (search, page, size) => {
    setLoading(true);
    const response = await getListConservation(token, search, page, size);
    if (response && response.status === 200) {
      setConservations(response.data);
    } else {
      setConservations([]);
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const getDataConservationById = async (id) => {
    setLoading(true);
    const response = await getConservationById(token, id);
    if (response && response.status === 200) {
      setConservation(response.data);
    } else {
      setConservation({});
    }
    handleResponse(response, null, "No se encontraron resultados");
  };

  const saveOrEditConservationData = async (body, id) => {
    setLoading(true);
    const response = await saveOrEditConservation(token, id, body);
    handleResponse(
      response,
      "Se ha guardado correctamente",
      "No se pudo guardar correctamente"
    );
  };

  const deleteConservationData = async (id, body) => {
    setLoading(true);
    const response = await deleteConservation(token, id, body);
    handleResponse(
      response,
      "Se ha eliminado correctamente",
      "No se pudo eliminar correctamente"
    );
  };

  const addUpdateOrDeleteImg = async (id, file) => {
    setLoading(true);
    const api = file ? addUpdateImgConservation : deleteImgConservation;
    const body = file ? { vc_imagen: file } : null;
    const response = await api(token, id, body);
    handleResponse(
      response,
      "Se ha actualizado correctamente",
      "No se pudo actualizar correctamente"
    );
  };

  return {
    getConservationData,
    conservations,
    conservation,
    getDataConservationById,
    // ForCrud,
    deleteConservationData,
    saveOrEditConservationData,
    addUpdateOrDeleteImg,
    // response,
    loading,
    message,
  };
};
