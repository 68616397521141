/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { stylesFonts } from "../../../styles";
import { Grid, Typography, Box, Alert, CircularProgress } from "@mui/material";

import { UpLoadImage } from "../../../../../../components/Admin/UploadImage/UpLoadIamge";
import { UpLoadAudio } from "../../../../../../components";
import { useAdminSpecies } from "../../../../../../hooks";

const token = localStorage.getItem("token_raices");

const SectionMultimedia = ({ specie }) => {
  const [specieData, setSpecieData] = useState({});
  const {
    updateOrDeleteImgSpecie,
    updateOrDeleteSound,
    loading,
    message,
    specie: specieApi,
    getSpecieById,
  } = useAdminSpecies();

  useEffect(() => {
    if (specie) {
      setSpecieData(specie);
    }
  }, [specie]);

  useEffect(() => {
    if (specieApi) {
      setSpecieData(specieApi);
    }
  }, [specieApi]);

  const handleUpLoadAudio = async (file) => {
    const body = file ? { vc_sonido: file } : null;
    await updateOrDeleteSound(token, specie?.id_especie, body);
    getSpecieById(token, specie?.id_especie);
  };

  const handleUpLoadImg = async (file) => {
    const body = file ? { vc_imagen: file } : null;
    await updateOrDeleteImgSpecie(token, specie?.id_especie, body);
    getSpecieById(token, specie.id_especie);
  };

  const severity = message?.status === 200 ? "success" : "error";

  return (
    <>
      <Typography sx={stylesFonts.titleSection}>Multimedia</Typography>
      {message?.message !== "" && message?.message !== null && (
        <Box py={1}>
          <Alert severity={severity}>
            <Typography sx={stylesFonts.messageAlert}>
              {message.message}
            </Typography>
          </Alert>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={stylesFonts.subtitleSection}>Imagen</Typography>
          <Typography sx={stylesFonts.textSection} mb={2}>
            Las imágenes debes ser referenciales de la especie
          </Typography>
          {loading ? (
            <Box py={3} sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <UpLoadImage
              data={{
                id: specieData?.id_especie,
                image: specieData?.vc_imagen,
              }}
              handleUpLoad={(file) => handleUpLoadImg(file)}
              handleDelete={() => handleUpLoadImg(null)}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={stylesFonts.subtitleSection}>Audio</Typography>
          <Typography sx={stylesFonts.textSection}>
            Audio referencial de la especie (opcional)
          </Typography>
          <UpLoadAudio
            data={{
              id: specieData.id_especie,
              audio: specieData.vc_sonido,
            }}
            loading={loading}
            handleUpLoad={(file) => handleUpLoadAudio(file)}
            handleDelete={() => handleUpLoadAudio(null)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionMultimedia;
