import { useState } from "react";
import {
  getListAuthor,
  getAuthorById,
} from "../../infrastructure/api/client/authors";

export const useAuthors = () => {
  const [listAuthors, setListAuthors] = useState([]);
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getAuthors = async (currentPage, itemsPerPage, search) => {
    const response = await getListAuthor(
      currentPage,
      itemsPerPage,
      search
    ).then((res) => res);
    if (response) {
      setListAuthors(response.data);
      setLoading(false);
    } else {
      setListAuthors([]);
      setError(true);
      setLoading(false);
    }
  };

  const getAuthor = async (id) => {
    const response = await getAuthorById(id).then((res) => res);
    if (response.status === 200) {
      setAuthor(response.data);
      setLoading(false);
    } else {
      setAuthor(null);
      setError(true);
      setLoading(false);
    }
  };

  return {
    getAuthors,
    listAuthors,
    getAuthor,
    author,
    error,
    loading,
  };
};
