import { Typography, Box } from "@mui/material";
import { titleSection } from "../../styles";
import { UpLoadImage } from "../../../../../components";

const SectionMultimedia = ({ conservation, handleDelete, handleUpLoad }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={titleSection}>Multimedia</Typography>
      </Box>
      <Box>
        <UpLoadImage
          data={{
            image: conservation?.vc_imagen_estado,
            id: conservation?.id_est_conservacion,
          }}
          handleDelete={handleDelete}
          handleUpLoad={handleUpLoad}
        />
      </Box>
    </>
  );
};

export default SectionMultimedia;
