import { Container, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { stylesFont } from "../../../../../styles";

export default function InfoSection() {
  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <Container maxWidth="lg">
        <Box>
          <Typography sx={{ ...stylesFont.titleSection, mb: 2 }}>
            Descubre las raíces indígenas
          </Typography>
          <Typography
            sx={{
              ...stylesFont.text,
              mb: 2,
            }}
          >
            La Amazonía peruana se presenta como un gran mosaico de culturas, en
            ella habitan pobladores amazónicos originarios, ribereños y
            migrantes procedentes de la costa y la sierra del país. El número
            total de habitantes en la Amazonía es de 4.076.404 (INEI, 2017). Del
            total de la población amazónica se reconocen como indígenas un total
            de 212.823 personas (INEI, 2017). Según el Ministerio de Cultura en
            la Amazonía peruana habitan 51 pueblos indígenas originarios
            (MINCUL, 2016) y se hablan un total de 45 lenguas (entre las que se
            incluye la variante amazónica del quechua), pertenecientes a 17
            familias lingüísticas (MINEDU, 2013). En la Amazonía peruana se han
            censado un total de 2.703 comunidades indígenas, estando reconocidas
            un total de 2.425, de las cuales el 77,1% están tituladas (INEI,
            2017)
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
